import { Popover, PopoverBody, PopoverContent, PopoverTrigger } from '@chakra-ui/react';
import type { ReactElement, ReactNode } from 'react';

import { QuestionIcon } from '@endaoment-frontend/ui/icons';

import styles from './QuestionPopover.module.scss';

type Placement = 'bottom' | 'left' | 'right' | 'top';

type QuestionPopoverProps = {
  content: ReactNode;
  placement?: Placement;
  children?: ReactElement;
};

export const QuestionPopover = ({ content, placement = 'top', children }: QuestionPopoverProps) => {
  return (
    <Popover placement={placement} trigger='hover'>
      <PopoverTrigger>
        {children ? children : <QuestionIcon color='currentColor' width={15} height={16} />}
      </PopoverTrigger>
      <PopoverContent className={styles['content']}>
        <PopoverBody className={styles['body']}>{content}</PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
