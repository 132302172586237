import type { IconProps } from '../Icon.types';

export const NFTIcon = ({ width = 28, height, color, ...props }: IconProps) => (
  <svg
    width={width}
    height={height ?? width}
    viewBox='0 0 28 28'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}>
    <rect
      x='2.5'
      y='5.5'
      width='23'
      height='18'
      rx='2.5'
      fill={color ?? '#DE53B7'}
      fillOpacity='0.125'
      stroke={color ?? '#DE53B7'}
    />
    <path
      d='M23.4765 22.7113L19.0951 16.5148C18.889 16.2233 18.4526 16.2355 18.2631 16.5381L14.3824 22.7346C14.1739 23.0676 14.4133 23.5 14.8062 23.5H23.0682C23.4738 23.5 23.7106 23.0425 23.4765 22.7113Z'
      fill={color ?? '#DE53B7'}
      stroke={color ?? '#DE53B7'}
    />
    <path
      d='M18.4406 22.7059L11.6989 13.4361C11.4961 13.1573 11.0786 13.1625 10.8829 13.4464L4.49234 22.7162C4.26367 23.0479 4.50111 23.5 4.904 23.5H18.0362C18.4449 23.5 18.681 23.0364 18.4406 22.7059Z'
      fill={color ?? '#DE53B7'}
      stroke={color ?? '#DE53B7'}
    />
    <circle cx='18.5' cy='10.5' r='2.5' fill={color ?? '#DE53B7'} />
  </svg>
);
