import type { IconProps } from './Icon.types';

export const AwaitingIcon = ({ width = 36, height, ...props }: Omit<IconProps, 'color'>) => (
  <svg
    width={width}
    height={height ?? width}
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}>
    <ellipse cx='7.99999' cy='16.4444' rx='6.22222' ry='8.44444' stroke='#627EEA' strokeWidth='2.66667' />
    <ellipse cx='24' cy='16.4444' rx='6.22222' ry='8.44444' stroke='#627EEA' strokeWidth='2.66667' />
    <circle cx='4.88894' cy='16.4446' r='3.11111' fill='#627EEA' />
    <circle cx='20.8889' cy='16.4446' r='3.11111' fill='#627EEA' />
  </svg>
);
