import type { IconProps } from './Icon.types';

export const PlayIcon = ({ width, height, className, color }: IconProps) => (
  <svg
    width={width ?? '36'}
    height={height ?? width ?? '36'}
    className={className}
    viewBox='0 0 36 36'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M10 11.4821C10 9.93849 11.6744 8.97675 13.0077 9.75452L24.8886 16.685C26.2443 17.4758 26.2024 19.4487 24.8143 20.1813L12.9335 26.4518C11.6016 27.1547 10 26.189 10 24.683V11.4821Z'
      fill={color ?? 'white'}
    />
  </svg>
);
