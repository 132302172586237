import clsx from 'clsx';
import { useRef, type DetailedHTMLProps, type VideoHTMLAttributes } from 'react';

import styles from './FloatingVideo.module.scss';

export const FloatingVideo = ({
  className,
  playing = false,
  ...props
}: DetailedHTMLProps<VideoHTMLAttributes<HTMLVideoElement>, HTMLVideoElement> & {
  className?: string;
  playing?: boolean;
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const checkAndSetPlayState = (setPlay: boolean) => {
    if (!videoRef.current) return;

    if (setPlay && videoRef.current.paused) {
      void videoRef.current.play();
    }
    if (!setPlay && !videoRef.current.paused) {
      videoRef.current.pause();
    }
  };
  checkAndSetPlayState(playing);
  return (
    <video
      ref={videoRef}
      onMouseOver={() => checkAndSetPlayState(true)}
      onMouseOut={() => checkAndSetPlayState(playing)}
      muted
      loop
      playsInline
      className={clsx(styles['floating-video'], className)}
      {...props}
    />
  );
};
