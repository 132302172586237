import clsx from 'clsx';

import styles from './DashedBorder.module.scss';

export const DashedBorder = ({ loading, strokeOpacity }: { loading?: boolean; strokeOpacity?: number }) => (
  <svg
    className={clsx(styles['dashed-border'], loading && styles['dashed-border--loading'])}
    width='100%'
    height='100%'
    xmlns='http://www.w3.org/2000/svg'>
    <rect
      width='100%'
      height='100%'
      fill='none'
      rx='18'
      ry='18'
      stroke='#8478E0'
      strokeOpacity={strokeOpacity ?? 0.2}
      strokeWidth='3'
      strokeDasharray='10, 10'
      strokeDashoffset='0'
      strokeLinecap='round'
    />
  </svg>
);
