import type { IconProps } from '../Icon.types';

export const ExploreActionIcon = ({ width = 30, height, color, ...props }: IconProps) => (
  <svg
    width={width}
    height={height ?? width}
    viewBox='0 0 30 30'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}>
    <circle
      cx='15'
      cy='15'
      r='14'
      fill={color ?? '#03814D'}
      fillOpacity='0.125'
      stroke={color ?? '#03814D'}
      strokeOpacity='0.75'
      strokeWidth='1.5'
    />
    <path
      d='M20.27 9.39673C20.4849 9.28929 20.7129 9.51725 20.6054 9.73214L17.1301 16.6828C17.0333 16.8763 16.8764 17.0332 16.6829 17.13L9.73226 20.6053C9.51737 20.7127 9.2894 20.4848 9.39685 20.2699L12.8722 13.3193C12.9689 13.1257 13.1259 12.9688 13.3194 12.872L20.27 9.39673Z'
      fill={color ?? '#03814D'}
      fillOpacity='0.25'
    />
    <path
      d='M19.9346 8.72591L20.27 9.39673L19.9346 8.72591L12.984 12.2012C12.6453 12.3706 12.3707 12.6452 12.2013 12.9839L12.8722 13.3193L12.2013 12.9839L8.72603 19.9345C8.29625 20.7941 9.20812 21.7059 10.0677 21.2761L17.0183 17.8008C17.357 17.6315 17.6316 17.3569 17.8009 17.0182L21.2762 10.0676C21.706 9.20799 20.7942 8.29614 19.9346 8.72591Z'
      stroke={color ?? '#03814D'}
      strokeOpacity='0.75'
      strokeWidth='1.5'
    />
    <circle cx='15.0016' cy='14.9996' r='1.4' fill={color ?? '#03814D'} fillOpacity='0.75' />
    <path
      d='M15 4.5L15 6.95'
      stroke={color ?? '#03814D'}
      strokeOpacity='0.25'
      strokeWidth='1.5'
      strokeLinecap='round'
    />
  </svg>
);
