import type { IconProps } from '../Icon.types';

export const BankIcon = ({ width = 28, height, color, ...props }: IconProps) => (
  <svg
    width={width}
    height={height ?? width}
    viewBox='0 0 28 28'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}>
    <path
      d='M12.8906 3.7396C13.5624 3.29173 14.4376 3.29173 15.1094 3.7396L23.2519 9.16795C24.0752 9.71678 23.6866 11 22.6972 11H5.30278C4.31337 11 3.92484 9.71677 4.74808 9.16795L12.8906 3.7396Z'
      fill={color ?? '#0083CF'}
    />
    <rect x='11' y='12' width='2' height='6' fill={color ?? '#0083CF'} fillOpacity={0.5} />
    <rect x='15' y='12' width='2' height='6' fill={color ?? '#0083CF'} fillOpacity={0.5} />
    <rect x='19' y='12' width='2' height='6' fill={color ?? '#0083CF'} fillOpacity={0.5} />
    <rect x='7' y='12' width='2' height='6' fill={color ?? '#0083CF'} fillOpacity={0.5} />
    <rect x='5' y='19' width='18' height='2' rx='1' fill={color ?? '#0083CF'} />
    <rect x='4' y='22' width='21' height='2' rx='1' fill={color ?? '#0083CF'} />
  </svg>
);
