import { Flex, Skeleton } from '@chakra-ui/react';
import clsx from 'clsx';
import { match } from 'ts-pattern';
import { formatUnits } from 'viem';

import { NDAO_TOKEN_DECIMALS } from '@endaoment-frontend/constants';
import { NDAOTokenIcon } from '@endaoment-frontend/ui/icons';

import styles from './TokenAmount.module.scss';

const FancyCompactNumber = ({ number }: { number: number }) => {
  const formatter = new Intl.NumberFormat('en-US', {
    minimumSignificantDigits: 2,
    maximumFractionDigits: 1,
    minimumFractionDigits: 1,
    notation: 'compact',
  });

  const parts = formatter.formatToParts(number);
  return (
    <span>
      {parts.map((part, i) => (part.type === 'compact' ? <em key={i}>{part.value.toLowerCase()}</em> : part.value))}
    </span>
  );
};

export const TokenAmount = ({
  amount,
  stringAmount,
  size,
  className,
  simpleTicker = false,
}: {
  amount?: bigint;
  stringAmount?: string;
  size: 'large' | 'medium' | 'small';
  className?: string;
  simpleTicker?: boolean;
}) => {
  return match(size)
    .with('large', () => (
      <Skeleton
        isLoaded={typeof amount === 'bigint' || !!stringAmount}
        display='flex'
        gap='0.5rem'
        alignItems='center'
        className={clsx(styles['token-amount'], styles['token-amount--large'], className)}>
        <NDAOTokenIcon width={42} />
        <Flex alignItems='baseline' gap='0.75rem'>
          {stringAmount ?? <FancyCompactNumber number={Number(formatUnits(amount ?? 0n, NDAO_TOKEN_DECIMALS))} />}
          <b className={clsx(!simpleTicker && styles['ticker-label'])}>NDAO</b>
        </Flex>
      </Skeleton>
    ))
    .with('medium', () => (
      <Skeleton
        isLoaded={typeof amount === 'bigint'}
        display='flex'
        gap='0.325rem'
        alignItems='center'
        className={clsx(styles['token-amount'], styles['token-amount--medium'], className)}>
        <NDAOTokenIcon width={24} />
        <Flex alignItems='baseline' gap='0.325rem'>
          {stringAmount ?? <FancyCompactNumber number={Number(formatUnits(amount ?? 0n, NDAO_TOKEN_DECIMALS))} />}
          <b className={clsx(!simpleTicker && styles['ticker-label'])}>NDAO</b>
        </Flex>
      </Skeleton>
    ))
    .with('small', () => (
      <Skeleton
        isLoaded={typeof amount === 'bigint'}
        display='flex'
        gap='0.25rem'
        alignItems='center'
        className={clsx(styles['token-amount'], styles['token-amount--small'], className)}>
        <NDAOTokenIcon width={20} />
        <Flex alignItems='baseline' gap='0.25rem'>
          {stringAmount ?? <FancyCompactNumber number={Number(formatUnits(amount ?? 0n, NDAO_TOKEN_DECIMALS))} />}
          <b className={clsx(!simpleTicker && styles['ticker-label'])}>NDAO</b>
        </Flex>
      </Skeleton>
    ))
    .exhaustive();
};
