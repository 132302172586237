import { useId } from 'react';

export const ImpactPoolLogoIcon = () => {
  const shadowId = useId();
  return (
    <svg width='97' height='105' viewBox='0 0 97 105' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g filter={`url(#${shadowId})`}>
        <path
          d='M42.9506 66.4631C38.8604 67.0447 37.4297 68.4477 37.4297 70.0861C37.4297 72.2453 42.4383 73.9958 48.6168 73.9958C54.7953 73.9958 59.8039 72.2453 59.8039 70.0861C59.8039 68.4477 58.3732 67.0447 54.283 66.4631'
          stroke='#53ACDE'
          strokeOpacity='0.5'
          strokeWidth='1.91892'
          strokeLinecap='round'
        />
        <path
          opacity='0.5'
          d='M42.1515 62.9277C31.8033 63.7337 25.5161 66.9826 25.5161 70.8679C25.5161 75.3592 35.8586 79.0001 48.6168 79.0001C61.3749 79.0001 71.7174 75.3592 71.7174 70.8679C71.7174 66.9826 65.4302 63.7337 55.082 62.9277'
          stroke='#53ACDE'
          strokeOpacity='0.5'
          strokeWidth='1.91892'
          strokeLinecap='round'
        />
        <path
          d='M31.3721 46.8211C38.441 48.7359 43.3331 54.7043 45.1039 62.3482L46.5279 68.495C46.7224 69.3349 47.4706 69.9295 48.3327 69.9295H48.9004C49.7625 69.9295 50.5106 69.3349 50.7052 68.495L52.1292 62.3482C53.9 54.7043 58.7921 48.7359 65.861 46.8211L76.7334 43.876C77.8766 43.5663 77.8766 41.9445 76.7334 41.6348L65.861 38.6898C58.7921 36.7749 53.9 30.8065 52.1292 23.1626L49.7476 12.8821C49.4699 11.6835 47.7632 11.6835 47.4855 12.8821L45.1039 23.1626C43.3331 30.8065 38.441 36.7749 31.3721 38.6898L20.4997 41.6348C19.3564 41.9445 19.3564 43.5663 20.4997 43.876L31.3721 46.8211Z'
          fill='#FFBE8F'
          stroke='#EA6B0E'
          strokeWidth='1.91892'
        />
      </g>
      <defs>
        <filter
          id={shadowId}
          x='0.682617'
          y='0.0236816'
          width='95.8677'
          height='104.936'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'>
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='7' />
          <feGaussianBlur stdDeviation='9' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 0.678431 0 0 0 0 0.729412 0 0 0 0 0.8 0 0 0 0.38 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_9852_17509' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_9852_17509' result='shape' />
        </filter>
      </defs>
    </svg>
  );
};
