import { z } from 'zod';

export type BlockchainTransactionStatus = 'error' | 'pending' | 'success';
export type TransactionStatus = BlockchainTransactionStatus | 'none' | 'rejected' | 'waiting';

export const addressSchema = z.custom<`0x${string}`>(v => typeof v === 'string' && v.startsWith('0x'));
export type Address = z.infer<typeof addressSchema>;
export type ShortAddress = `0x${string}...${string}`;

export const bigIntSchema = z.preprocess(
  v => {
    if (typeof v === 'undefined') return '0';
    return v;
  },
  z.bigint({ coerce: true }),
);

export const swapInfoSchema = z.object({
  swapWrapper: addressSchema,
  tokenIn: addressSchema,
  amountIn: bigIntSchema,
  callData: z.string(),
  quote: z.object({
    priceImpact: z.number(),
    /** USDC value */
    expectedUsdc: bigIntSchema,
    /** USDC value */
    minimumTolerableUsdc: bigIntSchema,
    /** USDC value */
    endaomentFee: bigIntSchema,
  }),
  chainId: z.number(),
});
export type SwapInfo = z.infer<typeof swapInfoSchema>;
