import type { IconProps } from './Icon.types';

export const BackIcon = ({ width = 14, height = 8, color = 'currentColor', ...props }: IconProps) => (
  <svg width={width} height={height} viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M13 3.5C13.2761 3.5 13.5 3.72386 13.5 4C13.5 4.27614 13.2761 4.5 13 4.5V3.5ZM0.646446 4.35355C0.451184 4.15829 0.451184 3.84171 0.646446 3.64645L3.82843 0.464466C4.02369 0.269204 4.34027 0.269204 4.53553 0.464466C4.7308 0.659728 4.7308 0.976311 4.53553 1.17157L1.70711 4L4.53553 6.82843C4.7308 7.02369 4.7308 7.34027 4.53553 7.53553C4.34027 7.7308 4.02369 7.7308 3.82843 7.53553L0.646446 4.35355ZM13 4.5H1V3.5H13V4.5Z'
      fill={color}
    />
  </svg>
);
