import type { IconProps } from './Icon.types';

export const NVTTokenDiagramIcon = ({ width = 77, height, ...props }: IconProps) => {
  return (
    <svg
      width={width}
      height={height ?? width}
      viewBox='0 0 77 77'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <circle cx='38.5' cy='38.5' r='38' fill='#FFDFD2' stroke='currentColor' />
      <path
        d='M33.1479 35.3508L29.8583 29.868L18.0554 23.055L14.8085 28.6845L32.3279 38.7955L14.7828 48.8806L18.0209 54.5014L29.8583 47.6713L33.1397 42.1804C33.7667 41.1519 34.0996 39.9712 34.1011 38.7663C34.1025 37.5623 33.773 36.3802 33.1479 35.3508Z'
        fill='currentColor'
      />
      <path
        d='M48.8799 14.7835L38.7692 32.3027L28.6582 14.7833L23.0802 18.0301L29.8933 29.833L35.3328 33.1659C36.3695 33.7925 37.5594 34.1201 38.7706 34.1123C39.9819 34.1045 41.1679 33.7623 42.1969 33.1232L47.6883 29.8413L54.5009 18.0214L48.8799 14.7835Z'
        fill='currentColor'
      />
      <path
        d='M44.3894 42.2228L47.7573 47.6973L59.5597 54.5098L62.8153 48.8891L45.2959 38.7781L62.8152 28.6673L59.534 23.0547L47.6965 29.8848L44.4327 35.3592C43.7989 36.3897 43.4596 37.5747 43.4518 38.7849C43.444 39.9952 43.7682 41.1846 44.3894 42.2228Z'
        fill='currentColor'
      />
      <path
        d='M42.205 44.4077C41.179 43.7889 40.0042 43.4608 38.8061 43.4574C37.6074 43.4545 36.4312 43.7772 35.4017 44.3906L29.9103 47.6715L23.0802 59.5089L28.6839 62.7816L38.7949 45.2622L48.9058 62.7816L54.5271 59.5265L47.7228 47.7323L42.205 44.4077Z'
        fill='currentColor'
      />
    </svg>
  );
};
