import type { IconProps } from '../Icon.types';

export const AllocationIcon = ({ width = 36, height = 36, color, ...props }: IconProps) => (
  <svg width={width} height={height} viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      opacity='0.25'
      d='M31.9996 18C31.9996 25.732 25.7316 32 17.9996 32C10.2676 32 3.9996 25.732 3.9996 18C3.9996 10.268 10.2676 3.99997 17.9996 3.99997C25.7316 3.99997 31.9996 10.268 31.9996 18ZM9.5996 18C9.5996 22.6392 13.3604 26.4 17.9996 26.4C22.6388 26.4 26.3996 22.6392 26.3996 18C26.3996 13.3608 22.6388 9.59997 17.9996 9.59997C13.3604 9.59997 9.5996 13.3608 9.5996 18Z'
      fill={color ?? '#8478E0'}
    />
    <path
      opacity='0.5'
      d='M17.9989 3.99988C15.2299 3.99988 12.5232 4.82096 10.2209 6.3593C7.91861 7.89764 6.1242 10.0841 5.06457 12.6423C4.00495 15.2005 3.7277 18.0154 4.26789 20.7311C4.80808 23.4469 6.14146 25.9414 8.09939 27.8994C10.0573 29.8573 12.5519 31.1907 15.2676 31.7309C17.9834 32.2711 20.7983 31.9938 23.3565 30.9342C25.9146 29.8746 28.1011 28.0801 29.6395 25.7779C31.1778 23.4756 31.9989 20.7688 31.9989 17.9999L26.3989 17.9999C26.3989 19.6612 25.9062 21.2853 24.9832 22.6667C24.0602 24.048 22.7483 25.1247 21.2134 25.7605C19.6785 26.3962 17.9896 26.5626 16.3601 26.2385C14.7307 25.9144 13.2339 25.1143 12.0592 23.9396C10.8844 22.7648 10.0844 21.2681 9.76029 19.6386C9.43617 18.0092 9.60252 16.3202 10.2383 14.7853C10.8741 13.2504 11.9507 11.9385 13.3321 11.0155C14.7135 10.0925 16.3375 9.59988 17.9989 9.59988L17.9989 3.99988Z'
      fill={color ?? '#8478E0'}
    />
    <path
      d='M17.9989 3.99988C15.6966 3.99988 13.4298 4.56768 11.3993 5.65298C9.36887 6.73828 7.63741 8.30759 6.35831 10.2219C5.07921 12.1362 4.29197 14.3364 4.0663 16.6276C3.84063 18.9189 4.18351 21.2304 5.06457 23.3574C5.94563 25.4845 7.33767 27.3615 9.11738 28.822C10.8971 30.2826 13.0095 31.2817 15.2676 31.7309C17.5257 32.18 19.8597 32.0654 22.0629 31.397C24.266 30.7287 26.2704 29.5274 27.8984 27.8994L23.9386 23.9396C22.9618 24.9164 21.7592 25.6372 20.4373 26.0382C19.1154 26.4392 17.715 26.508 16.3601 26.2385C15.0053 25.969 13.7378 25.3695 12.67 24.4932C11.6022 23.6168 10.7669 22.4907 10.2383 21.2144C9.70966 19.9382 9.50393 18.5513 9.63933 17.1765C9.77473 15.8018 10.2471 14.4817 11.0145 13.3331C11.782 12.1845 12.8209 11.2429 14.0392 10.5917C15.2574 9.94056 16.6175 9.59988 17.9989 9.59988L17.9989 3.99988Z'
      fill={color ?? '#8478E0'}
    />
  </svg>
);
