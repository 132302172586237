import type { IconProps } from './Icon.types';

export const RewardIcon = ({ width = 36, height, color, ...props }: IconProps) => (
  <svg
    width={width}
    height={height ?? width}
    viewBox='0 0 36 36'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}>
    <path
      d='M15 5L16.4342 10.8916C16.7825 12.3226 17.9143 13.4311 19.3524 13.7495L25 15L19.2491 16.4406C17.8665 16.787 16.787 17.8665 16.4406 19.2491L15 25L13.5594 19.2491C13.213 17.8665 12.1335 16.787 10.7509 16.4406L5 15L10.7509 13.5594C12.1335 13.213 13.213 12.1335 13.5594 10.7509L15 5Z'
      fill={color ?? '#EA6B0E'}
    />
    <path
      opacity='0.5'
      d='M24.5 18L25.4322 21.8295C25.6586 22.7597 26.3943 23.4802 27.329 23.6872L31 24.5L27.2619 25.4364C26.3632 25.6615 25.6615 26.3632 25.4364 27.2619L24.5 31L23.5636 27.2619C23.3385 26.3632 22.6368 25.6615 21.7381 25.4364L18 24.5L21.7381 23.5636C22.6368 23.3385 23.3385 22.6368 23.5636 21.7381L24.5 18Z'
      fill={color ?? '#EA4705'}
    />
  </svg>
);
