import type { IconProps } from './Icon.types';

export const LaptopIcon = ({
  width = 54,
  height = 39,
  color = 'currentColor',
  strokeColor = 'currentColor',
  strokeWidth = 1.5,
  ...props
}: IconProps) => {
  return (
    <svg width={width} height={height} viewBox='0 0 54 39' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M14 30H53L40 34.5H1L14 30Z' fill='#ADBACC' fillOpacity='0.25' />
      <path d='M40 34.5H1V36.5C1 37.0523 1.44772 37.5 2 37.5H40V34.5Z' fill='#ADBACC' fillOpacity='0.25' />
      <path d='M53 30L40 34.5V37.5L53 33V30Z' fill='#ADBACC' fillOpacity='0.25' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14 30H53V4C53 2.34315 51.6569 1 50 1H17C15.3431 1 14 2.34315 14 4V30ZM50.5 27H16.5V4H50.5V27Z'
        fill={color}
        fillOpacity='0.25'
      />
      <path
        d='M14 30H53M14 30L1 34.5M14 30V4C14 2.34315 15.3431 1 17 1H50C51.6569 1 53 2.34315 53 4V30M53 30L40 34.5M53 30V33L40 37.5M40 34.5H1M40 34.5V37.5M1 34.5V36.5C1 37.0523 1.44772 37.5 2 37.5H40M16.5 27H50.5V4H16.5V27Z'
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinejoin='round'
      />
    </svg>
  );
};
