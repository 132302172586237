import type { IconProps } from '../Icon.types';

export const BaseChainIcon = ({ width = 28, height = 28, color, ...props }: IconProps) => (
  <svg width={width} height={height} viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.9923 22C18.4148 22 22 18.4183 22 14C22 9.58172 18.4148 6 13.9923 6C9.73787 6 6.25835 9.31464 6 13.5H16.4938V14.5H6C6.25835 18.6854 9.73787 22 13.9923 22Z'
      fill={color ?? 'white'}
    />
  </svg>
);
