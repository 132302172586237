import clsx from 'clsx';
import Image from 'next/image';
import type { ComponentProps, ReactNode } from 'react';

import { useIsMobile } from '@endaoment-frontend/hooks';

import styles from './EmptyStateBlock.module.scss';
import notfoundImage from './images/empty/404.png';
import activityImage from './images/empty/activity.png';
import allocationImage from './images/empty/allocation.png';
import collaboratorImage from './images/empty/collaborator.png';
import fundsImage from './images/empty/funds.png';
import orgImage from './images/empty/org.png';
import portfoliosImage from './images/empty/portfolios.png';
import researchImage from './images/empty/research.png';

type EmptyStateType =
  | 'activity'
  | 'allocation'
  | 'collaborator'
  | 'fund'
  | 'generic'
  | 'org'
  | 'portfolio'
  | 'research';

type EmptyStateBlockProps = {
  title?: string;
  variation: EmptyStateType;
  description?: string;
  children?: ReactNode;
  vertical?: boolean;
  spread?: boolean;
  className?: string;
  reverse?: boolean;
};

type EmptyTypeImageMap = { [Key in EmptyStateType]: ComponentProps<typeof Image>['src'] };
const variationImageMap: EmptyTypeImageMap = {
  generic: notfoundImage,
  fund: fundsImage,
  portfolio: portfoliosImage,
  org: orgImage,
  research: researchImage,
  activity: activityImage,
  allocation: allocationImage,
  collaborator: collaboratorImage,
};

export const EmptyStateBlock = ({
  title,
  description,
  variation = 'generic',
  children,
  vertical,
  spread,
  className,
  reverse,
}: EmptyStateBlockProps) => {
  const { isMobile } = useIsMobile();

  return (
    <div
      className={clsx(
        styles['empty-state-block'],
        (vertical || isMobile) && styles['empty-state-block--vertical'],
        spread && styles['empty-state-block--spread'],
        className,
        reverse && styles['empty-state-block--reverse'],
      )}>
      <div>
        {!!title && <h2>{title}</h2>}
        {!!description && <p>{description}</p>}
        {children}
      </div>
      <div className={styles['image']}>
        <Image src={variationImageMap[variation]} priority alt='' fill />
      </div>
    </div>
  );
};
