import type { IconProps } from '../Icon.types';

export const StockIcon = ({ width = 28, height, color, ...props }: IconProps) => (
  <svg
    width={width}
    height={height ?? width}
    viewBox='0 0 28 28'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}>
    <path
      d='M6 17L10.8 12.5L14.5333 16L22 9'
      stroke={color ?? '#03814D'}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M4 23H24' stroke={color ?? '#03814D'} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path
      opacity='0.5'
      d='M10 23L10 5'
      stroke={color ?? '#03814D'}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeDasharray='2 3'
    />
    <path
      opacity='0.5'
      d='M18 23L18 5'
      stroke={color ?? '#03814D'}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeDasharray='2 3'
    />
  </svg>
);
