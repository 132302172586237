import { forwardRef } from 'react';

import type { IconProps } from './Icon.types';

export const ShareIcon2 = forwardRef<SVGSVGElement, IconProps>(function Share(
  { width = 24, height, color = '#102F54', ...props },
  ref,
) {
  return (
    <svg
      width={width}
      height={height ?? width}
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      ref={ref}
      {...props}>
      <path
        d='M6.69283 5.11279H3.54077C2.68983 5.11279 2 5.80262 2 6.65357V18.4037C2 19.2546 2.68983 19.9444 3.54077 19.9444H15.291C16.1419 19.9444 16.8317 19.2546 16.8317 18.4037V15.7732'
        stroke={color}
        strokeOpacity='0.25'
        strokeWidth='2.31116'
        strokeLinecap='round'
      />
      <path
        d='M21.9444 1.1732C21.9444 0.525261 21.4191 0 20.7712 0H10.2124C9.56441 0 9.03915 0.525261 9.03915 1.1732C9.03915 1.82114 9.56441 2.34641 10.2124 2.34641H19.598V11.732C19.598 12.38 20.1232 12.9052 20.7712 12.9052C21.4191 12.9052 21.9444 12.38 21.9444 11.732V1.1732ZM9.2998 12.6446C9.75796 13.1027 10.5008 13.1027 10.9589 12.6445L21.6007 2.00279L19.9416 0.343623L9.2998 10.9854C8.84163 11.4436 8.84163 12.1864 9.2998 12.6446Z'
        fill={color}
        fillOpacity='0.25'
      />
    </svg>
  );
});
