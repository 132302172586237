import type { IconProps } from './Icon.types';

export const ErrorIcon = ({ width = 14, height = 14, color, ...props }: IconProps) => (
  <svg width={width} height={height} viewBox='0 0 165 165' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M15 15L82.5 82.5M150 150L82.5 82.5M150 15L82.5 82.5M15 150L82.5 82.5'
      stroke={color ?? '#DA3732'}
      strokeWidth='30'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
