import { useId } from 'react';

import type { IconProps } from './Icon.types';

export const XIcon = ({ color = 'currentColor', ...props }: Omit<IconProps, 'height' | 'width'>) => {
  const id = useId();
  return (
    <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath={`url(#${id})`}>
        <path
          d='M23.8093 16.9392L38.7027 0H35.1747L22.2373 14.705L11.912 0H0L15.6173 22.2387L0 39.9999H3.528L17.1813 24.4675L28.088 39.9999H40M4.80133 2.60316H10.2213L35.172 37.5246H29.7507'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width='40' height='40' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
