import type { IconProps } from './Icon.types';

export const ExternalIcon = ({
  width = 18,
  height,
  strokeColor = '#ADBACC',
  strokeWidth = 2,
  color = '#ADBACC',
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height ?? width}
    viewBox='0 0 18 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}>
    <path
      d='M5.00002 4.35791H1V17H13.6421V13.4446'
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      strokeLinecap='round'
    />
    <path
      d='M18.0005 1C18.0005 0.447715 17.5528 0 17.0005 0H8.00049C7.44821 0 7.00049 0.447715 7.00049 1C7.00049 1.55228 7.44821 2 8.00049 2H16.0005V10C16.0005 10.5523 16.4482 11 17.0005 11C17.5528 11 18.0005 10.5523 18.0005 10V1ZM7.92976 11.4849L17.7076 1.70711L16.2934 0.292893L6.51555 10.0707L7.92976 11.4849Z'
      fill={color}
    />
  </svg>
);
