import clsx from 'clsx';

import type { CountryCode } from '@endaoment-frontend/types';

import styles from './FlagIcon.module.scss';
import type { IconProps } from './Icon.types';

export const FlagIcon = ({ countryCode, className }: IconProps & { countryCode: CountryCode }) => {
  return <span className={clsx(styles['flag'], styles[`flag--${countryCode}`], className)}></span>;
};
