import type { IconProps } from './Icon.types';

export const BitcoinIcon = ({ width = 32, height, color, ...props }: IconProps) => {
  return (
    <svg
      width={width}
      height={height ?? width}
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.7236 3.34412C15.3199 3.6051 17.356 4.41311 17.5816 6.82C17.7348 8.57467 17.0083 9.61871 15.807 10.2071C17.7465 10.7 18.9264 11.859 18.6825 14.4298C18.3857 17.6523 16.0182 18.4892 12.6333 18.6658L12.6386 21.9926L10.6451 21.9952L10.6398 18.6684C10.4219 18.6651 10.1941 18.6685 9.96046 18.6718C9.64827 18.6764 9.32574 18.6812 9.00242 18.6706L9.00779 21.9974L7.01788 22L7.01257 18.6732L3.02593 18.5929L3.36189 16.1616C3.36189 16.1616 4.86757 16.1953 4.83966 16.1777C5.39396 16.1747 5.55848 15.7746 5.59945 15.5118L5.58954 10.1787L5.60519 6.38317C5.54398 5.98753 5.27956 5.51283 4.46464 5.49842C4.50901 5.4579 3.00504 5.50033 3.00504 5.50033L3 3.27139L7.0853 3.26604L7.08012 0.00738393L9.13821 0.00468855L9.14345 3.26335C9.54076 3.25214 9.9327 3.25536 10.329 3.25859C10.4591 3.25966 10.5897 3.26072 10.721 3.26129L10.7158 0.00262588L12.7183 0L12.7236 3.34412ZM8.89844 9.4753L8.99954 9.47969C10.2111 9.53346 13.8037 9.69296 13.8092 7.47517C13.8037 5.36958 10.7462 5.43752 9.32561 5.46909C9.15786 5.47282 9.01289 5.47603 8.89844 5.47511V9.4753ZM9.06815 16.0076C9.00451 16.005 8.94598 16.0025 8.89281 16.0005V12.0003C9.01944 12.0016 9.17855 11.9995 9.36254 11.9972C11.0415 11.9759 14.7914 11.9283 14.7736 14.051C14.7925 16.2466 10.5598 16.0699 9.06815 16.0076Z'
        fill={color ?? '#53ACDE'}
      />
    </svg>
  );
};
