import type { IconProps } from './Icon.types';

export const ScanIcon = ({ width = 36, height, color = 'black', ...props }: IconProps) => (
  <svg
    width={width}
    height={height ?? width}
    viewBox='0 0 36 36'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}>
    <path
      d='M30 23V26C30 28.2091 28.2091 30 26 30H23M30 13V10C30 7.79086 28.2091 6 26 6H23M6 23V26C6 28.2091 7.79086 30 10 30H13M6 13V10C6 7.79086 7.79086 6 10 6H13'
      stroke={color}
      strokeWidth='2.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M12 13H24' stroke={color} strokeWidth='2.5' strokeLinecap='round' />
    <path d='M12 18H24' stroke={color} strokeWidth='2.5' strokeLinecap='round' />
    <path d='M12 23H24' stroke={color} strokeWidth='2.5' strokeLinecap='round' />
  </svg>
);
