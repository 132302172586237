import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/react';
import { Widget as TypeformWidget } from '@typeform/embed-react';
import { atom, useAtom } from 'jotai';
import { useCallback, useMemo, type ReactNode } from 'react';
import { useMount } from 'react-use';

import styles from './TypeformModal.module.scss';

type TypeformSettings = {
  isOpen: boolean;
  /**
   * The ID of the typeform to open
   */
  id: string;
  /**
   * Fields that are not visible to the user but are sent with the form
   * In a regular typeform url, these are added in the URL hash
   * @default undefined
   */
  hiddenFields?: Record<string, string>;
};

const typeformModalSettingsAtom = atom<TypeformSettings>({
  isOpen: false,
  id: '',
  hiddenFields: undefined,
});

export const useTypeformModal = (options: Omit<TypeformSettings, 'isOpen'>) => {
  const [typeformSettings, setTypeformSettings] = useAtom(typeformModalSettingsAtom);

  const isOpen = useMemo(() => {
    return typeformSettings.id === options.id && typeformSettings.isOpen;
  }, [options.id, typeformSettings.id, typeformSettings.isOpen]);
  const openTypeform = useCallback(() => {
    setTypeformSettings({ ...options, isOpen: true });
  }, [options, setTypeformSettings]);

  return { isOpen, openTypeform };
};

export const TypeformModalProvider = ({ children }: { children?: Array<ReactNode> | ReactNode }) => {
  const [typeformSettings, setTypeformSettings] = useAtom(typeformModalSettingsAtom);
  return (
    <>
      {children}
      <Modal
        isOpen={typeformSettings.isOpen}
        isCentered
        onClose={() => setTypeformSettings({ id: '', isOpen: false, hiddenFields: undefined })}
        size='6xl'>
        <ModalOverlay />
        <ModalContent className={styles['typeform-modal']}>
          <TypeformWidget
            id={typeformSettings.id}
            hidden={typeformSettings.hiddenFields}
            inlineOnMobile
            className={styles['typeform-iframe']}
          />
        </ModalContent>
      </Modal>
    </>
  );
};

export const TypeformOpener = ({ onOpen, ...options }: Omit<TypeformSettings, 'isOpen'> & { onOpen?: () => void }) => {
  const { openTypeform } = useTypeformModal(options);

  useMount(() => {
    onOpen?.();
    openTypeform();
  });

  return null;
};
