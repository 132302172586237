import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { useLocalStorage } from 'react-use';

import { useIsMounted } from '@endaoment-frontend/hooks';

import { Button } from './Button';
import { cardClassNames } from './Card';
import styles from './CookieModal.module.scss';

export const CookieModal = () => {
  const [isModalOpen, setModalOpen] = useLocalStorage('cookieModalOpen', true);
  const isMounted = useIsMounted();

  return (
    <AnimatePresence initial>
      {!!isModalOpen && !!isMounted && (
        <motion.div
          className={clsx(cardClassNames.base, styles['cookie-modal'])}
          initial={{ x: '-100vw', opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: '-100vw', opacity: 0, transitionEnd: { display: 'none' } }}
          transition={{ duration: 0.5, ease: 'easeInOut' }}>
          <div>
            <h3>We Use Cookies</h3>
            <p>Cookies make interacting with our contracts a more cohesive process.</p>
          </div>
          <Button
            onClick={() => {
              setModalOpen(false);
            }}
            filled
            size='medium'
            variation='fund'>
            Okay
          </Button>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
