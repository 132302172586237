import { forwardRef, type ComponentPropsWithRef, type ReactNode } from 'react';
import Tilt from 'react-parallax-tilt';

const TiltBoxWithRef = (
  {
    className,
    children,
    enabled = true,
  }: { className?: string; children: Array<ReactNode> | ReactNode; enabled?: boolean },
  ref: ComponentPropsWithRef<typeof Tilt>['ref'],
) => {
  return (
    <Tilt perspective={1000} tiltMaxAngleX={5} tiltMaxAngleY={5} ref={ref} className={className} tiltEnable={enabled}>
      {children}
    </Tilt>
  );
};

export const TiltBox = forwardRef(TiltBoxWithRef);
