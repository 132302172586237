import type { IconProps } from './Icon.types';

export const SearchIcon = ({ width = 20, height, color, strokeColor = '#adbacc', ...props }: IconProps) => (
  <svg
    width={width}
    height={height ?? width}
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}>
    <circle cx='8.02222' cy='8.31129' r='6.52222' stroke={color ?? strokeColor} strokeWidth='3' />
    <line x1='13.796' y1='13.0932' x2='19.7071' y2='19.0043' stroke={color ?? strokeColor} strokeWidth='3' />
  </svg>
);
