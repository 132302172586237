import type { IconProps } from './Icon.types';

export const DeployedIcon = ({ width = 24, height, className, color }: IconProps) => (
  <svg
    width={width}
    height={height ?? width}
    className={className}
    viewBox='20 15 100 110'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='m53.596 99.897-7.5941 9.2439c-3.8564 4.694-10.788 5.373-15.482 1.516-4.6941-3.856-5.3732-10.788-1.5167-15.482l11.426-13.908c3.8564-4.6941 10.788-5.3732 15.482-1.5167'
      stroke={color ?? '#C4C4C4'}
      strokeWidth='8'
      strokeLinecap='round'
    />
    <path
      d='m51.244 68.103 7.5941-9.2436c3.8564-4.6941 10.788-5.3732 15.482-1.5167s5.3732 10.788 1.5167 15.482l-11.426 13.908c-3.8565 4.6941-10.788 5.3732-15.482 1.5167'
      stroke={color ?? '#C4C4C4'}
      strokeWidth='8'
      strokeLinecap='round'
    />
    <path
      d='m38.92 64.5v-27.5c0-8.2843 6.7158-15 15-15h44c8.2841 0 15 6.7157 15 15v64.5c0 8.284-6.716 15-15 15h-40'
      stroke={color ?? '#C4C4C4'}
      strokeWidth='8'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='m82.42 85.5h15' stroke={color ?? '#C4C4C4'} strokeLinecap='round' strokeWidth='8' />
    <path d='m69.42 101.5h28' stroke={color ?? '#C4C4C4'} strokeWidth='8' strokeLinecap='round' />
    <path d='m91.42 69.5h6' stroke={color ?? '#C4C4C4'} strokeLinecap='round' strokeWidth='8' />
    <path d='m87.42 53.5h10' stroke={color ?? '#C4C4C4'} strokeLinecap='round' strokeWidth='8' />
    <path d='m54.42 37.5h43' stroke={color ?? '#C4C4C4'} strokeLinecap='round' strokeWidth='8' />
  </svg>
);
