import type { IconProps } from './Icon.types';

export const LogoTechIcon = ({ color, width = 290, height = 47 }: IconProps) => {
  return (
    <svg width={width} height={height} viewBox='0 0 290 47' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M34.7727 17.993C32.025 17.993 29.9838 19.0966 28.7277 20.8472C28.7277 19.6282 27.7395 18.6399 26.5205 18.6399H23.9781V38.0867H29.081V26.3653C29.081 23.9297 30.7689 22.2553 33.1241 22.2553C35.5577 22.2553 36.8531 23.7014 36.8531 26.2512V38.0867H41.956V24.767C41.956 20.8091 39.4438 17.993 34.7727 17.993Z'
        fill={color ?? '#393F49'}
      />
      <path
        d='M59.5909 11.0667V20.6569C58.2563 19.0205 56.2937 18.1071 53.5852 18.1071C48.4824 18.1071 44.6748 22.065 44.6748 28.3823C44.6748 34.6616 48.4431 38.6575 53.546 38.6575C56.5292 38.6575 58.6096 37.4778 59.9442 35.5369C59.9442 36.9451 61.0858 38.0867 62.494 38.0867H64.6938V11.0667H59.5909ZM54.7236 34.3952C51.7011 34.3952 49.8562 32.0357 49.8562 28.3823C49.8562 24.6909 51.8581 22.4075 54.7628 22.4075C57.589 22.4075 59.7872 24.5767 59.7872 28.3823C59.7872 32.1118 57.6675 34.3952 54.7236 34.3952Z'
        fill={color ?? '#393F49'}
      />
      <path
        d='M77.1618 17.993C73.1972 17.993 70.2925 19.4772 67.7803 22.3694L70.9991 25.2237C73.0795 23.2828 74.4926 22.065 77.2403 22.065C79.5562 22.065 81.0086 23.1306 81.0086 25.0334V25.4901L76.2982 26.3273C71.7449 27.1265 67.6233 28.6868 67.6233 33.1774C67.6233 37.1353 70.9991 38.7336 74.8066 38.7336C77.9076 38.7336 80.0665 37.7061 81.2833 36.0697C81.2833 37.1836 82.1864 38.0867 83.3003 38.0867H85.8759V24.9573C85.8759 20.8472 82.932 17.993 77.1618 17.993ZM75.6702 34.7758C73.7468 34.7758 72.6084 34.0527 72.6084 32.8349C72.6084 31.0082 74.6103 30.3232 77.6328 29.7143L81.0086 29.0293V30.171C81.0086 33.558 78.2609 34.7758 75.6702 34.7758Z'
        fill={color ?? '#393F49'}
      />
      <path
        d='M98.9695 17.993C92.8461 17.993 88.5282 22.1792 88.5282 28.3443C88.5282 34.5094 92.8461 38.6956 98.9695 38.6956C105.054 38.6956 109.371 34.5094 109.371 28.3443C109.371 22.1792 105.054 17.993 98.9695 17.993ZM98.9695 22.1792C102.11 22.1792 104.19 24.6148 104.19 28.3443C104.19 32.1118 102.11 34.5094 98.9695 34.5094C95.79 34.5094 93.7096 32.1118 93.7096 28.3443C93.7096 24.6148 95.79 22.1792 98.9695 22.1792Z'
        fill={color ?? '#393F49'}
      />
      <path
        d='M134.092 17.993C131.148 17.993 129.264 19.2108 128.086 20.8472C127.026 19.0586 125.142 17.993 122.512 17.993C119.686 17.993 117.841 19.1347 116.781 20.8091C116.781 19.6111 115.81 18.6399 114.612 18.6399H112.032V38.0867H117.135V26.137C117.135 23.9678 118.352 22.2553 120.667 22.2553C123.023 22.2553 123.847 23.8156 123.847 25.9848V38.0867H128.95V26.137C128.95 23.9297 130.167 22.2553 132.483 22.2553C134.798 22.2553 135.662 23.8156 135.662 25.9848V38.0867H140.765V24.5006C140.765 20.5427 138.41 17.993 134.092 17.993Z'
        fill={color ?? '#393F49'}
      />
      <path
        d='M154.06 34.5094C150.566 34.5094 148.839 32.4543 148.408 29.6382H162.813C162.853 29.2196 162.892 28.3062 162.892 27.6973C162.892 21.7225 159.634 17.993 153.51 17.993C147.191 17.993 143.422 22.4456 143.422 28.4584C143.422 34.6616 147.465 38.6956 153.942 38.6956C157.2 38.6956 159.948 37.7822 162.892 34.8899L160.026 31.8454C157.671 33.7483 156.415 34.5094 154.06 34.5094ZM153.353 21.8747C156.14 21.8747 157.71 23.5111 157.907 26.099H148.525C149.075 23.5872 150.645 21.8747 153.353 21.8747Z'
        fill={color ?? '#393F49'}
      />
      <path
        d='M176.456 17.993C173.708 17.993 171.667 19.0966 170.411 20.8472C170.411 19.6282 169.422 18.6399 168.203 18.6399H165.661V38.0867H170.764V26.3653C170.764 23.9297 172.452 22.2553 174.807 22.2553C177.241 22.2553 178.536 23.7014 178.536 26.2512V38.0867H183.639V24.767C183.639 20.8091 181.127 17.993 176.456 17.993Z'
        fill={color ?? '#393F49'}
      />
      <path
        d='M198.613 33.9005C197.318 34.2049 196.689 34.243 196.101 34.243C194.766 34.243 194.099 33.8244 194.099 32.4543V22.4075H198.888V18.6399H194.099V12.6271H188.996V18.6399H185.424V22.4075H188.996V33.0632C188.996 36.6786 190.605 38.5433 194.688 38.5433C195.983 38.5433 197.435 38.3911 199.202 37.8203L198.613 33.9005Z'
        fill={color ?? '#393F49'}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.3544 10.2403H0.00174206V12.8643H0.000210285L0.000209912 21.399H0V26.6513H0.000209683L0.000209395 33.219H0V38.4713H20.3527V33.219H5.25251L5.25251 26.6513H20.3527V21.399H5.25251L5.25251 15.4926H20.3544V10.2403Z'
        fill={color ?? '#393F49'}
      />
      <path
        d='M207.99 39.2479C209.584 39.2479 210.823 38.0438 210.823 36.4856C210.823 34.9274 209.584 33.688 207.99 33.688C206.432 33.688 205.192 34.9274 205.192 36.4856C205.192 38.0438 206.432 39.2479 207.99 39.2479ZM217.015 39H221.76V17.3977H228.807V13.6793H209.967V17.3977H217.015V39ZM237.725 39.3541C242.47 39.3541 245.622 37.2293 246.224 33.3338H242.081C241.762 35.1399 240.416 36.1669 237.831 36.1669C234.644 36.1669 232.873 34.1838 232.732 30.6778H246.295V29.4383C246.295 22.816 242.152 20.0891 237.548 20.0891C232.271 20.0891 228.34 23.9138 228.34 29.6508V29.9341C228.34 35.7774 232.271 39.3541 237.725 39.3541ZM232.802 27.8093C233.227 24.9408 234.963 23.2055 237.548 23.2055C240.204 23.2055 241.833 24.6221 242.045 27.8093H232.802ZM258.232 39.3541C263.261 39.3541 266.483 36.344 266.802 32.0943H262.871C262.623 34.715 260.676 36.0607 258.409 36.0607C255.257 36.0607 253.274 33.9359 253.274 29.8987V29.6154C253.274 25.7907 255.364 23.5243 258.303 23.5243C260.569 23.5243 262.198 24.5513 262.553 27.0302H266.696C266.2 21.9306 262.34 20.0891 258.232 20.0891C253.062 20.0891 248.883 23.8076 248.883 29.6862V29.9695C248.883 35.8836 252.92 39.3541 258.232 39.3541ZM270.492 39H274.777V28.0926C274.777 25.1533 276.725 23.6659 279.133 23.6659C281.683 23.6659 282.816 24.9408 282.816 27.7385V39H287.101V27.3489C287.101 22.2494 284.48 20.0891 280.762 20.0891C277.716 20.0891 275.662 21.6119 274.777 23.418V11.9086H270.492V39Z'
        fill={color ?? '#EA6B0E'}
      />
    </svg>
  );
};
