import Script from 'next/script';

const SCHEMA_URL = 'https://schema.org/';

type StructuredDataPostalAddress = {
  '@type': 'PostalAddress';
  'addressCountry': string;
  'addressLocality'?: string;
  'addressRegion'?: string;
  'postalCode'?: string;
  'streetAddress'?: string;
};
type StructuredDataPerson = {
  '@type': 'Person';
  'givenName': string;
  'familyName': string;
};
type GoogleStructuredData = {
  '@type': string;
  'name': string;
  'description'?: string;
  'url'?: string;
  'identifier'?: string;
  'image'?: string;
} & (
  | {
      '@type': 'FundingScheme';
      'address'?: StructuredDataPostalAddress;
      'logo'?: string;
      'founder'?: StructuredDataPerson;
      'foundingDate'?: string;
      'sponsor'?: StructuredDataPerson;
      'member'?: StructuredDataPerson;
      'funder'?: StructuredDataPerson;
    }
  | {
      '@type': 'Organization';
      'address'?: StructuredDataPostalAddress;
      'logo'?: string;
    }
  | {
      '@type': 'WebApplication';
      'applicationCategory': string;
      'offers': { '@type': 'Offer'; 'price': string; 'priceCurrency': string };
      'operatingSystem': string;
    }
  | { '@type': 'Thing' }
);

export const StructuredDataScript = ({ structuredData }: { structuredData: GoogleStructuredData }) => {
  return (
    // eslint-disable-next-line @eslint-react/dom/no-dangerously-set-innerhtml
    <Script
      type='application/ld+json'
      strategy='beforeInteractive'
      dangerouslySetInnerHTML={{
        __html: JSON.stringify({
          '@context': SCHEMA_URL,
          ...structuredData,
        }),
      }}
    />
  );
};
