export enum NDAO_WALLETS {
  ZACH = '0x9ba4a587E757c8C6E3e2063c8634fC1D24F4a675',
  ROBBIE = '0x78b037b39704e88a82dd23cfbe1f57f6aef8ebc5',
  PEDRO = '0x5398785700a9248cfc2101f0188c9788e8619974',
  RICARDO = '0x77dfb7afae21622274751358d01ad5f1d59d202a',
  NOAH = '0xc8b810fe39952aa65ca5a9387a3546b9b6bf5780',
  ISHAAN = '0x61752014a3E9D04c3Ea5dE61Fed4667Ca43676D5',
  ALEXIS = '0x88100996d867585258f88b01cfe44cb60119eef0',
  DAE = '0xbf8e57a8195eed88f7663acf2af0b7eacdbd69bc',
  TEST_PAUSER_WALLET = '0xbb77d2c8c6fec55285c782356333afdcd4a926d8',
  PIETRO = '0xc6f1beb66fa3d40b818f969d219958947cd4b448',
  BRUXA = '0xe17b2975e5c6646f6aeaf49b4e19d2567c517833',
}

export const DOT_ORG_TEAM = [
  NDAO_WALLETS.ALEXIS,
  NDAO_WALLETS.DAE,
  NDAO_WALLETS.ROBBIE,
  NDAO_WALLETS.ZACH,
  NDAO_WALLETS.BRUXA,
] as const;
