import type { IconProps } from './Icon.types';

export const HamburgerIcon = ({
  width = 20,
  height,
  strokeColor = '#696F8C',
  strokeWidth = 2.5,
  ...props
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height ?? width}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <line x1='0' y1='2' x2='20' y2='2' stroke={strokeColor} strokeWidth={strokeWidth} strokeLinecap='round' />
      <line x1='0' y1='10' x2='20' y2='10' stroke={strokeColor} strokeWidth={strokeWidth} strokeLinecap='round' />
      <line x1='0' y1='18' x2='20' y2='18' stroke={strokeColor} strokeWidth={strokeWidth} strokeLinecap='round' />
    </svg>
  );
};
