import { z } from 'zod';

import { uuidSchema } from '../general';

export const entityTypeSchema = z.enum(['fund', 'org']);
export type EntityType = z.infer<typeof entityTypeSchema>;

export const entityLabelSchema = z.object({
  type: z.enum([...entityTypeSchema.options, 'subproject']),
  name: z.string(),
  id: uuidSchema,
});
export type EntityLabel = z.infer<typeof entityLabelSchema>;

export const stockTickerSchema = z.object({
  ticker: z.string(),
  name: z.string(),
});
export type StockTicker = z.infer<typeof stockTickerSchema>;

export const employeeStockPlanSchema = z.enum(['Exercised', 'ESPP', 'Restricted']);
export type EmployeeStockPlan = z.infer<typeof employeeStockPlanSchema>;

export const stockLotSchema = z.object({
  numberOfShares: z.number(),
  purchasePrice: z.number(),
  purchaseDate: z.string(),
  lotId: z
    .string()
    .regex(/^[a-z\d]*$/i)
    .optional(),
  employeeStockPlan: employeeStockPlanSchema.optional(),
});
export type StockLot = z.infer<typeof stockLotSchema>;

export const portfolioTradeTypeSchema = z.enum(['Buy', 'Sell']);
