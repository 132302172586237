import type { IconProps } from './Icon.types';

export const WalletIcon = ({ width = 22, height, color, ...props }: IconProps) => (
  <svg
    width={width}
    height={height ?? width}
    viewBox='0 0 22 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M19 20H3C1.89543 20 1 19.1046 1 18V6.875V3.4375C1 4.30726 2.0701 4.875 3.4375 4.875H7H19C20.1046 4.875 21 5.77043 21 6.875V8.40625H15C13.8954 8.40625 13 9.30168 13 10.4062V14.4688C13 15.5733 13.8954 16.4688 15 16.4688H21V18C21 19.1046 20.1046 20 19 20Z'
      fill={color ?? '#53ACDE'}
      fillOpacity='0.25'
    />
    <path
      d='M19 4.875V4.875C20.1046 4.875 21 5.77043 21 6.875V6.875M19 4.875H7H3.4375C2.0701 4.875 1 4.30726 1 3.4375M19 4.875V4M19 4.875C20.1046 4.875 21 5.77043 21 6.875M1 3.4375V6.875V18M1 3.4375C1 2.56774 2 2 3.4375 2H17M21 8.40625V12.9375V16.4688M21 8.40625V16.4688M21 8.40625H15C13.8954 8.40625 13 9.30168 13 10.4062V14.4688C13 15.5733 13.8954 16.4688 15 16.4688H21M21 8.40625V6.875M21 16.4688V18M19 20H3M19 20C20.1046 20 21 19.1046 21 18M19 20V20C20.1046 20 21 19.1046 21 18V18M3 20C1.89543 20 1 19.1046 1 18M3 20V20C1.89543 20 1 19.1046 1 18V18M17 2C18.1046 2 19 2.89543 19 4M17 2V2C18.1046 2 19 2.89543 19 4V4'
      stroke={color ?? '#53ACDE'}
      strokeWidth='1.5'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M21 8.40625V12.9375V16.4688H15C13.8954 16.4688 13 15.5733 13 14.4688V10.4062C13 9.30168 13.8954 8.40625 15 8.40625H21ZM17.5 14C18.3284 14 19 13.3284 19 12.5C19 11.6716 18.3284 11 17.5 11C16.6716 11 16 11.6716 16 12.5C16 13.3284 16.6716 14 17.5 14Z'
      fill={color ?? '#53ACDE'}
      fillOpacity='0.25'
    />
    <path
      d='M21 8.40625V12.9375V16.4688M21 8.40625V16.4688M21 8.40625H15C13.8954 8.40625 13 9.30168 13 10.4062V14.4688C13 15.5733 13.8954 16.4688 15 16.4688H21M19 12.5C19 13.3284 18.3284 14 17.5 14C16.6716 14 16 13.3284 16 12.5C16 11.6716 16.6716 11 17.5 11C18.3284 11 19 11.6716 19 12.5Z'
      stroke={color ?? '#53ACDE'}
      strokeWidth='1.5'
    />
    <circle
      cx='17.5'
      cy='12.5'
      r='1.5'
      fill={color ?? '#53ACDE'}
      fillOpacity='0.25'
      stroke={color ?? '#53ACDE'}
      strokeWidth='1.5'
    />
  </svg>
);
