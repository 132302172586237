import type { IconProps } from './Icon.types';

export const ExternalSmallIcon = ({ width = 18, height, color = 'black' }: IconProps) => (
  <svg width={width} height={height ?? width} viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M5.00002 4.35788H1V16.9999H13.6421V13.4445' stroke={color} strokeWidth='2' strokeLinecap='round' />
    <path
      d='M18 1C18 0.447715 17.5523 0 17 0H8C7.44772 0 7 0.447715 7 1C7 1.55228 7.44772 2 8 2H16V10C16 10.5523 16.4477 11 17 11C17.5523 11 18 10.5523 18 10V1ZM7.92927 11.4849L17.7071 1.70711L16.2929 0.292893L6.51506 10.0707L7.92927 11.4849Z'
      fill={color}
    />
  </svg>
);
