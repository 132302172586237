import { useId } from 'react';

import type { IconProps } from '../Icon.types';

export const PrivateActivityIcon = ({ width = 36, height = 36, color, className, ...props }: IconProps) => {
  const id = useId();
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <circle cx='14' cy='10' r='5.25' fill='#EA6B0E' fillOpacity='0.25' stroke='#EA6B0E' strokeWidth='1.5' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21 22.9231C20.4477 22.9231 20 23.3708 20 23.9231V32C20 32.5523 20.4477 33 21 33H30C30.5523 33 31 32.5523 31 32V23.9231C31 23.3708 30.5523 22.9231 30 22.9231H21ZM25.5 29.5C26.3284 29.5 27 28.8284 27 28C27 27.1716 26.3284 26.5 25.5 26.5C24.6716 26.5 24 27.1716 24 28C24 28.8284 24.6716 29.5 25.5 29.5Z'
        fill={color ?? '#696F8C'}
        fillOpacity='0.25'
      />
      <path
        d='M20.75 23.9231C20.75 23.785 20.8619 23.6731 21 23.6731V22.1731C20.0335 22.1731 19.25 22.9566 19.25 23.9231H20.75ZM20.75 32V23.9231H19.25V32H20.75ZM21 32.25C20.8619 32.25 20.75 32.1381 20.75 32H19.25C19.25 32.9665 20.0335 33.75 21 33.75V32.25ZM30 32.25H21V33.75H30V32.25ZM30.25 32C30.25 32.1381 30.1381 32.25 30 32.25V33.75C30.9665 33.75 31.75 32.9665 31.75 32H30.25ZM30.25 23.9231V32H31.75V23.9231H30.25ZM30 23.6731C30.1381 23.6731 30.25 23.785 30.25 23.9231H31.75C31.75 22.9566 30.9665 22.1731 30 22.1731V23.6731ZM21 23.6731H30V22.1731H21V23.6731ZM26.25 28C26.25 28.4142 25.9142 28.75 25.5 28.75V30.25C26.7426 30.25 27.75 29.2426 27.75 28H26.25ZM25.5 27.25C25.9142 27.25 26.25 27.5858 26.25 28H27.75C27.75 26.7574 26.7426 25.75 25.5 25.75V27.25ZM24.75 28C24.75 27.5858 25.0858 27.25 25.5 27.25V25.75C24.2574 25.75 23.25 26.7574 23.25 28H24.75ZM25.5 28.75C25.0858 28.75 24.75 28.4142 24.75 28H23.25C23.25 29.2426 24.2574 30.25 25.5 30.25V28.75ZM22.75 22.9231V21.0769H21.25V22.9231H22.75ZM22.75 21.0769V21H21.25V21.0769H22.75ZM28.25 21V21.0769H29.75V21H28.25ZM28.25 21.0769V22.9231H29.75V21.0769H28.25ZM25.5 18.25C27.0188 18.25 28.25 19.4812 28.25 21H29.75C29.75 18.6528 27.8472 16.75 25.5 16.75V18.25ZM25.5 16.75C23.1528 16.75 21.25 18.6528 21.25 21H22.75C22.75 19.4812 23.9812 18.25 25.5 18.25V16.75Z'
        fill={color ?? '#696F8C'}
      />
      <mask id={id} fill='white'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M8.66965 14.5376C5.86376 16.3087 4 19.4367 4 23.0001V26.091C4 27.6976 5.30245 29.0001 6.90909 29.0001H18.25V23.9231C18.25 22.6643 19.0957 21.6031 20.25 21.2766V21.0769V21C20.25 19.4262 20.9425 18.0141 22.0394 17.0519C21.301 16.0556 20.3818 15.2013 19.3304 14.5376C18.0464 16.0444 16.1348 17.0001 14 17.0001C11.8652 17.0001 9.9536 16.0444 8.66965 14.5376ZM23.8335 21.1731C23.8111 21.052 23.7866 20.9318 23.76 20.8123C23.7534 20.874 23.75 20.9366 23.75 21V21.0769V21.1731H23.8335Z'
        />
      </mask>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.66965 14.5376C5.86376 16.3087 4 19.4367 4 23.0001V26.091C4 27.6976 5.30245 29.0001 6.90909 29.0001H18.25V23.9231C18.25 22.6643 19.0957 21.6031 20.25 21.2766V21.0769V21C20.25 19.4262 20.9425 18.0141 22.0394 17.0519C21.301 16.0556 20.3818 15.2013 19.3304 14.5376C18.0464 16.0444 16.1348 17.0001 14 17.0001C11.8652 17.0001 9.9536 16.0444 8.66965 14.5376ZM23.8335 21.1731C23.8111 21.052 23.7866 20.9318 23.76 20.8123C23.7534 20.874 23.75 20.9366 23.75 21V21.0769V21.1731H23.8335Z'
        fill={color ?? '#EA6B0E'}
        fillOpacity='0.25'
      />
      <path
        d='M8.66965 14.5376L9.81138 13.5647L8.96827 12.5753L7.869 13.2692L8.66965 14.5376ZM18.25 29.0001V30.5001H19.75V29.0001H18.25ZM20.25 21.2766L20.6582 22.72L21.75 22.4112V21.2766H20.25ZM22.0394 17.0519L23.0286 18.1795L24.067 17.2686L23.2445 16.1588L22.0394 17.0519ZM19.3304 14.5376L20.131 13.2692L19.0317 12.5753L18.1886 13.5647L19.3304 14.5376ZM23.76 20.8123L25.2239 20.4855L22.2684 20.6532L23.76 20.8123ZM23.8335 21.1731V22.6731H25.6358L25.3086 20.9008L23.8335 21.1731ZM23.75 21.1731H22.25V22.6731H23.75V21.1731ZM5.5 23.0001C5.5 19.9729 7.08141 17.3139 9.4703 15.806L7.869 13.2692C4.64611 15.3035 2.5 18.9006 2.5 23.0001H5.5ZM5.5 26.091V23.0001H2.5V26.091H5.5ZM6.90909 27.5001C6.13087 27.5001 5.5 26.8692 5.5 26.091H2.5C2.5 28.526 4.47402 30.5001 6.90909 30.5001V27.5001ZM18.25 27.5001H6.90909V30.5001H18.25V27.5001ZM16.75 23.9231V29.0001H19.75V23.9231H16.75ZM19.8418 19.8332C18.0587 20.3375 16.75 21.9755 16.75 23.9231H19.75C19.75 23.3531 20.1328 22.8686 20.6582 22.72L19.8418 19.8332ZM18.75 21.0769V21.2766H21.75V21.0769H18.75ZM18.75 21V21.0769H21.75V21H18.75ZM21.0502 15.9243C19.6424 17.1592 18.75 18.9763 18.75 21H21.75C21.75 19.8761 22.2426 18.869 23.0286 18.1795L21.0502 15.9243ZM18.5297 15.806C19.4239 16.3704 20.206 17.0973 20.8343 17.945L23.2445 16.1588C22.396 15.0138 21.3398 14.0322 20.131 13.2692L18.5297 15.806ZM14 18.5001C16.5926 18.5001 18.9153 17.3375 20.4721 15.5105L18.1886 13.5647C17.1775 14.7514 15.677 15.5001 14 15.5001V18.5001ZM7.52792 15.5105C9.08469 17.3375 11.4074 18.5001 14 18.5001V15.5001C12.323 15.5001 10.8225 14.7514 9.81138 13.5647L7.52792 15.5105ZM22.296 21.139C22.3186 21.2404 22.3394 21.3426 22.3584 21.4454L25.3086 20.9008C25.2829 20.7615 25.2546 20.6231 25.2239 20.4855L22.296 21.139ZM25.25 21C25.25 20.9898 25.2505 20.9803 25.2515 20.9714L22.2684 20.6532C22.2562 20.7676 22.25 20.8833 22.25 21H25.25ZM25.25 21.0769V21H22.25V21.0769H25.25ZM25.25 21.1731V21.0769H22.25V21.1731H25.25ZM23.8335 19.6731H23.75V22.6731H23.8335V19.6731Z'
        fill={color ?? '#EA6B0E'}
        mask={`url(#${id})`}
      />
    </svg>
  );
};
