import type { RawCreateParams, ZodTypeAny } from 'zod';
import { z } from 'zod';

import type {
  CONTINENT,
  COUNTRY_GEODATA,
  COUNTRY_ISO3166_ALPHA3,
  LETTERS,
  US_STATES,
  US_STATES_TO_FULL,
} from '@endaoment-frontend/constants';

export type Branded<T, U> = T & { __brand: U };
export type TypeEquals<T, S> = [T] extends [S] ? ([S] extends [T] ? true : false) : false;

export type Digit = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
export type Letter = (typeof LETTERS)[number];
export type AlphaNumeric = Digit | Letter | Lowercase<Letter>;
export type UUID = Branded<string, 'UUID'>;
const GUID_REGEX = /^[\da-fA-F]{8}\b-[\da-fA-F]{4}\b-[\da-fA-F]{4}\b-[\da-fA-F]{4}\b-[\da-fA-F]{12}$/;
export const uuidSchema = z.custom<UUID>(
  v => {
    if (typeof v !== 'string') return false;
    return GUID_REGEX.test(v);
  },
  {
    message: `Invalid UUID`,
  },
);
export const isUuid = (v: string): v is UUID => uuidSchema.safeParse(v).success;

export const phoneNumberSchema = z
  .string()
  .refine(v => /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/im.test(v), {
    message: `Invalid phone number, please enter a number in the format '+X XXX-XXX-XXXX'`,
  });

export const percentageNumberSchema = z.number().min(0).max(1).describe('A percentage number between 0 and 1');
export type EIN = Branded<string, 'EIN'>;
export const einSchema = z
  .custom<EIN>(v => z.string().min(9).max(9).safeParse(v).success, {
    message: `Invalid EIN`,
  })
  .describe(`A string composed of 9 digits - Use this type to denote the "clean", dash-less EIN`);

export type EINDash = Branded<string, 'EINDash'>;
export const einDashSchema = z
  .custom<EINDash>(v => {
    if (typeof v !== 'string') return false;
    return /^\d{2}-\d{7}$/.test(v);
  })
  .describe(
    `A string composed of 9 digits plus a single digit, separating the first 2 digits from the last 7 - Use this type to denote the formatted EIN`,
  );

export const timestampSchema = z.union([z.number(), z.string().transform(v => new Date(v).valueOf())]);

export type PromiseReturn<T> = T extends PromiseLike<infer U> ? U : T;

export type RequireProperty<Type, Key extends keyof Type> = Type & {
  [Property in Key]: NonNullable<Type[Property]>;
};
export type NullableProperties<T> = {
  [K in keyof T]?: T[K] | null | undefined;
};

export type AdminAccountName = 'accountant' | 'keeper' | 'maintainer' | 'reviewer';

export type Exchange = 'binanceus' | 'coinbase' | 'gemini' | 'otc';

export type USStateCode = z.infer<typeof US_STATES>;
export type USStateFull = (typeof US_STATES_TO_FULL)[USStateCode];

export type CountryCode = z.infer<typeof COUNTRY_ISO3166_ALPHA3>;
export type CountryEntry = (typeof COUNTRY_GEODATA)[CountryCode];
export type CountryName = (typeof COUNTRY_GEODATA)[CountryCode]['name'];
export type CountryLocation = (typeof COUNTRY_GEODATA)[CountryCode]['location'];

export type ContinentEntry = (typeof CONTINENT)[keyof typeof CONTINENT];
export type ContinentName = (typeof CONTINENT)[keyof typeof CONTINENT]['name'];
export type ContinentLocation = (typeof CONTINENT)[keyof typeof CONTINENT]['location'];

export const arraySchemaInvalidsFiltered = <T extends ZodTypeAny>(schema: T, params?: RawCreateParams) => {
  const catchValue = {} as never;
  return z.array(schema.catch(catchValue), params).transform(arr => arr.filter(v => v !== catchValue));
};
export const isOfSchema =
  <T extends ZodTypeAny>(schema: T) =>
  (v: unknown): v is z.infer<T> =>
    schema.safeParse(v).success;

export type MetaData = {
  title: string;
  description: string;
  url: string;
  header?: string;
  keywords?: string;
  image?: string;
};
