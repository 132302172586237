import type { ImageProps } from '@chakra-ui/react';
import { Image } from '@chakra-ui/react';
import { useState } from 'react';

import { CircleIcon } from '@endaoment-frontend/ui/icons';
import { stringToColor } from '@endaoment-frontend/utils';

type OrgLogoProps = Omit<ImageProps, 'alt' | 'src'> & {
  src?: ImageProps['src'] | null;
  orgName: string;
  hideFallback?: boolean;
};

export const OrgLogo = ({ src, orgName, hideFallback, onError, ...props }: OrgLogoProps) => {
  const [logoFailed, setLogoFailed] = useState(false);

  if (!src || logoFailed) {
    if (hideFallback) return null;
    return (
      <CircleIcon
        startColor={stringToColor(orgName)}
        endColor={stringToColor(orgName.split('').reverse().join(''))}
        data-testid={`${orgName} logo`}
      />
    );
  }

  const handleError: OrgLogoProps['onError'] = e => {
    setLogoFailed(true);
    onError?.(e);
  };

  return (
    <Image
      src={src}
      alt={`${orgName} logo`}
      width='200px'
      height='200px'
      htmlWidth={200}
      htmlHeight={200}
      loading='lazy'
      {...props}
      onError={handleError}
    />
  );
};
