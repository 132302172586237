import type { IconProps } from './Icon.types';

export const FacebookIcon = ({ width = 36, height, color = 'currentColor', ...props }: IconProps) => (
  <svg
    width={width}
    height={height ?? width}
    viewBox='0 0 36 36'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}>
    <path
      d='M1.25 18C1.25 8.74923 8.74923 1.25 18 1.25C27.2508 1.25 34.75 8.74923 34.75 18C34.75 26.5663 28.3183 33.6323 20.0211 34.6294C19.359 34.709 18.6846 34.75 18 34.75C17.0611 34.75 16.1411 34.6729 15.2459 34.5249C7.30567 33.2121 1.25 26.312 1.25 18Z'
      stroke={color}
      strokeWidth='2.5'
    />
    <path
      d='M11 22.6008H15.1521V34.7715C16.0493 34.9218 16.9705 35 17.9098 35C18.5945 35 19.2697 34.9585 19.933 34.8777V22.6H24.276L24.9577 18.1085H19.9322V15.6536C19.9322 13.7878 20.5315 12.1327 22.2455 12.1327H25V8.21237L24.9752 8.20895C24.4812 8.1408 23.4605 8 21.5583 8C17.5197 8 15.1521 10.1725 15.1521 15.1206V18.1093H11V22.6008Z'
      fill={color}
    />
  </svg>
);
