import type { IconProps } from '../Icon.types';

export const ComplexIcon = ({ width = 28, height, color, ...props }: IconProps) => (
  <svg
    width={width}
    height={height ?? width}
    viewBox='0 0 28 28'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M20.7283 6.29289C21.1189 6.68342 21.1189 7.31658 20.7283 7.70711L19.8004 8.63508L21.5372 10.3719C21.9277 10.7624 21.9277 11.3956 21.5371 11.7861C21.1466 12.1766 20.5134 12.1766 20.1229 11.7861L18.3861 10.0493L16.8823 11.5532L18.0913 12.7623C18.4819 13.1528 18.4819 13.786 18.0913 14.1765C17.7008 14.567 17.0677 14.567 16.6771 14.1765L15.468 12.9674L14.1663 14.2691C15.2304 15.9737 15.0215 18.2463 13.5398 19.7281C11.815 21.4529 9.01846 21.4529 7.29363 19.7281C5.56879 18.0032 5.56879 15.2067 7.29363 13.4819C8.77518 12.0003 11.0474 11.7914 12.752 12.855L19.3141 6.29289C19.7047 5.90237 20.3378 5.90237 20.7283 6.29289ZM12.1256 14.8961C11.1818 13.9523 9.65163 13.9523 8.70784 14.8961C7.76405 15.8399 7.76405 17.3701 8.70784 18.3138C9.65163 19.2576 11.1818 19.2576 12.1256 18.3138C13.0694 17.3701 13.0694 15.8399 12.1256 14.8961Z'
      fill={color ?? '#AFA6C9'}
    />
  </svg>
);
