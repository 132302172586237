import type { IconProps } from './Icon.types';

export const TargetAllocationIcon = ({ width = 36, height, color, ...props }: IconProps) => (
  <svg
    width={width}
    height={height ?? width}
    viewBox='0 0 36 36'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}>
    <rect x='3' y='12' width='30' height='5' rx='2.5' fill={color ?? '#EA6B0E'} />
    <rect x='3' y='5' width='14' height='5' rx='2.5' fill={color ?? '#EA6B0E'} />
    <rect x='3' y='19' width='21' height='5' rx='2.5' fill={color ?? '#EA6B0E'} />
    <rect x='3' y='26' width='9' height='5' rx='2.5' fill={color ?? '#EA6B0E'} />
  </svg>
);
