import type { IconProps } from './Icon.types';

export const QuestionIcon = ({ width = 10, height = 11, color, ...props }: IconProps) => (
  <svg width={width} height={height} viewBox='0 0 10 11' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <circle cx='5' cy='5.5' r='5' fill={color ?? '#102F54'} fillOpacity='0.25' />
    <path
      d='M5.56548 6.47619C5.53571 6.57044 5.51959 6.71677 5.51711 6.91518H4.51265C4.52753 6.49603 4.56721 6.20709 4.6317 6.04836C4.69618 5.88715 4.86235 5.70238 5.13021 5.49405L5.40179 5.28199C5.49107 5.21503 5.563 5.14187 5.61756 5.0625C5.71677 4.92609 5.76637 4.77604 5.76637 4.61235C5.76637 4.42386 5.71057 4.25273 5.59896 4.09896C5.48983 3.94271 5.28894 3.86458 4.99628 3.86458C4.70858 3.86458 4.50397 3.96007 4.38244 4.15104C4.26339 4.34201 4.20387 4.54043 4.20387 4.74628H3.12872C3.15848 4.03943 3.40526 3.53844 3.86905 3.2433C4.16171 3.05481 4.52133 2.96057 4.94792 2.96057C5.50843 2.96057 5.97346 3.09449 6.34301 3.36235C6.71503 3.63021 6.90104 4.02703 6.90104 4.55283C6.90104 4.87525 6.82044 5.14683 6.65923 5.36756C6.56498 5.50149 6.38393 5.67262 6.11607 5.88095L5.85193 6.08557C5.70809 6.19717 5.6126 6.32738 5.56548 6.47619ZM5.59524 8.5H4.48661V7.42485H5.59524V8.5Z'
      fill={color ?? '#F0F2F4'}
    />
  </svg>
);
