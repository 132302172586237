import { useId } from 'react';

import type { IconProps } from './Icon.types';

export const ProfileIcon = ({ width = 20, height, color = '#EA6B0E', ...props }: IconProps) => {
  const id = useId();

  return (
    <svg
      width={width}
      height={height ?? width}
      viewBox='0 0 20 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <circle cx='10' cy='6' r='5.5' fill={color} fillOpacity='0.25' stroke={color} />
      <mask id={id} fill='white'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M4.66965 10.5375C1.86376 12.3086 0 15.4367 0 19V22.0909C0 23.6976 1.30245 25 2.90909 25H17.0909C18.6976 25 20 23.6976 20 22.0909V19C20 15.4367 18.1362 12.3086 15.3304 10.5375C14.0464 12.0444 12.1348 13 10 13C7.8652 13 5.9536 12.0444 4.66965 10.5375Z'
        />
      </mask>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.66965 10.5375C1.86376 12.3086 0 15.4367 0 19V22.0909C0 23.6976 1.30245 25 2.90909 25H17.0909C18.6976 25 20 23.6976 20 22.0909V19C20 15.4367 18.1362 12.3086 15.3304 10.5375C14.0464 12.0444 12.1348 13 10 13C7.8652 13 5.9536 12.0444 4.66965 10.5375Z'
        fill={color}
        fillOpacity='0.25'
      />
      <path
        d='M4.66965 10.5375L5.4308 9.88897L4.86873 9.22933L4.13588 9.69191L4.66965 10.5375ZM15.3304 10.5375L15.8641 9.69191L15.1313 9.22933L14.5692 9.88897L15.3304 10.5375ZM1 19C1 15.7941 2.67553 12.9788 5.20342 11.3832L4.13588 9.69191C1.052 11.6385 -1 15.0792 -1 19H1ZM1 22.0909V19H-1V22.0909H1ZM2.90909 24C1.85473 24 1 23.1453 1 22.0909H-1C-1 24.2498 0.750161 26 2.90909 26V24ZM17.0909 24H2.90909V26H17.0909V24ZM19 22.0909C19 23.1453 18.1453 24 17.0909 24V26C19.2498 26 21 24.2498 21 22.0909H19ZM19 19V22.0909H21V19H19ZM14.7966 11.3832C17.3245 12.9788 19 15.7941 19 19H21C21 15.0792 18.948 11.6385 15.8641 9.69191L14.7966 11.3832ZM14.5692 9.88897C13.4671 11.1823 11.8296 12 10 12V14C12.44 14 14.6257 12.9064 16.0915 11.1861L14.5692 9.88897ZM10 12C8.17043 12 6.53287 11.1823 5.4308 9.88897L3.90849 11.1861C5.37433 12.9064 7.55997 14 10 14V12Z'
        fill={color}
        mask={`url(#${id})`}
      />
    </svg>
  );
};
