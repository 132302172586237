import type { IconProps } from './Icon.types';

export const RightCaretIcon = ({ width, height, className, color }: IconProps) => (
  <svg
    width={width}
    height={height ?? width}
    viewBox='0 0 36 36'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}>
    <path
      d='M13 28L23 18L13 8'
      stroke={color ?? 'black'}
      strokeWidth='4'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
