import type { IconProps } from './Icon.types';

export const PriceTagIcon = ({
  width = 50,
  height,
  color = 'currentColor',
  strokeColor = 'currentColor',
  strokeWidth = 1.5,
  ...props
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height ?? width}
      viewBox='0 0 50 50'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M31.2621 12.8676C31.9582 12.9671 32.6033 13.2896 33.1006 13.7869L46.2999 26.9862C47.5691 28.2554 47.5691 30.3132 46.2999 31.5824L34.9862 42.8961C33.717 44.1653 31.6592 44.1653 30.39 42.8961L17.1907 29.6968C16.6934 29.1995 16.3709 28.5544 16.2714 27.8583L14.1972 13.339C13.9851 11.8541 15.2579 10.5813 16.7428 10.7934L31.2621 12.8676ZM25.4402 22.0364C26.5142 20.9625 26.5142 19.2213 25.4402 18.1474C24.3663 17.0734 22.6251 17.0734 21.5511 18.1474C20.4772 19.2213 20.4772 20.9625 21.5511 22.0364C22.6251 23.1104 24.3663 23.1104 25.4402 22.0364Z'
        fill={color}
        fillOpacity='0.25'
        stroke={color ?? strokeColor}
        strokeWidth={strokeWidth}
      />
      <path
        d='M23.9037 20C19.4038 12.5 12.9037 7.5 6.40379 7C-0.0961084 6.5 -1.09638 15 5.40367 16C11.9037 17 11.9037 6 5.4037 1.5'
        stroke={color ?? strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
      />
    </svg>
  );
};
