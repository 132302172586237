import clsx from 'clsx';

import styles from './StatusBadge.module.scss';

type StatusBadgeProps = {
  status?: 'error' | 'off' | 'on';
  className?: string;
};

export const StatusBadge = ({ status, className }: StatusBadgeProps) => {
  return (
    <span
      className={clsx(
        styles['status-badge'],
        className,
        status === 'off' && styles['status-badge--off'],
        status === 'error' && styles['status-badge--error'],
      )}
    />
  );
};
