import { FetchError } from 'ofetch';
import { z } from 'zod';

export const isFetchError = <T>(error: unknown): error is FetchError<T> => error instanceof FetchError;

export const getFetchErrorMessage = (error: unknown): string | undefined => {
  if (isFetchError(error)) {
    const messageParse = z.object({ message: z.string() }).safeParse(error.data);
    if (messageParse.success) {
      return messageParse.data.message;
    }

    return error.statusMessage ?? error.message;
  }

  return undefined;
};
