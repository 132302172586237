import type { Ref } from 'react';
import { forwardRef } from 'react';

import type { IconProps } from './Icon.types';

export const DownCaretIcon = forwardRef(
  ({ width = 9, height = 6, strokeColor = 'currentColor', color, ...props }: IconProps, ref: Ref<SVGSVGElement>) => (
    <svg
      width={width}
      height={height}
      viewBox='0 0 9 6'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      ref={ref}
      {...props}>
      <path d='M0.5 1L4.5 5L8.5 1' stroke={color ?? strokeColor} strokeLinecap='round' />
    </svg>
  ),
);
