import { motion } from 'framer-motion';

import type { IconProps } from '@endaoment-frontend/ui/icons';

type SaveAnimationProps = IconProps & {
  duration?: number;
  delay?: number;
};

export const SaveAnimation = ({
  duration = 0.5,
  delay = 0.5,
  width = 15,
  height,
  strokeColor = 'currentColor',
  strokeWidth = 4,
  ...props
}: SaveAnimationProps) => {
  return (
    <svg
      width={width}
      height={height ?? width}
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <motion.path
        d='M30 10L14 26L6 18'
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
        initial={{ pathLength: 0 }}
        animate={{ pathLength: 1 }}
        exit={{ pathLength: 0, transition: { delay } }}
        transition={{ duration }}
      />
    </svg>
  );
};
