import type { IconProps } from '../Icon.types';

export const CardIcon = ({ width = 28, height, color, ...props }: IconProps) => (
  <svg
    width={width}
    height={height ?? width}
    viewBox='0 0 28 28'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}>
    <rect
      x='1.5'
      y='6.5'
      width='25'
      height='16'
      rx='2.5'
      fill={color ?? '#EA4705'}
      fillOpacity='0.125'
      stroke={color ?? '#EA4705'}
    />
    <rect x='1' y='10' width='26' height='5' fill={color ?? '#EA4705'} />
  </svg>
);
