import type { IconProps } from './Icon.types';

export const EthereumIcon = ({
  width = 31,
  height = 49,
  color = 'currentColor',
  strokeColor = 'currentColor',
  strokeWidth = 1.5,
  ...props
}: IconProps) => (
  <svg width={width} height={height} viewBox='0 0 31 49' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path d='M15.4311 29.0638L29.5347 22.3592L15.4314 1L15.4311 29.0638Z' fill={color} />
    <path d='M1 25.7012L15.4314 47.5575L15.4311 32.5615L1 25.7012Z' fill={color} />
    <path
      d='M15.4314 1L1.32807 22.3595L15.4311 29.0638M15.4314 1L29.5347 22.3592L15.4311 29.0638M15.4314 1L15.4311 29.0638M15.4311 32.5615L1 25.7012L15.4314 47.5575M15.4311 32.5615L29.8631 25.7008L15.4314 47.5575M15.4311 32.5615L15.4314 47.5575'
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      strokeLinejoin='round'
    />
    <path
      d='M15.4311 29.0638L1.32807 22.3595L15.4314 1L29.5347 22.3592L15.4311 29.0638Z'
      fill={color}
      fillOpacity='0.25'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1 25.7012L15.4314 47.5575L15.4311 32.5615L1 25.7012Z'
      fill={color}
      fillOpacity='0.25'
    />
    <path
      d='M15.4314 1L1.32807 22.3595L15.4311 29.0638M15.4314 1L29.5347 22.3592L15.4311 29.0638M15.4314 1L15.4311 29.0638M15.4311 32.5615L1 25.7012L15.4314 47.5575M15.4311 32.5615L29.8631 25.7008L15.4314 47.5575M15.4311 32.5615L15.4314 47.5575'
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      strokeLinejoin='round'
    />
  </svg>
);
