import type { IconProps } from './Icon.types';

export const PopupIcon = ({ width = 36, height, color, ...props }: IconProps) => (
  <svg
    width={width}
    height={height ?? width}
    viewBox='0 0 36 36'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}>
    <path
      d='M11 24L24 11M24 11H11M24 11V24'
      stroke={color ?? '#ADBACC'}
      strokeWidth='3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
