import type { IconProps } from './Icon.types';

export const PhoneIcon = ({
  width = 25,
  height = 28,
  strokeColor = 'currentColor',
  strokeWidth = 2,
  ...props
}: IconProps) => (
  <svg width={width} height={height} viewBox='0 0 25 28' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M7.03714 18.2449C-0.317301 8.97554 1.23299 5.0346 3.26704 3.42076C4.21011 2.67252 4.93455 2.09773 5.44038 1.69641C5.92536 1.31161 6.63005 1.39316 7.01485 1.87814L10.8664 6.73253C11.2512 7.21751 11.1699 7.92261 10.685 8.3074L9.52905 9.22451C9.52905 9.22451 9.52905 9.22451 9.52905 9.22451C9.52905 9.22451 8.00351 10.4349 11.4329 14.7573C14.8623 19.0796 16.3879 17.8692 16.3879 17.8692C16.3879 17.8692 16.3879 17.8692 16.3879 17.8692L17.5438 16.9521C18.0288 16.5673 18.7339 16.6485 19.1187 17.1335L22.9702 21.9879C23.355 22.4729 23.2742 23.1777 22.7892 23.5625C22.2834 23.9638 21.5589 24.5386 20.6158 25.2868C18.5818 26.9007 14.3916 27.5143 7.03714 18.2449Z'
      stroke={strokeColor}
      strokeWidth={strokeWidth}
    />
  </svg>
);
