import type { IconProps } from './Icon.types';

export const MapIcon = ({ width, height, className, color }: IconProps) => (
  <svg
    width={width ?? '36'}
    height={height ?? width ?? '36'}
    className={className}
    viewBox='0 0 36 36'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M17 5.5H24H28C29.3807 5.5 30.5 6.61929 30.5 8V11V17V23V26C30.5 27.3807 29.3807 28.5 28 28.5H24H10H6C4.61929 28.5 3.5 27.3807 3.5 26V23V17V11V8C3.5 6.61929 4.61929 5.5 6 5.5H10H17Z'
      stroke={color ?? 'white'}
      fillOpacity='0.5'
      fill={color ?? 'white'}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M31 12.6192V8C31 6.34315 29.6569 5 28 5L17.3872 5C18.3724 6.21559 19.5 7.93787 19.5 8.99976C19.5 10.0718 18.782 10.4257 18.1155 10.7542C17.5384 11.0386 17 11.304 17 12C17 13.5736 17.8667 14.2001 18.6143 14.7405C19.3691 15.286 20.0024 15.7438 19.5 16.9998C18.5 19.4998 20.5 20.5 22 19.5C22.5704 19.1198 22.7793 18.4504 22.9841 17.7942C23.3179 16.7246 23.6407 15.6901 25.5 16C27.9757 16.4126 28.0679 16.1441 28.5865 14.6326C28.6963 14.3125 28.8253 13.9367 29 13.4998C29.207 12.9823 29.9502 12.8323 30.6988 12.6811C30.7995 12.6608 30.9004 12.6405 31 12.6192ZM3.01117 26.2607C3.00377 26.1748 3 26.0878 3 26V12.1038C4.17621 12.1487 5.35389 12.5498 5.50002 13.4998C5.84501 15.7422 7.2901 14.5569 8.89311 13.2421C10.1496 12.2115 11.5032 11.1013 12.5 11.5C13.8376 12.035 13.4344 13.1192 13.0125 14.254C12.6458 15.2402 12.2649 16.2645 13 16.9998C14.5 18.5 17 21.5 14.5 22C12.6968 22.3606 11.6005 21.5443 10.7964 20.9455C9.8516 20.2419 9.31042 19.8389 8.50002 22C8.1195 23.0147 4.96479 25.5635 3.01117 26.2607ZM31 25.1862V26C31 27.6569 29.6569 29 28 29H11.6413C12.3313 28.3319 12.8578 27.3829 13.3473 26.5005C14.2558 24.8627 15.0369 23.4546 16.5 24.4998C18.8055 26.1467 19.467 24.9274 20.1613 23.6476C20.521 22.9845 20.8895 22.3053 21.5 22C22.3049 21.5976 22.84 22.3071 23.4962 23.1772C24.4706 24.4692 25.712 26.1152 28.5 25C29.2166 24.7134 30.1353 24.8385 31 25.1862Z'
      fill={color ?? 'white'}
    />
  </svg>
);
