import type { IconProps } from './Icon.types';

export const PauseIcon = ({ width, height, className, color }: IconProps) => (
  <svg
    width={width ?? '36'}
    height={height ?? width ?? '36'}
    className={className}
    viewBox='0 0 36 36'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <rect x='10' y='9' width='6' height='18' rx='1' fill={color ?? 'white'} />
    <rect x='19' y='9' width='6' height='18' rx='1' fill={color ?? 'white'} />
  </svg>
);
