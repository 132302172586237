import clsx from 'clsx';
import { P, match } from 'ts-pattern';

import { calculateFundImpact } from '@endaoment-frontend/funds';
import { parseHtml } from '@endaoment-frontend/react-utils';
import type { FundListing } from '@endaoment-frontend/types';
import { StarIcon } from '@endaoment-frontend/ui/icons';
import { formatCurrency, formatUsdc, stringToColor } from '@endaoment-frontend/utils';

import { Avatar } from './Avatar';
import { Card } from './Card';
import styles from './FundCard.module.scss';
import { Loader } from './Loader';

type FundCardProps = {
  fund: Pick<
    FundListing,
    | 'advisor'
    | 'description'
    | 'id'
    | 'inTransitBuyUsdcAmount'
    | 'inTransitSellUsdcAmount'
    | 'investedUsdc'
    | 'logo'
    | 'name'
    | 'totalGrantedUsdc'
    | 'usdcBalance'
    | 'v2ContractAddress'
  >;
  variation?: 'compact' | 'default';
  className?: string;
};

export const FundCard = ({ fund, variation = 'default', className }: FundCardProps) => {
  const fundImpact = calculateFundImpact(fund);
  const isDeployed = typeof fund.v2ContractAddress === 'string';

  return (
    <Card className={clsx(styles['fund-card'], variation === 'compact' && styles['fund-card--compact'], className)}>
      <header>
        {isDeployed ? (
          <Avatar className={styles['fund-card__avatar']}>
            {fund.logo ? (
              <img src={fund.logo} alt={`${fund.name} logo`} />
            ) : (
              <StarIcon
                startColor={stringToColor(fund.name)}
                endColor={stringToColor(fund.name.split('').reverse().join(''))}
              />
            )}
          </Avatar>
        ) : (
          <Loader size='l' />
        )}
        <h3>{fund.name}</h3>
        <span>
          Managed by {fund.advisor.firstName} {fund.advisor.lastName}
        </span>
      </header>
      <article>{variation === 'default' && parseHtml(fund.description)}</article>
      <footer>
        {variation === 'default' && <span>Fund Impact</span>}
        {match(fund)
          .with({ id: P.nullish }, () => <strong>Creating...</strong>)
          .with({ id: P.not(P.nullish), v2ContractAddress: P.nullish }, () => <strong>Deploying...</strong>)
          .with({ id: P.not(P.nullish), v2ContractAddress: P.string }, () => (
            <strong>{formatCurrency(formatUsdc(fundImpact), { compact: false, lowercase: true })}</strong>
          ))
          .exhaustive()}
      </footer>
    </Card>
  );
};
