import type { IconProps } from '../Icon.types';

export const EthereumChainIcon = ({ width = 28, height = 28, color, ...props }: IconProps) => (
  <svg width={width} height={height} viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path d='M13.9998 15.9895L19.8634 13.202L13.9999 4.32178L13.9998 15.9895Z' fill={color ?? 'white'} />
    <path d='M8 14.5915L13.9999 23.6784L13.9998 17.4437L8 14.5915Z' fill={color ?? 'white'} />
    <path
      d='M13.9999 4.32178L8.1364 13.2021L13.9998 15.9895M13.9999 4.32178L19.8634 13.202L13.9998 15.9895M13.9999 4.32178L13.9998 15.9895M13.9998 17.4437L8 14.5915L13.9999 23.6784M13.9998 17.4437L20 14.5913L13.9999 23.6784M13.9998 17.4437L13.9999 23.6784'
      stroke={color ?? 'white'}
      strokeWidth='0.562501'
      strokeLinejoin='round'
    />
    <path
      d='M13.9998 15.9895L8.1364 13.2021L13.9999 4.32178L19.8634 13.202L13.9998 15.9895Z'
      fill={color ?? 'white'}
      fillOpacity='0.25'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8 14.5915L13.9999 23.6784L13.9998 17.4437L8 14.5915Z'
      fill={color ?? 'white'}
      fillOpacity='0.25'
    />
    <path
      d='M13.9999 4.32178L8.1364 13.2021L13.9998 15.9895M13.9999 4.32178L19.8634 13.202L13.9998 15.9895M13.9999 4.32178L13.9998 15.9895M13.9998 17.4437L8 14.5915L13.9999 23.6784M13.9998 17.4437L20 14.5913L13.9999 23.6784M13.9998 17.4437L13.9999 23.6784'
      stroke={color ?? 'white'}
      strokeWidth='0.562501'
      strokeLinejoin='round'
    />
  </svg>
);
