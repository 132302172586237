import { useId } from 'react';

import type { IconGradientFillProps } from './Icon.types';

export const CircleIcon = ({
  startColor = 'currentColor',
  endColor = 'currentColor',
  ...props
}: IconGradientFillProps) => {
  const gradientId = useId();
  const clipPathId = useId();

  return (
    <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <linearGradient id={gradientId} x1='0%' y1='0%' x2='100%' y2='100%'>
        <stop offset='0%' style={{ stopColor: startColor, stopOpacity: 1 }} />
        <stop offset='100%' style={{ stopColor: endColor, stopOpacity: 1 }} />
      </linearGradient>
      <g clipPath={`url(#${clipPathId})`}>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM16 27C22.0751 27 27 22.0751 27 16C27 9.92487 22.0751 5 16 5C9.92487 5 5 9.92487 5 16C5 22.0751 9.92487 27 16 27Z'
          fill={`url(#${gradientId})`}
        />
      </g>
      <defs>
        <clipPath id={clipPathId}>
          <rect width='32' height='32' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
