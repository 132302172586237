import type { IconProps } from './Icon.types';

export const NVTTokenIcon = ({ width = 86, height, ...props }: IconProps) => {
  return (
    <svg
      width={width}
      height={height ?? width}
      viewBox='0 0 86 86'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <circle cx='43' cy='43' r='41.5' fill='#EA6B0E' stroke='#AA3401' strokeWidth='3' />
      <path
        d='M36.6564 39.0465L33.0858 33.0954L20.2747 25.7007L16.7503 31.8112L35.7662 42.7855L16.7217 53.7328L20.2363 59.8337L33.0853 52.4198L36.6472 46.4595C37.3278 45.3432 37.6892 44.0616 37.6909 42.7538C37.6925 41.4469 37.3348 40.1639 36.6564 39.0465Z'
        fill='white'
      />
      <path
        d='M53.7329 16.7218L42.7577 35.7382L31.7835 16.7222L25.7288 20.2467L33.1236 33.0578L39.0277 36.6752C40.153 37.3553 41.4445 37.7108 42.7593 37.7024C44.074 37.6939 45.3613 37.3224 46.4782 36.6286L52.439 33.0662L59.8339 20.2362L53.7329 16.7218Z'
        fill='white'
      />
      <path
        d='M48.8605 46.5085L52.516 52.4506L65.3265 59.8449L68.8605 53.7438L49.8446 42.7695L68.861 31.7943L65.2995 25.7023L52.4506 33.1163L48.9077 39.0585C48.2197 40.177 47.8514 41.4633 47.8429 42.777C47.8344 44.0907 48.1863 45.3816 48.8605 46.5085Z'
        fill='white'
      />
      <path
        d='M46.4892 48.8793C45.3755 48.2077 44.1003 47.8516 42.7998 47.848C41.4988 47.8448 40.222 48.1952 39.1046 48.861L33.1439 52.4224L25.7299 65.2713L31.8123 68.8234L42.7876 49.8069L53.7619 68.8228L59.8635 65.2894L52.4782 52.4878L46.4892 48.8793Z'
        fill='white'
      />
    </svg>
  );
};
