import { useId } from 'react';

import type { IconProps } from '../Icon.types';

export const DonateDirectIcon = ({ width = 28, height, color, ...props }: IconProps) => {
  const pathId = useId();
  return (
    <svg
      width={width}
      height={height ?? width}
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <mask id={pathId} fill='white'>
        <path d='M28 14C28 21.732 21.732 28 14 28C6.26801 28 0 21.732 0 14C0 6.26801 6.26801 0 14 0C21.732 0 28 6.26801 28 14ZM7 14C7 17.866 10.134 21 14 21C17.866 21 21 17.866 21 14C21 10.134 17.866 7 14 7C10.134 7 7 10.134 7 14Z' />
      </mask>
      <path
        d='M28 14C28 21.732 21.732 28 14 28C6.26801 28 0 21.732 0 14C0 6.26801 6.26801 0 14 0C21.732 0 28 6.26801 28 14ZM7 14C7 17.866 10.134 21 14 21C17.866 21 21 17.866 21 14C21 10.134 17.866 7 14 7C10.134 7 7 10.134 7 14Z'
        fill={color ?? '#53ACDE'}
        fillOpacity='0.25'
        stroke={color ?? '#53ACDE'}
        strokeWidth='3'
        mask={`url(#${pathId})`}
      />
    </svg>
  );
};
