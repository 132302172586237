import clsx from 'clsx';
import type { ReactNode } from 'react';

import styles from './ProgressBar.module.scss';

type ProgressBarProps = {
  progress: number;
  children?: Array<ReactNode> | ReactNode;
  className?: string;
  labelsClassName?: string;
};

export const ProgressBar = ({ progress, children, className, labelsClassName }: ProgressBarProps) => {
  return (
    <div className={clsx(styles['progress-bar'], className)}>
      <div className={styles['progress']}>
        <div
          className={styles['progress-inner']}
          style={{ width: `${Math.max(0, Math.min(100, progress))}%` }}
          data-testid='progress-bar'
        />
      </div>
      {!!children && <div className={clsx(styles['labels'], labelsClassName)}>{children}</div>}
    </div>
  );
};
