import type { IconProps } from './Icon.types';

export const PlusIcon = ({ width = 22, height, strokeColor = '#627EEA', strokeWidth = 2 }: IconProps) => {
  return (
    <svg width={width} height={height ?? width} viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <line x1='11.04' y1='1' x2='11.04' y2='21' stroke={strokeColor} strokeWidth={strokeWidth} strokeLinecap='round' />
      <line x1='21' y1='12' x2='1' y2='12' stroke={strokeColor} strokeWidth={strokeWidth} strokeLinecap='round' />
    </svg>
  );
};
