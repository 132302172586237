import { useId } from 'react';

import type { IconProps } from '../Icon.types';

export const InTransitIcon = ({ width = 36, height, color, ...props }: IconProps) => {
  const clipId = useId();
  return (
    <svg
      width={width}
      height={width ?? height}
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <g clipPath={`url(#${clipId})`}>
        <rect opacity='0.25' x='3' y='7' width='17' height='16' fill={color ?? '#627EEA'} />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M1.5 7.75C1.5 5.95507 2.95507 4.5 4.75 4.5H18.75C20.5449 4.5 22 5.95507 22 7.75V8.4762H27.7202C29.3773 8.4762 30.8833 9.43941 31.5783 10.9438L32.4896 12.9162L34.8848 18.1008C34.9607 18.2651 35 18.444 35 18.625V20.75C35 23.0972 33.0972 25 30.75 25L30 25C30 23.9565 29.4672 23.0375 28.6589 22.5H30.75C31.7165 22.5 32.5 21.7165 32.5 20.75V19.875H26.3056C25.0629 19.875 24.0556 18.8676 24.0556 17.625V14.4405C24.0556 13.1978 25.0629 12.1905 26.3056 12.1905H29.4004L29.3088 11.9923C29.0227 11.3728 28.4025 10.9762 27.7202 10.9762H22V22.5H25.3411C24.5328 23.0375 24 23.9565 24 25L20.75 25L13 25C13 23.9565 12.4672 23.0375 11.6589 22.5L19.5 22.5V10.1786V9.7262V7.75C19.5 7.33579 19.1642 7 18.75 7H4.75C4.33579 7 4 7.33579 4 7.75V21.75C4 22.1642 4.33579 22.5 4.75 22.5L8.3411 22.5C7.53275 23.0375 7 23.9565 7 25H4.75C2.95507 25 1.5 23.5449 1.5 21.75V7.75ZM30.5554 14.6905H26.5556V17.375H31.7956L30.5554 14.6905Z'
          fill={color ?? '#627EEA'}
        />
        <circle
          cx='10'
          cy='25'
          r='3'
          fill={color ?? '#627EEA'}
          fillOpacity='0.5'
          stroke={color ?? '#627EEA'}
          strokeWidth='2.5'
        />
        <circle
          cx='27'
          cy='25'
          r='3'
          fill={color ?? '#627EEA'}
          fillOpacity='0.5'
          stroke={color ?? '#627EEA'}
          strokeWidth='2.5'
        />
        <line
          x1='1.25'
          y1='29.75'
          x2='34.75'
          y2='29.75'
          stroke={color ?? '#627EEA'}
          strokeWidth='2.5'
          strokeLinecap='round'
          strokeDasharray='7 4'
        />
      </g>
      <defs>
        <clipPath id={clipId}>
          <rect width='35.1768' height='36' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
