export const UipIcon = ({ strokeColor = 'currentColor' }: { strokeColor: string }) => (
  <svg width='56' height='55' viewBox='0 0 54 52' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M27.5054 38.954C27.6675 38.9413 27.8325 38.9348 28 38.9348C28.1675 38.9348 28.3325 38.9413 28.4946 38.954C28.3164 38.775 28.1668 38.6686 28.103 38.6282C28.0643 38.6037 28.0299 38.5838 28 38.5679C27.9701 38.5838 27.9357 38.6037 27.897 38.6282C27.8332 38.6686 27.6836 38.775 27.5054 38.954ZM28.1437 38.5067C28.1438 38.5069 28.1395 38.5082 28.1307 38.51C28.1393 38.5073 28.1437 38.5065 28.1437 38.5067ZM27.8693 38.51C27.8605 38.5082 27.8562 38.5069 27.8563 38.5067C27.8563 38.5065 27.8607 38.5073 27.8693 38.51ZM28.4946 43.9808C28.3325 43.9935 28.1675 44 28 44C27.8325 44 27.6675 43.9935 27.5054 43.9808C27.6836 44.1598 27.8332 44.2662 27.897 44.3066C27.9357 44.3311 27.9701 44.351 28 44.3669C28.0299 44.351 28.0643 44.3311 28.103 44.3066C28.1668 44.2662 28.3164 44.1598 28.4946 43.9808ZM27.8563 44.4281C27.8562 44.4279 27.8605 44.4266 27.8693 44.4248C27.8607 44.4275 27.8563 44.4284 27.8563 44.4281ZM28.1307 44.4248C28.1395 44.4266 28.1438 44.4279 28.1437 44.4281C28.1437 44.4284 28.1393 44.4275 28.1307 44.4248ZM28 49.48C29.9806 49.48 32.0316 48.9825 33.7599 47.8882C35.4198 46.8372 37.48 44.7036 37.48 41.4674C37.48 38.2312 35.4198 36.0976 33.7599 35.0466C32.0316 33.9524 29.9806 33.4548 28 33.4548C26.0194 33.4548 23.9684 33.9524 22.2401 35.0466C20.5802 36.0976 18.52 38.2312 18.52 41.4674C18.52 44.7036 20.5802 46.8372 22.2401 47.8882C23.9684 48.9825 26.0194 49.48 28 49.48Z'
      stroke='white'
      strokeWidth='10.96'
    />
    <path
      d='M28 48.5652C29.8343 48.5652 31.71 48.1034 33.2705 47.1153C34.7742 46.1633 36.5652 44.2801 36.5652 41.4674C36.5652 38.6547 34.7742 36.7715 33.2705 35.8195C31.71 34.8314 29.8343 34.3696 28 34.3696C26.1657 34.3696 24.29 34.8314 22.7295 35.8195C21.2258 36.7715 19.4348 38.6547 19.4348 41.4674C19.4348 44.2801 21.2258 46.1633 22.7295 47.1153C24.29 48.1034 26.1657 48.5652 28 48.5652Z'
      stroke={strokeColor}
      strokeOpacity='0.5'
      strokeWidth='9.13043'
    />
    <path
      d='M28 47.6522C29.6884 47.6522 31.3889 47.226 32.7821 46.3439C34.1298 45.4906 35.6522 43.8575 35.6522 41.4674C35.6522 39.0774 34.1298 37.4442 32.7821 36.5909C31.3889 35.7088 29.6884 35.2826 28 35.2826C26.3116 35.2826 24.6111 35.7088 23.2179 36.5909C21.8702 37.4442 20.3478 39.0774 20.3478 41.4674C20.3478 43.8575 21.8702 45.4906 23.2179 46.3439C24.6111 47.226 26.3116 47.6522 28 47.6522Z'
      stroke='white'
      strokeWidth='7.30435'
    />
    <path
      d='M28 46.7391C29.5424 46.7391 31.0678 46.3487 32.2937 45.5725C33.4854 44.8179 34.7391 43.4348 34.7391 41.4674C34.7391 39.5 33.4854 38.1169 32.2937 37.3623C31.0678 36.5862 29.5424 36.1957 28 36.1957C26.4576 36.1957 24.9322 36.5862 23.7063 37.3623C22.5146 38.1169 21.2609 39.5 21.2609 41.4674C21.2609 43.4348 22.5146 44.8179 23.7063 45.5725C24.9322 46.3487 26.4576 46.7391 28 46.7391Z'
      stroke={strokeColor}
      strokeOpacity='0.75'
      strokeWidth='5.47826'
    />
    <path
      d='M28 45.8261C29.3965 45.8261 30.7467 45.4713 31.8053 44.8011C32.8411 44.1453 33.8261 43.0121 33.8261 41.4674C33.8261 39.9227 32.8411 38.7896 31.8053 38.1338C30.7467 37.4635 29.3965 37.1087 28 37.1087C26.6035 37.1087 25.2533 37.4635 24.1947 38.1338C23.1589 38.7896 22.1739 39.9227 22.1739 41.4674C22.1739 43.0121 23.1589 44.1453 24.1947 44.8011C25.2533 45.4713 26.6035 45.8261 28 45.8261Z'
      stroke='white'
      strokeWidth='3.65217'
    />
    <path
      d='M28 44.913C29.2505 44.913 30.4256 44.5939 31.3169 44.0296C32.1967 43.4726 32.913 42.5894 32.913 41.4674C32.913 40.3454 32.1967 39.4622 31.3169 38.9052C30.4256 38.3409 29.2505 38.0218 28 38.0218C26.7495 38.0218 25.5744 38.3409 24.6831 38.9052C23.8033 39.4622 23.087 40.3454 23.087 41.4674C23.087 42.5894 23.8033 43.4726 24.6831 44.0296C25.5744 44.5939 26.7495 44.913 28 44.913Z'
      fill='white'
      stroke={strokeColor}
      strokeWidth='1.82609'
    />
    <path
      d='M33.4201 13.4057L31.219 4.36322C30.3922 0.966911 25.568 0.950801 24.7186 4.34153L22.5691 12.9224C21.996 15.2099 20.2099 16.996 17.9224 17.5691L9.20629 19.7525C5.82721 20.599 5.8272 25.401 9.20629 26.2475L17.9224 28.4309C20.2099 29.004 21.996 30.7901 22.5691 33.0776L24.4573 40.6152C25.3807 44.3015 30.6193 44.3015 31.5427 40.6152L33.4309 33.0776C34.004 30.7901 35.7901 29.004 38.0776 28.4309L46.2107 26.3935C49.638 25.535 49.5706 20.6412 46.121 19.8774L38.2484 18.1342C35.8691 17.6074 33.9965 15.7734 33.4201 13.4057Z'
      fill='white'
      stroke='white'
      strokeWidth='1.82609'
    />
    <path
      d='M29.4447 4.79512L31.6459 13.8376C32.3869 16.8818 34.7946 19.2398 37.8536 19.9171L45.7262 21.6603C47.2942 22.0075 47.3249 24.2319 45.767 24.6222L37.6338 26.6596C34.6927 27.3963 32.3963 29.6928 31.6596 32.6339L29.7714 40.1715C29.3096 42.0146 26.6904 42.0146 26.2286 40.1714L24.3404 32.6338C23.6037 29.6927 21.3072 27.3963 18.3661 26.6596L9.65002 24.4761C8.11408 24.0914 8.11407 21.9086 9.65003 21.5239L18.3661 19.3404C21.3072 18.6037 23.6037 16.3072 24.3404 13.3661L26.49 4.78526C26.8761 3.24403 29.0689 3.25134 29.4447 4.79512Z'
      stroke={strokeColor}
      strokeWidth='1.82609'
    />
    <path
      opacity='0.3'
      d='M25.6043 4.5634C26.2221 2.09742 29.7305 2.10913 30.3318 4.57917L32.533 13.6217C33.1917 16.3276 35.3318 18.4236 38.051 19.0257L45.9236 20.7688C48.4324 21.3244 48.4814 24.8834 45.9888 25.5078L37.8557 27.5452C35.2414 28.2001 33.2001 30.2414 32.5452 32.8557L30.657 40.3933C29.9645 43.158 26.0355 43.158 25.343 40.3933L23.4548 32.8557C22.7999 30.2414 20.7586 28.2001 18.1443 27.5452L9.42815 25.3618C6.97064 24.7462 6.97064 21.2538 9.42816 20.6382L18.1443 18.4548C20.7586 17.7999 22.7999 15.7586 23.4548 13.1443L25.6043 4.5634Z'
      fill={strokeColor}
    />
  </svg>
);
