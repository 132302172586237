import type { IconProps } from './Icon.types';

export const LinkedInIcon = ({ width = 36, height, color = 'currentColor', ...props }: IconProps) => (
  <svg
    width={width}
    height={height ?? width}
    viewBox='0 0 36 36'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}>
    <path
      d='M6.15596 6.15596C9.2972 3.01473 13.5576 1.25 18 1.25C22.4424 1.25 26.7028 3.01473 29.844 6.15596C32.9853 9.2972 34.75 13.5576 34.75 18C34.75 22.4424 32.9853 26.7028 29.844 29.844C26.7028 32.9853 22.4424 34.75 18 34.75C13.5576 34.75 9.2972 32.9853 6.15596 29.844C3.01473 26.7028 1.25 22.4424 1.25 18C1.25 13.5576 3.01473 9.2972 6.15596 6.15596Z'
      stroke={color}
      strokeWidth='2.5'
    />
    <path d='M9 13.7273H12.3043V26H9V13.7273Z' fill={color} />
    <path
      d='M10.6522 8C10.214 8 9.79375 8.1724 9.48391 8.47928C9.17407 8.78616 9 9.20237 9 9.63636C9 10.0704 9.17407 10.4866 9.48391 10.7934C9.79375 11.1003 10.214 11.2727 10.6522 11.2727C11.0904 11.2727 11.5106 11.1003 11.8204 10.7934C12.1303 10.4866 12.3043 10.0704 12.3043 9.63636C12.3043 9.20237 12.1303 8.78616 11.8204 8.47928C11.5106 8.1724 11.0904 8 10.6522 8Z'
      fill={color}
    />
    <path
      d='M22.6304 13.7273C21.1864 13.7273 19.8779 14.2951 18.913 15.2131V13.7273H15.6087V26H18.913V19.8636C18.913 18.2886 20.2141 17 21.8043 17C23.3946 17 24.6957 18.2886 24.6957 19.8636V26H28V19.0455C28 16.1082 25.5961 13.7273 22.6304 13.7273Z'
      fill={color}
    />
  </svg>
);
