import { Flex } from '@chakra-ui/react';

import { DiscordIcon, FacebookIcon, LinkedInIcon, MailIcon, MirrorIcon, XIcon } from '@endaoment-frontend/ui/icons';

import styles from './SocialLinks.module.scss';

export const SocialLinks = () => {
  return (
    <section id='social-links' className={styles['social__section']}>
      <Flex className={styles['social']} alignItems='center' justifyContent='center' gap='0.75rem'>
        <a href='https://discord.gg/endaoment' target='_blank' rel='noreferrer'>
          <DiscordIcon />
        </a>
        <a href='https://twitter.com/endaomentdotorg' target='_blank' rel='noreferrer'>
          <XIcon />
        </a>
        <a href='mailto:hello@endaoment.org' target='_blank' rel='noreferrer'>
          <MailIcon />
        </a>
        <a href='https://mirror.xyz/endaoment.eth' target='_blank' rel='noreferrer'>
          <MirrorIcon />
        </a>
        <a href='https://www.linkedin.com/company/endaoment/' target='_blank' rel='noreferrer'>
          <LinkedInIcon />
        </a>
        <a href='https://www.facebook.com/endaomentdotorg/' target='_blank' rel='noreferrer'>
          <FacebookIcon />
        </a>
      </Flex>
    </section>
  );
};
