import type { IconProps } from './Icon.types';

export const NDAOTokenDiagramIcon = ({ width = 77, height, ...props }: IconProps) => {
  return (
    <svg
      width={width}
      height={height ?? width}
      viewBox='0 0 77 77'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <circle cx='38.5' cy='38.5' r='38' fill='none' stroke='currentColor' />
      <path
        d='M23.6476 27.6044C26.1229 27.6044 28.1294 25.598 28.1294 23.1227C28.1294 20.6481 26.1229 18.6416 23.6476 18.6416C21.173 18.6416 19.1665 20.6481 19.1665 23.1227C19.1665 25.598 21.173 27.6044 23.6476 27.6044Z'
        fill='currentColor'
      />
      <path
        d='M23.6476 58.082C26.1229 58.082 28.1294 56.0755 28.1294 53.6009C28.1294 51.1256 26.1229 49.1191 23.6476 49.1191C21.173 49.1191 19.1665 51.1256 19.1665 53.6009C19.1665 56.0755 21.173 58.082 23.6476 58.082Z'
        fill='currentColor'
      />
      <path
        d='M53.7967 27.6044C56.2713 27.6044 58.2778 25.598 58.2778 23.1227C58.2778 20.6481 56.2713 18.6416 53.7967 18.6416C51.3214 18.6416 49.3149 20.6481 49.3149 23.1227C49.3149 25.598 51.3214 27.6044 53.7967 27.6044Z'
        fill='currentColor'
      />
      <path
        d='M53.7967 58.082C56.2713 58.082 58.2778 56.0755 58.2778 53.6009C58.2778 51.1256 56.2713 49.1191 53.7967 49.1191C51.3214 49.1191 49.3149 51.1256 49.3149 53.6009C49.3149 56.0755 51.3214 58.082 53.7967 58.082Z'
        fill='currentColor'
      />
      <path
        d='M32.3665 40.3525L26.1635 38.8018L13 42.3301L14.6848 48.6067L34.2224 43.3681L28.9474 62.9057L35.2116 64.5905L38.7523 51.3906L37.1899 45.1876C36.906 44.017 36.3066 42.9467 35.4556 42.0937C34.6053 41.2413 33.5364 40.6385 32.3665 40.3525Z'
        fill='currentColor'
      />
      <path
        d='M28.9474 14.6851L34.186 34.2224L14.6484 28.9838L13 35.2239L26.1635 38.7523L32.3665 37.2627C33.5426 36.9726 34.6156 36.3629 35.4666 35.5009C36.3176 34.639 36.9142 33.5584 37.1899 32.3788L38.7523 26.1752L35.2116 13L28.9474 14.6851Z'
        fill='currentColor'
      />
      <path
        d='M45.1749 37.2627L51.4274 38.7523L64.5902 35.2239L62.9178 28.9474L43.3801 34.186L48.6187 14.6485L42.3298 13L38.7891 26.1999L40.3522 32.3788C40.6326 33.5556 41.2307 34.6335 42.081 35.4948C42.9313 36.356 44.0015 36.9678 45.1749 37.2627Z'
        fill='currentColor'
      />
      <path
        d='M45.1749 40.3525C44.0118 40.6405 42.9491 41.2392 42.0995 42.084C41.2499 42.9295 40.6464 43.9895 40.3522 45.1512L38.7891 51.3541L42.3298 64.554L48.6063 62.9057L43.3678 43.3681L62.9054 48.6067L64.5785 42.3301L51.4274 38.8018L45.1749 40.3525Z'
        fill='currentColor'
      />
    </svg>
  );
};
