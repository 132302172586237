import type { IconProps } from '../Icon.types';

export const PolygonChainIcon = ({ width = 28, height = 28, color, ...props }: IconProps) => (
  <svg width={width} height={height} viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M18.137 16.8599L21.695 14.6949C21.8834 14.5802 22 14.367 22 14.1375V9.80814C22 9.57926 21.8829 9.36552 21.695 9.2508L18.137 7.08583C17.9486 6.97112 17.7149 6.97166 17.5265 7.08583L13.9685 9.2508C13.7801 9.36552 13.6635 9.57926 13.6635 9.80814V17.5454L11.1685 19.0632L8.67346 17.5454V14.5093L11.1685 12.9915L12.8142 13.9925V11.9558L11.4735 11.1403C11.3811 11.0841 11.2753 11.0543 11.168 11.0543C11.0607 11.0543 10.9549 11.0841 10.863 11.1403L7.30501 13.3053C7.11656 13.42 7 13.6332 7 13.8627V18.1921C7 18.4209 7.11707 18.6347 7.30501 18.7494L10.863 20.9144C11.0509 21.0285 11.2851 21.0285 11.4735 20.9144L15.0315 18.7499C15.2199 18.6352 15.3365 18.4215 15.3365 18.1926V10.4553L15.3817 10.4283L17.8315 8.9375L20.3265 10.4553V13.4915L17.8315 15.0093L16.1883 14.0093V16.046L17.5265 16.8604C17.7149 16.9746 17.9486 16.9746 18.137 16.8604V16.8599Z'
      fill={color ?? 'white'}
    />
  </svg>
);
