import type { IconProps } from './Icon.types';

export const ClaimedIcon = ({ width = 30, height, className, color }: IconProps) => (
  <svg
    width={width}
    height={height ?? width}
    className={className}
    viewBox='0 0 115 115'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M65.3886 16.379L64.7732 13.8507C62.8796 6.072 51.8307 6.03517 49.8853 13.801L49.28 16.2175C47.5061 23.2987 39.3984 26.657 33.1369 22.9041C26.4799 18.9143 18.9154 26.4795 22.9051 33.136C26.658 39.3977 23.2996 47.5055 16.2183 49.2794L13.7089 49.9081C5.93931 51.8544 5.93932 62.8956 13.7089 64.8419L16.3431 65.5018C22.7622 67.1098 25.8065 74.4593 22.4045 80.1353C18.5453 86.5744 25.8627 93.8917 32.3018 90.0325L32.9277 89.6574C39.0425 85.9925 46.9602 89.2721 48.6925 96.1874L49.6992 100.206C51.7001 108.193 63.0507 108.193 65.0515 100.206L66.2003 95.6203C67.7052 89.6127 74.6479 86.7431 80.0052 89.954C86.2267 93.6829 93.7071 86.5712 89.6098 80.1435C86.2469 74.8678 89.042 67.8479 95.1109 66.3277L99.5535 65.2148C107.579 63.2044 107.421 51.7453 99.3433 49.9568L96.2575 49.2735C89.0592 47.6796 85.5622 39.4803 89.3945 33.1819L89.8602 32.4164C93.7722 25.9871 86.4522 18.6181 79.997 22.4871C74.3133 25.8936 66.9559 22.8173 65.3886 16.379Z'
      stroke={color ?? '#C4C4C4'}
      strokeWidth='7.82387'
      strokeLinejoin='round'
    />
    <path
      d='M58.8718 69.6738L54.8535 73.6921C52.72 75.8256 49.2593 75.8198 47.1329 73.6793L43.0943 69.6137L35.4035 61.8717C33.2477 59.7015 33.2593 56.1945 35.4296 54.0387C37.5947 51.8879 41.0917 51.8937 43.2497 54.0516L50.953 61.755L68.9986 43.7094C71.1853 41.5227 74.7307 41.5227 76.9174 43.7094C79.1041 45.8961 79.1041 49.4415 76.9174 51.6282L58.8718 69.6738Z'
      fill={color ?? '#C4C4C4'}
    />
  </svg>
);
