import type { IconProps } from './Icon.types';

export const GlobeAltIcon = ({ width, height, className, color }: IconProps) => (
  <svg
    width={width ?? '36'}
    height={height ?? width ?? '36'}
    className={className}
    viewBox='0 0 36 36'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <circle cx='17.5' cy='17.5' r='11' fill={color ?? 'white'} fillOpacity='0.5' stroke={color ?? 'white'} />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M27.8892 15.9693C27.2113 11.328 23.497 7.67222 18.8275 7.08312C19.2248 7.78936 19.5 8.47515 19.5 8.99976C19.5 10.0718 18.7819 10.4257 18.1155 10.7542C17.5384 11.0386 17 11.304 17 12C17 13.5736 17.8667 14.2001 18.6143 14.7405C19.369 15.286 20.0024 15.7438 19.5 16.9998C18.5 19.4998 20.5 20.5 22 19.5C22.5703 19.1198 22.7793 18.4504 22.984 17.7942C23.3178 16.7246 23.6407 15.6901 25.5 16C26.8615 16.2269 27.5021 16.2478 27.8892 15.9693ZM8.25383 22.4801C7.45399 20.9982 7 19.3021 7 17.5C7 16.3915 7.17178 15.3231 7.49016 14.32C7.92485 14.0363 8.40154 13.6453 8.89309 13.2421C10.1496 12.2115 11.5032 11.1013 12.5 11.5C13.8376 12.035 13.4344 13.1192 13.0125 14.254C12.6458 15.2402 12.2649 16.2645 13 16.9998C14.5 18.5 17 21.5 14.5 22C12.6968 22.3606 11.6004 21.5443 10.7964 20.9455C9.85158 20.2419 9.3104 19.8389 8.5 22C8.41902 22.216 8.33682 22.3739 8.25383 22.4801ZM19.1332 27.8737C18.601 27.9569 18.0555 28 17.5 28C15.9125 28 14.4074 27.6477 13.0584 27.0171C13.1567 26.8441 13.2527 26.671 13.3473 26.5005C14.2558 24.8627 15.0369 23.4546 16.5 24.4998C18.8055 26.1467 19.467 24.9274 20.1613 23.6476C20.521 22.9845 20.8895 22.3053 21.5 22C22.3423 21.5789 22.8299 22.1332 23.4856 22.8788C23.9543 23.4117 24.5089 24.0423 25.3405 24.4842C24.2356 25.7237 22.8412 26.6995 21.2635 27.3054C20.5622 27.4973 19.8463 27.6886 19.1332 27.8737Z'
      fill={color ?? 'white'}
    />
  </svg>
);
