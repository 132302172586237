import type { IconProps } from './Icon.types';

export const ShieldIcon = ({ width = 18, height = 24, color = '#EA6B0E', ...props }: IconProps) => (
  <svg width={width} height={height} viewBox='0 0 18 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.34088 0.226401C8.72709 -0.0754674 9.27291 -0.0754666 9.65912 0.226401C12.3428 2.32401 14.2737 2.63871 16.9996 2.68177C17.5518 2.69049 18 3.13587 18 3.68815V14.2232C18 18.1786 15.6349 19.6219 11.291 22.2727C10.7375 22.6105 10.1519 22.9678 9.53498 23.3525C9.20762 23.5566 8.79238 23.5566 8.46502 23.3525C7.84808 22.9678 7.26247 22.6105 6.70898 22.2727C2.36513 19.6219 0 18.1786 0 14.2232V3.68815C0 3.13587 0.448173 2.69049 1.00039 2.68177C3.72633 2.63871 5.65719 2.32401 8.34088 0.226401ZM13.8792 10.5766C14.3699 10.0911 14.3741 9.29961 13.8885 8.80891C13.4029 8.3182 12.6115 8.31406 12.1208 8.79965L7.94737 12.9296L5.87924 10.883C5.38854 10.3974 4.59709 10.4015 4.1115 10.8922C3.62591 11.3829 3.63005 12.1744 4.12076 12.66L7.06812 15.5766C7.5552 16.0587 8.33954 16.0587 8.82661 15.5766L13.8792 10.5766Z'
      fill={color}
      fillOpacity='1.0'
    />
  </svg>
);
