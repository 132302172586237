import type { IconProps } from './Icon.types';

export const MinusIcon = ({ width = 23, height = 2, strokeColor = '#627EEA', strokeWidth = 2 }: IconProps) => {
  return (
    <svg width={width} height={height} viewBox='0 0 23 2' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <line
        x1='21.04'
        y1='1'
        x2='1.04004'
        y2='0.999998'
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
      />
      <line
        x1='22'
        y1='0.999878'
        x2='2'
        y2='0.999876'
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
      />
    </svg>
  );
};
