import type { IconProps } from '../Icon.types';

export const ArbitrumChainIcon = ({ width = 28, height = 28, color, ...props }: IconProps) => (
  <svg width={width} height={height} viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M15.4404 15.4869L14.4985 18.0713C14.4751 18.1414 14.4751 18.2192 14.4985 18.2971L16.1176 22.7419L17.9936 21.6599L15.744 15.4869C15.6895 15.3468 15.4949 15.3468 15.4404 15.4869Z'
      fill={color ?? 'white'}
    />
    <path
      d='M17.3318 11.1432C17.2774 11.0031 17.0828 11.0031 17.0283 11.1432L16.0864 13.7276C16.063 13.7976 16.063 13.8755 16.0864 13.9533L18.7408 21.2316L20.6168 20.1496L17.3318 11.151V11.1432Z'
      fill={color ?? 'white'}
    />
    <path
      d='M14 5.48391C14.0467 5.48391 14.0934 5.49948 14.1323 5.52283L21.2783 9.64851C21.3639 9.69521 21.4106 9.78863 21.4106 9.88204V18.1334C21.4106 18.2268 21.3562 18.3202 21.2783 18.3669L14.1323 22.4926C14.0934 22.5159 14.0467 22.5315 14 22.5315C13.9533 22.5315 13.9066 22.5159 13.8677 22.4926L6.72168 18.3669C6.63606 18.3202 6.58935 18.2268 6.58935 18.1334V9.87425C6.58935 9.78084 6.64384 9.68743 6.72168 9.64072L13.8677 5.51505C13.9066 5.49169 13.9533 5.47613 14 5.47613V5.48391ZM14 4.27734C13.7431 4.27734 13.494 4.33962 13.2605 4.47195L6.11451 8.59763C5.65523 8.86229 5.375 9.34492 5.375 9.87425V18.1256C5.375 18.6549 5.65523 19.1376 6.11451 19.4022L13.2605 23.5279C13.4862 23.6602 13.7431 23.7225 14 23.7225C14.2569 23.7225 14.506 23.6602 14.7395 23.5279L21.8855 19.4022C22.3448 19.1376 22.625 18.6549 22.625 18.1256V9.87425C22.625 9.34492 22.3448 8.86229 21.8855 8.59763L14.7317 4.47195C14.506 4.33962 14.2491 4.27734 13.9922 4.27734H14Z'
      fill={color ?? 'white'}
    />
    <path
      d='M13.393 9.2905H11.5793C11.447 9.2905 11.3224 9.37613 11.2757 9.50068L7.39136 20.1496L9.26737 21.2316L13.5487 9.50068C13.5877 9.39169 13.5098 9.28271 13.4008 9.28271L13.393 9.2905Z'
      fill={color ?? 'white'}
    />
    <path
      d='M16.5688 9.2905H14.755C14.6227 9.2905 14.4982 9.37613 14.4515 9.50068L10.0144 21.6597L11.8904 22.7418L16.7167 9.50068C16.7556 9.39169 16.6778 9.28271 16.5688 9.28271V9.2905Z'
      fill={color ?? 'white'}
    />
  </svg>
);
