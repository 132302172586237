import clsx from 'clsx';
import { useSwiper } from 'swiper/react';

import { Button } from './Button';
import styles from './SwiperButtons.module.scss';

export const SwiperButtons = ({ outside }: { outside?: boolean }) => {
  const swiper = useSwiper();
  return (
    <>
      <Button
        className={clsx(styles['button'], styles['button__prev'], outside && styles['button--outside'])}
        onClick={() => swiper.slidePrev()}
        float={false}>
        {' '}
      </Button>
      <Button
        className={clsx(styles['button'], styles['button__next'], outside && styles['button--outside'])}
        onClick={() => swiper.slideNext()}
        float={false}>
        {' '}
      </Button>
    </>
  );
};
