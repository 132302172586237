import type { IconProps } from './Icon.types';

export const USDCTokenIcon = ({ width = 86, height, ...props }: IconProps) => {
  return (
    <svg
      width={width}
      height={height ?? width}
      viewBox='0 0 86 86'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M84.5 43C84.5 66.0009 66.0009 84.5 43 84.5C19.9991 84.5 1.5 66.0009 1.5 43C1.5 19.9991 19.9991 1.5 43 1.5C66.0009 1.5 84.5 19.9991 84.5 43Z'
        fill='#2775CA'
        stroke='#084F9B'
        strokeWidth='3'
      />
      <path
        d='M54.8251 49.8082C54.8251 43.5375 51.0626 41.3875 43.5376 40.4918C38.1626 39.775 37.0876 38.3418 37.0876 35.8332C37.0876 33.3246 38.8794 31.7125 42.4626 31.7125C45.6876 31.7125 47.4794 32.7875 48.3751 35.475C48.5544 36.0125 49.0919 36.3707 49.6294 36.3707H52.4958C53.2126 36.3707 53.7501 35.8332 53.7501 35.1168V34.9375C53.0333 30.9957 49.8083 27.95 45.6876 27.5918V23.2918C45.6876 22.575 45.1501 22.0375 44.2544 21.8582H41.5669C40.8501 21.8582 40.3126 22.3957 40.1333 23.2918V27.4125C34.7583 28.1293 31.3544 31.7125 31.3544 36.1918C31.3544 42.1043 34.9376 44.4332 42.4626 45.3293C47.4794 46.225 49.0919 47.3 49.0919 50.1668C49.0919 53.0336 46.5833 55.0043 43.1794 55.0043C38.5208 55.0043 36.9083 53.0332 36.3708 50.3457C36.1919 49.6293 35.6544 49.2707 35.1169 49.2707H32.0708C31.3544 49.2707 30.8169 49.8082 30.8169 50.525V50.7043C31.5333 55.1832 34.4001 58.4082 40.3126 59.3043V63.6043C40.3126 64.3207 40.8501 64.8582 41.7458 65.0375H44.4333C45.1501 65.0375 45.6876 64.5 45.8669 63.6043V59.3043C51.2419 58.4082 54.8251 54.6457 54.8251 49.8082V49.8082Z'
        fill='white'
      />
      <path
        d='M33.8626 68.6207C19.8876 63.6043 12.7208 48.0168 17.9169 34.2207C20.6044 26.6957 26.5169 20.9625 33.8626 18.275C34.5794 17.9168 34.9376 17.3793 34.9376 16.4832V13.975C34.9376 13.2582 34.5794 12.7207 33.8626 12.5418C33.6833 12.5418 33.3251 12.5418 33.1458 12.7207C16.1251 18.0957 6.80827 36.1918 12.1833 53.2125C15.4083 63.2457 23.1126 70.95 33.1458 74.175C33.8626 74.5332 34.5794 74.175 34.7583 73.4582C34.9376 73.2793 34.9376 73.1 34.9376 72.7418V70.2332C34.9376 69.6957 34.4001 68.9793 33.8626 68.6207ZM52.8544 12.7207C52.1376 12.3625 51.4208 12.7207 51.2419 13.4375C51.0626 13.6168 51.0626 13.7957 51.0626 14.1543V16.6625C51.0626 17.3793 51.6001 18.0957 52.1376 18.4543C66.1126 23.4707 73.2794 39.0582 68.0833 52.8543C65.3958 60.3793 59.4833 66.1125 52.1376 68.8C51.4208 69.1582 51.0626 69.6957 51.0626 70.5918V73.1C51.0626 73.8168 51.4208 74.3543 52.1376 74.5332C52.3169 74.5332 52.6751 74.5332 52.8544 74.3543C69.8751 68.9793 79.1919 50.8832 73.8169 33.8625C70.5919 23.65 62.7083 15.9457 52.8544 12.7207V12.7207Z'
        fill='white'
      />
    </svg>
  );
};
