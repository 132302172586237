import { forwardRef } from 'react';

import type { IconProps } from './Icon.types';

export const CheckmarkIcon = forwardRef<SVGSVGElement, IconProps>(function Checkmark(
  { width = 24, height, strokeColor = '#11B736', color, className, strokeWidth = 2, ...props },
  ref,
) {
  return (
    <svg
      width={width}
      height={height ?? width}
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      ref={ref}
      className={className}
      {...props}>
      <path
        d='M30 10L14 26L6 18'
        stroke={color ?? strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
});
