import { useId } from 'react';

import type { IconGradientFillProps } from './Icon.types';

export const FilledCircleIcon = ({
  startColor = 'currentColor',
  endColor = 'currentColor',
  className,
}: IconGradientFillProps) => {
  const gradientId = useId();

  return (
    <svg
      className={className}
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <linearGradient id={gradientId} x1='0%' y1='0%' x2='100%' y2='100%'>
        <stop offset='0%' style={{ stopColor: startColor, stopOpacity: 1 }} />
        <stop offset='100%' style={{ stopColor: endColor, stopOpacity: 1 }} />
      </linearGradient>
      <circle cx='16' cy='16' r='16' fill={`url(#${gradientId})`} />
    </svg>
  );
};
