import clsx from 'clsx';

import styles from './Icon.module.scss';
import type { IconProps } from './Icon.types';

export const BigLoadingIcon = ({
  width = 69,
  height,
  spinning = false,
  strokeWidth = 4,
  className,
  ...props
}: IconProps & { spinning?: boolean }) => (
  <svg
    width={width}
    height={height ?? width}
    viewBox='0 0 69 69'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={clsx(spinning && styles['spinning'], className)}
    {...props}>
    <circle cx='34.5' cy='34.5' r='32.5' stroke='currentColor' strokeOpacity={0.1} strokeWidth={strokeWidth} />
    <path d='M67 32.5C67 51.5538 51.5538 67 32.5 67' stroke='currentColor' strokeWidth={strokeWidth} />
  </svg>
);
