import clsx from 'clsx';

import styles from './Icon.module.scss';
import type { IconProps } from './Icon.types';

export const LoadingIcon = ({
  width = 20,
  height,
  spinning = false,
  className,
  ...props
}: IconProps & { spinning?: boolean }) => (
  <svg
    width={width}
    height={height ?? width}
    viewBox='0 0 175 176'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={clsx(spinning && styles['spinning'], className)}
    {...props}>
    <circle cx='87.5' cy='88' r='72.5' stroke='currentColor' strokeOpacity={0.1} strokeWidth='30' />
    <path
      d='M160 88C160 78.4792 158.125 69.0516 154.481 60.2554C150.838 51.4593 145.497 43.467 138.765 36.7348C132.033 30.0025 124.041 24.6622 115.245 21.0187C106.448 17.3753 97.0208 15.5 87.5 15.5'
      stroke='currentColor'
      strokeWidth='30'
    />
  </svg>
);
