import type { IconProps } from './Icon.types';

export const NDAOTokenIcon = ({ width = 86, height, ...props }: IconProps) => {
  return (
    <svg
      width={width}
      height={height ?? width}
      viewBox='0 0 86 86'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <circle cx='43' cy='43' r='41.5' fill='#627EEA' stroke='#1F49EA' strokeWidth='3' />
      <path
        d='M26.5591 30.8527C29.2458 30.8527 31.4237 28.6747 31.4237 25.9879C31.4237 23.3018 29.2458 21.1238 26.5591 21.1238C23.8732 21.1238 21.6953 23.3018 21.6953 25.9879C21.6953 28.6747 23.8732 30.8527 26.5591 30.8527Z'
        fill='white'
      />
      <path
        d='M26.5591 63.936C29.2458 63.936 31.4237 61.758 31.4237 59.0719C31.4237 56.385 29.2458 54.207 26.5591 54.207C23.8732 54.207 21.6953 56.385 21.6953 59.0719C21.6953 61.758 23.8732 63.936 26.5591 63.936Z'
        fill='white'
      />
      <path
        d='M59.2796 30.8527C61.9656 30.8527 64.1434 28.6747 64.1434 25.9879C64.1434 23.3018 61.9656 21.1238 59.2796 21.1238C56.5929 21.1238 54.415 23.3018 54.415 25.9879C54.415 28.6747 56.5929 30.8527 59.2796 30.8527Z'
        fill='white'
      />
      <path
        d='M59.2796 63.936C61.9656 63.936 64.1434 61.758 64.1434 59.0719C64.1434 56.385 61.9656 54.207 59.2796 54.207C56.5929 54.207 54.415 56.385 54.415 59.0719C54.415 61.758 56.5929 63.936 59.2796 63.936Z'
        fill='white'
      />
      <path
        d='M36.0206 44.6904L29.2878 43.0071L15 46.837L16.8287 53.65L38.0351 47.9637L32.3095 69.1713L39.1087 71L42.9519 56.6719L41.256 49.9387C40.9478 48.6681 40.2972 47.5063 39.3736 46.5803C38.4506 45.6551 37.2905 45.0008 36.0206 44.6904Z'
        fill='white'
      />
      <path
        d='M32.3095 16.8291L37.9955 38.0364L16.7891 32.35L15 39.1235L29.2878 42.9534L36.0206 41.3365C37.2972 41.0217 38.4618 40.3598 39.3855 39.4242C40.3092 38.4885 40.9568 37.3156 41.256 36.0352L42.9519 29.3013L39.1087 15L32.3095 16.8291Z'
        fill='white'
      />
      <path
        d='M49.9264 41.3365L56.7129 42.9534L70.9999 39.1235L69.1847 32.3105L47.9783 37.9968L53.6643 16.7894L46.8383 15L42.9951 29.3282L44.6917 36.0352C44.9962 37.3126 45.6453 38.4826 46.5682 39.4175C47.4911 40.3524 48.6528 41.0164 49.9264 41.3365Z'
        fill='white'
      />
      <path
        d='M49.9264 44.6904C48.664 45.003 47.5105 45.6529 46.5883 46.5699C45.6661 47.4877 45.0111 48.6382 44.6917 49.8992L42.9951 56.6324L46.8383 70.9605L53.6509 69.1713L47.9649 47.9637L69.1713 53.65L70.9873 46.837L56.7129 43.0071L49.9264 44.6904Z'
        fill='white'
      />
    </svg>
  );
};
