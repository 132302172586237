import type { IconProps } from './Icon.types';

export const FavoriteIcon = ({ width = 24, height, color, ...props }: IconProps) => (
  <svg
    width={width}
    height={height ?? width}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}>
    <path
      d='M9.7212 7.09056C10.6112 4.22283 11.0562 2.78896 11.8582 2.69342C11.9525 2.68219 12.0476 2.68219 12.1419 2.69342C12.9439 2.78896 13.3889 4.22283 14.2789 7.09056C14.5257 7.88581 14.6491 8.28343 14.9347 8.52764C14.9714 8.55903 15.0098 8.58822 15.0496 8.61507C15.3597 8.82391 15.7591 8.82391 16.5578 8.82391C19.438 8.82391 20.8781 8.82391 21.2127 9.59287C21.252 9.68326 21.2814 9.77802 21.3004 9.87536C21.4614 10.7034 20.2963 11.5895 17.9662 13.3619C17.32 13.8534 16.9969 14.0991 16.8633 14.4589C16.8461 14.5051 16.8315 14.5524 16.8194 14.6004C16.7255 14.9736 16.8489 15.3713 17.0957 16.1665C17.9858 19.0342 18.4308 20.4681 17.8356 21.0389C17.7657 21.106 17.6887 21.1646 17.6061 21.2135C16.9037 21.6297 15.7386 20.7435 13.4085 18.9711C12.7623 18.4796 12.4392 18.2339 12.0711 18.212C12.0238 18.2092 11.9763 18.2092 11.929 18.212C11.5609 18.2339 11.2378 18.4796 10.5916 18.9711C8.26152 20.7435 7.09645 21.6297 6.39402 21.2135C6.31145 21.1646 6.23444 21.106 6.16448 21.0389C5.56933 20.4681 6.01435 19.0342 6.90438 16.1665C7.15119 15.3713 7.2746 14.9736 7.1807 14.6004C7.16863 14.5524 7.15397 14.5051 7.1368 14.4589C7.00318 14.0991 6.6801 13.8534 6.03394 13.3619C3.7038 11.5895 2.53874 10.7034 2.69976 9.87536C2.71869 9.77802 2.7481 9.68326 2.78743 9.59287C3.12204 8.82391 4.56214 8.82391 7.44235 8.82391C8.24105 8.82391 8.6404 8.82391 8.95049 8.61507C8.99035 8.58822 9.02873 8.55903 9.06544 8.52764C9.35098 8.28343 9.47439 7.88581 9.7212 7.09056Z'
      fill={color ?? '#AFA6C9'}
    />
  </svg>
);
