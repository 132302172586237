import { z } from 'zod';

import { timestampSchema, uuidSchema } from '../general';
import { addressSchema, bigIntSchema } from '../web3';

import { entityTypeSchema } from './misc';
import { orgSimpleListingSchema, subprojectListingSchema } from './orgs';

const transferSchema = z.object({
  id: uuidSchema,
  type: z.enum(['FundTransfer', 'GrantTransfer']),
  asyncStatus: z.enum(['PendingLiquidation', 'Liquidated', 'Rejected']).nullish(),
  chainId: z.number(),
  transactionHash: addressSchema.nullish(),
  /** A USDC value */
  netAmount: bigIntSchema.nullish(),
  /** A USDC value */
  fee: bigIntSchema.nullish(),
  /** A USDC value */
  requestedAmount: bigIntSchema.nullish(),
  createdAtUtc: timestampSchema,
  updatedAtUtc: timestampSchema,
});
export type Transfer = z.infer<typeof transferSchema>;

export const grantSchema = transferSchema.extend({
  type: z.literal('GrantTransfer'),
  destinationOrg: orgSimpleListingSchema.nullish(),
  destinationSubproject: subprojectListingSchema.nullish(),
  recommender: z.string().nullish(),
  purpose: z.string().nullish(),
  specialInstructions: z.string().nullish(),
});
export type Grant = z.infer<typeof grantSchema>;

export const isTransfer = (obj: unknown): obj is Transfer => transferSchema.safeParse(obj).success;
export const isGrant = (obj: unknown): obj is Grant => grantSchema.safeParse(obj).success;

export const adminGrantSchema = grantSchema.omit({ destinationOrg: true }).extend({
  destinationOrg: orgSimpleListingSchema,
  originEntityId: uuidSchema,
  originEntityType: entityTypeSchema,
  originEntityName: z.string().nullish(),
});
export type AdminGrant = z.infer<typeof adminGrantSchema>;
