import { z } from 'zod';

export const US_STATES = z.enum(
  [
    'AL',
    'AK',
    'AZ',
    'AR',
    'CA',
    'CO',
    'CT',
    'DE',
    'DC',
    'FL',
    'GA',
    'HI',
    'ID',
    'IL',
    'IN',
    'IA',
    'KS',
    'KY',
    'LA',
    'ME',
    'MD',
    'MA',
    'MI',
    'MN',
    'MS',
    'MO',
    'MT',
    'NE',
    'NV',
    'NH',
    'NJ',
    'NM',
    'NY',
    'NC',
    'ND',
    'OH',
    'OK',
    'OR',
    'PA',
    'PR',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VT',
    'VA',
    'WA',
    'WV',
    'WI',
    'WY',
  ],
  {
    errorMap: () => ({ message: 'Please select a state' }),
  },
);

export const US_STATES_TO_FULL = {
  [US_STATES.enum.AL]: 'Alabama',
  [US_STATES.enum.AK]: 'Alaska',
  [US_STATES.enum.AZ]: 'Arizona',
  [US_STATES.enum.AR]: 'Arkansas',
  [US_STATES.enum.CA]: 'California',
  [US_STATES.enum.CO]: 'Colorado',
  [US_STATES.enum.CT]: 'Connecticut',
  [US_STATES.enum.DE]: 'Delaware',
  [US_STATES.enum.DC]: 'District Of Columbia',
  [US_STATES.enum.FL]: 'Florida',
  [US_STATES.enum.GA]: 'Georgia',
  [US_STATES.enum.HI]: 'Hawaii',
  [US_STATES.enum.ID]: 'Idaho',
  [US_STATES.enum.IL]: 'Illinois',
  [US_STATES.enum.IN]: 'Indiana',
  [US_STATES.enum.IA]: 'Iowa',
  [US_STATES.enum.KS]: 'Kansas',
  [US_STATES.enum.KY]: 'Kentucky',
  [US_STATES.enum.LA]: 'Louisiana',
  [US_STATES.enum.ME]: 'Maine',
  [US_STATES.enum.MD]: 'Maryland',
  [US_STATES.enum.MA]: 'Massachusetts',
  [US_STATES.enum.MI]: 'Michigan',
  [US_STATES.enum.MN]: 'Minnesota',
  [US_STATES.enum.MS]: 'Mississippi',
  [US_STATES.enum.MO]: 'Missouri',
  [US_STATES.enum.MT]: 'Montana',
  [US_STATES.enum.NE]: 'Nebraska',
  [US_STATES.enum.NV]: 'Nevada',
  [US_STATES.enum.NH]: 'New Hampshire',
  [US_STATES.enum.NJ]: 'New Jersey',
  [US_STATES.enum.NM]: 'New Mexico',
  [US_STATES.enum.NY]: 'New York',
  [US_STATES.enum.NC]: 'North Carolina',
  [US_STATES.enum.ND]: 'North Dakota',
  [US_STATES.enum.OH]: 'Ohio',
  [US_STATES.enum.OK]: 'Oklahoma',
  [US_STATES.enum.OR]: 'Oregon',
  [US_STATES.enum.PA]: 'Pennsylvania',
  [US_STATES.enum.PR]: 'Puerto Rico',
  [US_STATES.enum.RI]: 'Rhode Island',
  [US_STATES.enum.SC]: 'South Carolina',
  [US_STATES.enum.SD]: 'South Dakota',
  [US_STATES.enum.TN]: 'Tennessee',
  [US_STATES.enum.TX]: 'Texas',
  [US_STATES.enum.UT]: 'Utah',
  [US_STATES.enum.VT]: 'Vermont',
  [US_STATES.enum.VA]: 'Virginia',
  [US_STATES.enum.WA]: 'Washington',
  [US_STATES.enum.WV]: 'West Virginia',
  [US_STATES.enum.WI]: 'Wisconsin',
  [US_STATES.enum.WY]: 'Wyoming',
} as const satisfies Record<z.infer<typeof US_STATES>, string>;

// These latitudes and longitudes confirmed by @NoahGallant through TravelMath.com
export const CONTINENT = {
  'Africa': {
    name: 'Africa',
    location: { latitude: 7.1881, longitude: 21.0938 },
  },
  'North America': {
    name: 'North America',
    location: { latitude: 46.073055, longitude: -100.546666 },
  },
  'South America': {
    name: 'South America',
    location: { latitude: -14.60472, longitude: -57.65611 },
  },
  'Antarctica': {
    name: 'Antarctica',
    location: { latitude: -82.8628, longitude: 16.0 },
  },
  'Europe': {
    name: 'Europe',
    location: { latitude: 48.6908, longitude: 9.14055 },
  },
  'Asia': {
    name: 'Asia',
    location: { latitude: 29.8405, longitude: 89.29666 },
  },
  'Oceania': {
    name: 'Oceania',
    location: { latitude: -18.3127, longitude: 138.5155 },
  },
} as const;

export const COUNTRY_ISO3166_ALPHA3_NOT_US = z.enum(
  [
    'ABW',
    'AFG',
    'AGO',
    'AIA',
    'ALA',
    'ALB',
    'AND',
    'ARE',
    'ARG',
    'ARM',
    'ASM',
    'ATA',
    'ATF',
    'ATG',
    'AUS',
    'AUT',
    'AZE',
    'BDI',
    'BEL',
    'BEN',
    'BES',
    'BFA',
    'BGD',
    'BGR',
    'BHR',
    'BHS',
    'BIH',
    'BLM',
    'BLR',
    'BLZ',
    'BMU',
    'BOL',
    'BRA',
    'BRB',
    'BRN',
    'BTN',
    'BVT',
    'BWA',
    'CAF',
    'CAN',
    'CCK',
    'CHE',
    'CHL',
    'CIV',
    'CMR',
    'COD',
    'COG',
    'COK',
    'COL',
    'COM',
    'CPV',
    'CRI',
    'CUW',
    'CXR',
    'CYM',
    'CYP',
    'CZE',
    'DEU',
    'DJI',
    'DMA',
    'DNK',
    'DOM',
    'DZA',
    'ECU',
    'EGY',
    'ERI',
    'ESH',
    'ESP',
    'EST',
    'ETH',
    'FIN',
    'FJI',
    'FLK',
    'FRA',
    'FRO',
    'FSM',
    'GAB',
    'GBR',
    'GEO',
    'GGY',
    'GHA',
    'GIB',
    'GIN',
    'GLP',
    'GMB',
    'GNB',
    'GNQ',
    'GRC',
    'GRD',
    'GRL',
    'GTM',
    'GUF',
    'GUM',
    'GUY',
    'HKG',
    'HMD',
    'HND',
    'HRV',
    'HTI',
    'HUN',
    'IDN',
    'IMN',
    'IND',
    'IOT',
    'IRL',
    'IRQ',
    'ISL',
    'ISR',
    'ITA',
    'JAM',
    'JEY',
    'JOR',
    'JPN',
    'KAZ',
    'KEN',
    'KGZ',
    'KHM',
    'KIR',
    'KNA',
    'KOR',
    'KWT',
    'LAO',
    'LBN',
    'LBR',
    'LBY',
    'LCA',
    'LIE',
    'LKA',
    'LSO',
    'LTU',
    'LUX',
    'LVA',
    'MAC',
    'MAF',
    'MAR',
    'MCO',
    'MDA',
    'MDG',
    'MDV',
    'MEX',
    'MHL',
    'MKD',
    'MLI',
    'MLT',
    'MMR',
    'MNE',
    'MNG',
    'MNP',
    'MOZ',
    'MRT',
    'MSR',
    'MTQ',
    'MUS',
    'MWI',
    'MYS',
    'MYT',
    'NAM',
    'NCL',
    'NER',
    'NFK',
    'NGA',
    'NIC',
    'NIU',
    'NLD',
    'NOR',
    'NPL',
    'NRU',
    'NZL',
    'OMN',
    'PAK',
    'PAN',
    'PCN',
    'PER',
    'PHL',
    'PLW',
    'PNG',
    'POL',
    'PRI',
    'PRT',
    'PRY',
    'PSE',
    'PYF',
    'QAT',
    'REU',
    'ROU',
    'RWA',
    'SAU',
    'SDN',
    'SEN',
    'SGP',
    'SGS',
    'SHN',
    'SJM',
    'SLB',
    'SLE',
    'SLV',
    'SMR',
    'SOM',
    'SPM',
    'SRB',
    'SSD',
    'STP',
    'SUR',
    'SVK',
    'SVN',
    'SWE',
    'SWZ',
    'SXM',
    'SYC',
    'TCA',
    'TCD',
    'TGO',
    'THA',
    'TJK',
    'TKL',
    'TKM',
    'TLS',
    'TON',
    'TTO',
    'TUN',
    'TUR',
    'TUV',
    'TWN',
    'TZA',
    'UGA',
    'UKR',
    'UMI',
    'URY',
    'UZB',
    'VAT',
    'VCT',
    'VEN',
    'VGB',
    'VIR',
    'VNM',
    'VUT',
    'WLF',
    'WSM',
    'XKX',
    'YEM',
    'ZAF',
    'ZMB',
    'ZWE',
  ],
  {
    errorMap: () => ({ message: 'Please select a country' }),
  },
);
export const COUNTRY_ISO3166_ALPHA3 = z.enum([...COUNTRY_ISO3166_ALPHA3_NOT_US.options, 'USA'], {
  errorMap: () => ({ message: 'Please select a country' }),
});

export const CONTINENT_TO_COUNTRY: Record<keyof typeof CONTINENT, Array<z.infer<typeof COUNTRY_ISO3166_ALPHA3>>> = {
  'Africa': [
    COUNTRY_ISO3166_ALPHA3.enum.AGO,
    COUNTRY_ISO3166_ALPHA3.enum.BDI,
    COUNTRY_ISO3166_ALPHA3.enum.BEN,
    COUNTRY_ISO3166_ALPHA3.enum.BFA,
    COUNTRY_ISO3166_ALPHA3.enum.BWA,
    COUNTRY_ISO3166_ALPHA3.enum.CAF,
    COUNTRY_ISO3166_ALPHA3.enum.CIV,
    COUNTRY_ISO3166_ALPHA3.enum.CMR,
    COUNTRY_ISO3166_ALPHA3.enum.COD,
    COUNTRY_ISO3166_ALPHA3.enum.COG,
    COUNTRY_ISO3166_ALPHA3.enum.COM,
    COUNTRY_ISO3166_ALPHA3.enum.CPV,
    COUNTRY_ISO3166_ALPHA3.enum.DJI,
    COUNTRY_ISO3166_ALPHA3.enum.DZA,
    COUNTRY_ISO3166_ALPHA3.enum.EGY,
    COUNTRY_ISO3166_ALPHA3.enum.ERI,
    COUNTRY_ISO3166_ALPHA3.enum.ESH,
    COUNTRY_ISO3166_ALPHA3.enum.ETH,
    COUNTRY_ISO3166_ALPHA3.enum.GAB,
    COUNTRY_ISO3166_ALPHA3.enum.GHA,
    COUNTRY_ISO3166_ALPHA3.enum.GIN,
    COUNTRY_ISO3166_ALPHA3.enum.GMB,
    COUNTRY_ISO3166_ALPHA3.enum.GNB,
    COUNTRY_ISO3166_ALPHA3.enum.GNQ,
    COUNTRY_ISO3166_ALPHA3.enum.IOT,
    COUNTRY_ISO3166_ALPHA3.enum.KEN,
    COUNTRY_ISO3166_ALPHA3.enum.LBR,
    COUNTRY_ISO3166_ALPHA3.enum.LBY,
    COUNTRY_ISO3166_ALPHA3.enum.LSO,
    COUNTRY_ISO3166_ALPHA3.enum.MAR,
    COUNTRY_ISO3166_ALPHA3.enum.MDG,
    COUNTRY_ISO3166_ALPHA3.enum.MLI,
    COUNTRY_ISO3166_ALPHA3.enum.MOZ,
    COUNTRY_ISO3166_ALPHA3.enum.MRT,
    COUNTRY_ISO3166_ALPHA3.enum.MUS,
    COUNTRY_ISO3166_ALPHA3.enum.MWI,
    COUNTRY_ISO3166_ALPHA3.enum.MYT,
    COUNTRY_ISO3166_ALPHA3.enum.NAM,
    COUNTRY_ISO3166_ALPHA3.enum.NER,
    COUNTRY_ISO3166_ALPHA3.enum.NGA,
    COUNTRY_ISO3166_ALPHA3.enum.REU,
    COUNTRY_ISO3166_ALPHA3.enum.RWA,
    COUNTRY_ISO3166_ALPHA3.enum.SDN,
    COUNTRY_ISO3166_ALPHA3.enum.SEN,
    COUNTRY_ISO3166_ALPHA3.enum.SHN,
    COUNTRY_ISO3166_ALPHA3.enum.SLE,
    COUNTRY_ISO3166_ALPHA3.enum.SOM,
    COUNTRY_ISO3166_ALPHA3.enum.SSD,
    COUNTRY_ISO3166_ALPHA3.enum.STP,
    COUNTRY_ISO3166_ALPHA3.enum.SWZ,
    COUNTRY_ISO3166_ALPHA3.enum.SYC,
    COUNTRY_ISO3166_ALPHA3.enum.TCD,
    COUNTRY_ISO3166_ALPHA3.enum.TGO,
    COUNTRY_ISO3166_ALPHA3.enum.TUN,
    COUNTRY_ISO3166_ALPHA3.enum.TZA,
    COUNTRY_ISO3166_ALPHA3.enum.UGA,
    COUNTRY_ISO3166_ALPHA3.enum.ZAF,
    COUNTRY_ISO3166_ALPHA3.enum.ZMB,
    COUNTRY_ISO3166_ALPHA3.enum.ZWE,
  ],
  'North America': [
    COUNTRY_ISO3166_ALPHA3.enum.ABW,
    COUNTRY_ISO3166_ALPHA3.enum.AIA,
    COUNTRY_ISO3166_ALPHA3.enum.ATG,
    COUNTRY_ISO3166_ALPHA3.enum.BES,
    COUNTRY_ISO3166_ALPHA3.enum.BHS,
    COUNTRY_ISO3166_ALPHA3.enum.BLM,
    COUNTRY_ISO3166_ALPHA3.enum.BLZ,
    COUNTRY_ISO3166_ALPHA3.enum.BMU,
    COUNTRY_ISO3166_ALPHA3.enum.BRB,
    COUNTRY_ISO3166_ALPHA3.enum.CAN,
    COUNTRY_ISO3166_ALPHA3.enum.CRI,
    COUNTRY_ISO3166_ALPHA3.enum.CUW,
    COUNTRY_ISO3166_ALPHA3.enum.CYM,
    COUNTRY_ISO3166_ALPHA3.enum.DMA,
    COUNTRY_ISO3166_ALPHA3.enum.DOM,
    COUNTRY_ISO3166_ALPHA3.enum.GLP,
    COUNTRY_ISO3166_ALPHA3.enum.GRD,
    COUNTRY_ISO3166_ALPHA3.enum.GRL,
    COUNTRY_ISO3166_ALPHA3.enum.GTM,
    COUNTRY_ISO3166_ALPHA3.enum.HND,
    COUNTRY_ISO3166_ALPHA3.enum.HTI,
    COUNTRY_ISO3166_ALPHA3.enum.JAM,
    COUNTRY_ISO3166_ALPHA3.enum.KNA,
    COUNTRY_ISO3166_ALPHA3.enum.LCA,
    COUNTRY_ISO3166_ALPHA3.enum.MAF,
    COUNTRY_ISO3166_ALPHA3.enum.MEX,
    COUNTRY_ISO3166_ALPHA3.enum.MSR,
    COUNTRY_ISO3166_ALPHA3.enum.MTQ,
    COUNTRY_ISO3166_ALPHA3.enum.NIC,
    COUNTRY_ISO3166_ALPHA3.enum.PAN,
    COUNTRY_ISO3166_ALPHA3.enum.PRI,
    COUNTRY_ISO3166_ALPHA3.enum.SLV,
    COUNTRY_ISO3166_ALPHA3.enum.SPM,
    COUNTRY_ISO3166_ALPHA3.enum.SXM,
    COUNTRY_ISO3166_ALPHA3.enum.TCA,
    COUNTRY_ISO3166_ALPHA3.enum.USA,
    COUNTRY_ISO3166_ALPHA3.enum.VGB,
    COUNTRY_ISO3166_ALPHA3.enum.VIR,
  ],
  'South America': [
    COUNTRY_ISO3166_ALPHA3.enum.ARG,
    COUNTRY_ISO3166_ALPHA3.enum.BOL,
    COUNTRY_ISO3166_ALPHA3.enum.BRA,
    COUNTRY_ISO3166_ALPHA3.enum.CHL,
    COUNTRY_ISO3166_ALPHA3.enum.COL,
    COUNTRY_ISO3166_ALPHA3.enum.ECU,
    COUNTRY_ISO3166_ALPHA3.enum.FLK,
    COUNTRY_ISO3166_ALPHA3.enum.GUF,
    COUNTRY_ISO3166_ALPHA3.enum.GUY,
    COUNTRY_ISO3166_ALPHA3.enum.PER,
    COUNTRY_ISO3166_ALPHA3.enum.PRY,
    COUNTRY_ISO3166_ALPHA3.enum.SUR,
    COUNTRY_ISO3166_ALPHA3.enum.TTO,
    COUNTRY_ISO3166_ALPHA3.enum.URY,
    COUNTRY_ISO3166_ALPHA3.enum.VCT,
    COUNTRY_ISO3166_ALPHA3.enum.VEN,
  ],
  'Europe': [
    COUNTRY_ISO3166_ALPHA3.enum.ALA,
    COUNTRY_ISO3166_ALPHA3.enum.ALB,
    COUNTRY_ISO3166_ALPHA3.enum.AND,
    COUNTRY_ISO3166_ALPHA3.enum.AUT,
    COUNTRY_ISO3166_ALPHA3.enum.BEL,
    COUNTRY_ISO3166_ALPHA3.enum.BGR,
    COUNTRY_ISO3166_ALPHA3.enum.BIH,
    COUNTRY_ISO3166_ALPHA3.enum.BLR,
    COUNTRY_ISO3166_ALPHA3.enum.CHE,
    COUNTRY_ISO3166_ALPHA3.enum.CZE,
    COUNTRY_ISO3166_ALPHA3.enum.DEU,
    COUNTRY_ISO3166_ALPHA3.enum.DNK,
    COUNTRY_ISO3166_ALPHA3.enum.ESP,
    COUNTRY_ISO3166_ALPHA3.enum.EST,
    COUNTRY_ISO3166_ALPHA3.enum.FIN,
    COUNTRY_ISO3166_ALPHA3.enum.FRA,
    COUNTRY_ISO3166_ALPHA3.enum.FRO,
    COUNTRY_ISO3166_ALPHA3.enum.GBR,
    COUNTRY_ISO3166_ALPHA3.enum.GGY,
    COUNTRY_ISO3166_ALPHA3.enum.GIB,
    COUNTRY_ISO3166_ALPHA3.enum.GRC,
    COUNTRY_ISO3166_ALPHA3.enum.HRV,
    COUNTRY_ISO3166_ALPHA3.enum.HUN,
    COUNTRY_ISO3166_ALPHA3.enum.IMN,
    COUNTRY_ISO3166_ALPHA3.enum.IRL,
    COUNTRY_ISO3166_ALPHA3.enum.ISL,
    COUNTRY_ISO3166_ALPHA3.enum.ITA,
    COUNTRY_ISO3166_ALPHA3.enum.JEY,
    COUNTRY_ISO3166_ALPHA3.enum.LIE,
    COUNTRY_ISO3166_ALPHA3.enum.LTU,
    COUNTRY_ISO3166_ALPHA3.enum.LUX,
    COUNTRY_ISO3166_ALPHA3.enum.LVA,
    COUNTRY_ISO3166_ALPHA3.enum.MCO,
    COUNTRY_ISO3166_ALPHA3.enum.MDA,
    COUNTRY_ISO3166_ALPHA3.enum.MKD,
    COUNTRY_ISO3166_ALPHA3.enum.MLT,
    COUNTRY_ISO3166_ALPHA3.enum.MNE,
    COUNTRY_ISO3166_ALPHA3.enum.NLD,
    COUNTRY_ISO3166_ALPHA3.enum.NOR,
    COUNTRY_ISO3166_ALPHA3.enum.POL,
    COUNTRY_ISO3166_ALPHA3.enum.PRT,
    COUNTRY_ISO3166_ALPHA3.enum.ROU,
    COUNTRY_ISO3166_ALPHA3.enum.SJM,
    COUNTRY_ISO3166_ALPHA3.enum.SMR,
    COUNTRY_ISO3166_ALPHA3.enum.SRB,
    COUNTRY_ISO3166_ALPHA3.enum.SVK,
    COUNTRY_ISO3166_ALPHA3.enum.SVN,
    COUNTRY_ISO3166_ALPHA3.enum.SWE,
    COUNTRY_ISO3166_ALPHA3.enum.UKR,
    COUNTRY_ISO3166_ALPHA3.enum.VAT,
    COUNTRY_ISO3166_ALPHA3.enum.XKX,
  ],
  'Asia': [
    COUNTRY_ISO3166_ALPHA3.enum.AFG,
    COUNTRY_ISO3166_ALPHA3.enum.ARE,
    COUNTRY_ISO3166_ALPHA3.enum.ARM,
    COUNTRY_ISO3166_ALPHA3.enum.AZE,
    COUNTRY_ISO3166_ALPHA3.enum.BGD,
    COUNTRY_ISO3166_ALPHA3.enum.BHR,
    COUNTRY_ISO3166_ALPHA3.enum.BRN,
    COUNTRY_ISO3166_ALPHA3.enum.BTN,
    COUNTRY_ISO3166_ALPHA3.enum.CYP,
    COUNTRY_ISO3166_ALPHA3.enum.GEO,
    COUNTRY_ISO3166_ALPHA3.enum.HKG,
    COUNTRY_ISO3166_ALPHA3.enum.IDN,
    COUNTRY_ISO3166_ALPHA3.enum.IND,
    COUNTRY_ISO3166_ALPHA3.enum.IRQ,
    COUNTRY_ISO3166_ALPHA3.enum.ISR,
    COUNTRY_ISO3166_ALPHA3.enum.JOR,
    COUNTRY_ISO3166_ALPHA3.enum.JPN,
    COUNTRY_ISO3166_ALPHA3.enum.KAZ,
    COUNTRY_ISO3166_ALPHA3.enum.KGZ,
    COUNTRY_ISO3166_ALPHA3.enum.KHM,
    COUNTRY_ISO3166_ALPHA3.enum.KOR,
    COUNTRY_ISO3166_ALPHA3.enum.KWT,
    COUNTRY_ISO3166_ALPHA3.enum.LAO,
    COUNTRY_ISO3166_ALPHA3.enum.LBN,
    COUNTRY_ISO3166_ALPHA3.enum.LKA,
    COUNTRY_ISO3166_ALPHA3.enum.MAC,
    COUNTRY_ISO3166_ALPHA3.enum.MDV,
    COUNTRY_ISO3166_ALPHA3.enum.MMR,
    COUNTRY_ISO3166_ALPHA3.enum.MNG,
    COUNTRY_ISO3166_ALPHA3.enum.MYS,
    COUNTRY_ISO3166_ALPHA3.enum.NPL,
    COUNTRY_ISO3166_ALPHA3.enum.OMN,
    COUNTRY_ISO3166_ALPHA3.enum.PAK,
    COUNTRY_ISO3166_ALPHA3.enum.PHL,
    COUNTRY_ISO3166_ALPHA3.enum.PSE,
    COUNTRY_ISO3166_ALPHA3.enum.QAT,
    COUNTRY_ISO3166_ALPHA3.enum.SAU,
    COUNTRY_ISO3166_ALPHA3.enum.SGP,
    COUNTRY_ISO3166_ALPHA3.enum.THA,
    COUNTRY_ISO3166_ALPHA3.enum.TJK,
    COUNTRY_ISO3166_ALPHA3.enum.TKM,
    COUNTRY_ISO3166_ALPHA3.enum.TLS,
    COUNTRY_ISO3166_ALPHA3.enum.TUR,
    COUNTRY_ISO3166_ALPHA3.enum.TWN,
    COUNTRY_ISO3166_ALPHA3.enum.UZB,
    COUNTRY_ISO3166_ALPHA3.enum.VNM,
    COUNTRY_ISO3166_ALPHA3.enum.YEM,
  ],
  'Oceania': [
    COUNTRY_ISO3166_ALPHA3.enum.ASM,
    COUNTRY_ISO3166_ALPHA3.enum.AUS,
    COUNTRY_ISO3166_ALPHA3.enum.CCK,
    COUNTRY_ISO3166_ALPHA3.enum.COK,
    COUNTRY_ISO3166_ALPHA3.enum.CXR,
    COUNTRY_ISO3166_ALPHA3.enum.FJI,
    COUNTRY_ISO3166_ALPHA3.enum.FSM,
    COUNTRY_ISO3166_ALPHA3.enum.GUM,
    COUNTRY_ISO3166_ALPHA3.enum.KIR,
    COUNTRY_ISO3166_ALPHA3.enum.MHL,
    COUNTRY_ISO3166_ALPHA3.enum.MNP,
    COUNTRY_ISO3166_ALPHA3.enum.NCL,
    COUNTRY_ISO3166_ALPHA3.enum.NFK,
    COUNTRY_ISO3166_ALPHA3.enum.NIU,
    COUNTRY_ISO3166_ALPHA3.enum.NRU,
    COUNTRY_ISO3166_ALPHA3.enum.NZL,
    COUNTRY_ISO3166_ALPHA3.enum.PCN,
    COUNTRY_ISO3166_ALPHA3.enum.PLW,
    COUNTRY_ISO3166_ALPHA3.enum.PNG,
    COUNTRY_ISO3166_ALPHA3.enum.PYF,
    COUNTRY_ISO3166_ALPHA3.enum.SLB,
    COUNTRY_ISO3166_ALPHA3.enum.TKL,
    COUNTRY_ISO3166_ALPHA3.enum.TON,
    COUNTRY_ISO3166_ALPHA3.enum.TUV,
    COUNTRY_ISO3166_ALPHA3.enum.UMI,
    COUNTRY_ISO3166_ALPHA3.enum.VUT,
    COUNTRY_ISO3166_ALPHA3.enum.WLF,
    COUNTRY_ISO3166_ALPHA3.enum.WSM,
  ],
  'Antarctica': [
    COUNTRY_ISO3166_ALPHA3.enum.ATA,
    COUNTRY_ISO3166_ALPHA3.enum.ATF,
    COUNTRY_ISO3166_ALPHA3.enum.BVT,
    COUNTRY_ISO3166_ALPHA3.enum.HMD,
    COUNTRY_ISO3166_ALPHA3.enum.SGS,
  ],
};

export const COUNTRY_GEODATA: {
  [key in z.infer<typeof COUNTRY_ISO3166_ALPHA3>]: {
    code: key;
    name: string;
    location: {
      latitude: number;
      longitude: number;
    };
  };
} = {
  ABW: {
    code: COUNTRY_ISO3166_ALPHA3.enum.ABW,
    name: 'Aruba',
    location: { latitude: 12.52111, longitude: -69.968338 },
  },
  AFG: {
    code: COUNTRY_ISO3166_ALPHA3.enum.AFG,
    name: 'Afghanistan',
    location: { latitude: 33.93911, longitude: 67.709953 },
  },
  AGO: {
    code: COUNTRY_ISO3166_ALPHA3.enum.AGO,
    name: 'Angola',
    location: { latitude: -11.202692, longitude: 17.873887 },
  },
  AIA: {
    code: COUNTRY_ISO3166_ALPHA3.enum.AIA,
    name: 'Anguilla',
    location: { latitude: 18.220554, longitude: -63.068615 },
  },
  ALA: {
    code: COUNTRY_ISO3166_ALPHA3.enum.ALA,
    name: 'Aland Islands',
    location: { latitude: 60.178524, longitude: 19.915609 },
  },
  ALB: {
    code: COUNTRY_ISO3166_ALPHA3.enum.ALB,
    name: 'Albania',
    location: { latitude: 41.153332, longitude: 20.168331 },
  },
  AND: {
    code: COUNTRY_ISO3166_ALPHA3.enum.AND,
    name: 'Andorra',
    location: { latitude: 42.546245, longitude: 1.601554 },
  },
  ARE: {
    code: COUNTRY_ISO3166_ALPHA3.enum.ARE,
    name: 'United Arab Emirates',
    location: { latitude: 23.424076, longitude: 53.847818 },
  },
  ARG: {
    code: COUNTRY_ISO3166_ALPHA3.enum.ARG,
    name: 'Argentina',
    location: { latitude: -38.416097, longitude: -63.616672 },
  },
  ARM: {
    code: COUNTRY_ISO3166_ALPHA3.enum.ARM,
    name: 'Armenia',
    location: { latitude: 40.069099, longitude: 45.038189 },
  },
  ASM: {
    code: COUNTRY_ISO3166_ALPHA3.enum.ASM,
    name: 'American Samoa',
    location: { latitude: -14.270972, longitude: -170.132217 },
  },
  ATA: {
    code: COUNTRY_ISO3166_ALPHA3.enum.ATA,
    name: 'Antarctica',
    location: { latitude: -75.250973, longitude: -0.071389 },
  },
  ATF: {
    code: COUNTRY_ISO3166_ALPHA3.enum.ATF,
    name: 'French Southern Territories',
    location: { latitude: -49.280366, longitude: 69.348557 },
  },
  ATG: {
    code: COUNTRY_ISO3166_ALPHA3.enum.ATG,
    name: 'Antigua and Barbuda',
    location: { latitude: 17.060816, longitude: -61.796428 },
  },
  AUS: {
    code: COUNTRY_ISO3166_ALPHA3.enum.AUS,
    name: 'Australia',
    location: { latitude: -25.274398, longitude: 133.775136 },
  },
  AUT: {
    code: COUNTRY_ISO3166_ALPHA3.enum.AUT,
    name: 'Austria',
    location: { latitude: 47.516231, longitude: 14.550072 },
  },
  AZE: {
    code: COUNTRY_ISO3166_ALPHA3.enum.AZE,
    name: 'Azerbaijan',
    location: { latitude: 40.143105, longitude: 47.576927 },
  },
  BDI: {
    code: COUNTRY_ISO3166_ALPHA3.enum.BDI,
    name: 'Burundi',
    location: { latitude: -3.373056, longitude: 29.918886 },
  },
  BEL: {
    code: COUNTRY_ISO3166_ALPHA3.enum.BEL,
    name: 'Belgium',
    location: { latitude: 50.503887, longitude: 4.469936 },
  },
  BEN: { code: COUNTRY_ISO3166_ALPHA3.enum.BEN, name: 'Benin', location: { latitude: 9.30769, longitude: 2.315834 } },
  BES: {
    code: COUNTRY_ISO3166_ALPHA3.enum.BES,
    name: 'Bonaire, Sint Eustatius and Saba',
    location: { latitude: 12.1784, longitude: -68.2385 },
  },
  BFA: {
    code: COUNTRY_ISO3166_ALPHA3.enum.BFA,
    name: 'Burkina Faso',
    location: { latitude: 12.238333, longitude: -1.561593 },
  },
  BGD: {
    code: COUNTRY_ISO3166_ALPHA3.enum.BGD,
    name: 'Bangladesh',
    location: { latitude: 23.684994, longitude: 90.356331 },
  },
  BGR: {
    code: COUNTRY_ISO3166_ALPHA3.enum.BGR,
    name: 'Bulgaria',
    location: { latitude: 42.733883, longitude: 25.48583 },
  },
  BHR: {
    code: COUNTRY_ISO3166_ALPHA3.enum.BHR,
    name: 'Bahrain',
    location: { latitude: 25.930414, longitude: 50.637772 },
  },
  BHS: {
    code: COUNTRY_ISO3166_ALPHA3.enum.BHS,
    name: 'Bahamas, The',
    location: { latitude: 25.03428, longitude: -77.39628 },
  },
  BIH: {
    code: COUNTRY_ISO3166_ALPHA3.enum.BIH,
    name: 'Bosnia and Herzegovina',
    location: { latitude: 43.915886, longitude: 17.679076 },
  },
  BLM: {
    code: COUNTRY_ISO3166_ALPHA3.enum.BLM,
    name: 'Saint Barthélemy',
    location: { latitude: 17.9, longitude: -62.8333 },
  },
  BLR: {
    code: COUNTRY_ISO3166_ALPHA3.enum.BLR,
    name: 'Belarus',
    location: { latitude: 53.709807, longitude: 27.953389 },
  },
  BLZ: {
    code: COUNTRY_ISO3166_ALPHA3.enum.BLZ,
    name: 'Belize',
    location: { latitude: 17.189877, longitude: -88.49765 },
  },
  BMU: {
    code: COUNTRY_ISO3166_ALPHA3.enum.BMU,
    name: 'Bermuda',
    location: { latitude: 32.321384, longitude: -64.75737 },
  },
  BOL: {
    code: COUNTRY_ISO3166_ALPHA3.enum.BOL,
    name: 'Bolivia',
    location: { latitude: -16.290154, longitude: -63.588653 },
  },
  BRA: {
    code: COUNTRY_ISO3166_ALPHA3.enum.BRA,
    name: 'Brazil',
    location: { latitude: -14.235004, longitude: -51.92528 },
  },
  BRB: {
    code: COUNTRY_ISO3166_ALPHA3.enum.BRB,
    name: 'Barbados',
    location: { latitude: 13.193887, longitude: -59.543198 },
  },
  BRN: {
    code: COUNTRY_ISO3166_ALPHA3.enum.BRN,
    name: 'Brunei Darussalam',
    location: { latitude: 4.535277, longitude: 114.727669 },
  },
  BTN: {
    code: COUNTRY_ISO3166_ALPHA3.enum.BTN,
    name: 'Bhutan',
    location: { latitude: 27.514162, longitude: 90.433601 },
  },
  BVT: {
    code: COUNTRY_ISO3166_ALPHA3.enum.BVT,
    name: 'Bouvet Island',
    location: { latitude: -54.423199, longitude: 3.413194 },
  },
  BWA: {
    code: COUNTRY_ISO3166_ALPHA3.enum.BWA,
    name: 'Botswana',
    location: { latitude: -22.328474, longitude: 24.684866 },
  },
  CAF: {
    code: COUNTRY_ISO3166_ALPHA3.enum.CAF,
    name: 'Central African Republic',
    location: { latitude: 6.611111, longitude: 20.939444 },
  },
  CAN: {
    code: COUNTRY_ISO3166_ALPHA3.enum.CAN,
    name: 'Canada',
    location: { latitude: 56.130366, longitude: -106.346771 },
  },
  CCK: {
    code: COUNTRY_ISO3166_ALPHA3.enum.CCK,
    name: 'Cocos (Keeling) Islands',
    location: { latitude: -12.164165, longitude: 96.870956 },
  },
  CHE: {
    code: COUNTRY_ISO3166_ALPHA3.enum.CHE,
    name: 'Switzerland',
    location: { latitude: 46.818188, longitude: 8.227512 },
  },
  CHL: {
    code: COUNTRY_ISO3166_ALPHA3.enum.CHL,
    name: 'Chile',
    location: { latitude: -35.675147, longitude: -71.542969 },
  },
  CIV: {
    code: COUNTRY_ISO3166_ALPHA3.enum.CIV,
    name: "Côte d'Ivoire",
    location: { latitude: 7.539989, longitude: -5.54708 },
  },
  CMR: {
    code: COUNTRY_ISO3166_ALPHA3.enum.CMR,
    name: 'Cameroon',
    location: { latitude: 7.369722, longitude: 12.354722 },
  },
  COD: {
    code: COUNTRY_ISO3166_ALPHA3.enum.COD,
    name: 'Congo, Republic of the',
    location: { latitude: -0.228021, longitude: 15.827659 },
  },
  COG: {
    code: COUNTRY_ISO3166_ALPHA3.enum.COG,
    name: 'Congo',
    location: { latitude: -4.038333, longitude: 21.758664 },
  },
  COK: {
    code: COUNTRY_ISO3166_ALPHA3.enum.COK,
    name: 'Cook Islands',
    location: { latitude: -21.236736, longitude: -159.777671 },
  },
  COL: {
    code: COUNTRY_ISO3166_ALPHA3.enum.COL,
    name: 'Colombia',
    location: { latitude: 4.570868, longitude: -74.297333 },
  },
  COM: {
    code: COUNTRY_ISO3166_ALPHA3.enum.COM,
    name: 'Comoros',
    location: { latitude: -11.875001, longitude: 43.872219 },
  },
  CPV: {
    code: COUNTRY_ISO3166_ALPHA3.enum.CPV,
    name: 'Cabo Verde',
    location: { latitude: 16.002082, longitude: -24.013197 },
  },
  CRI: {
    code: COUNTRY_ISO3166_ALPHA3.enum.CRI,
    name: 'Costa Rica',
    location: { latitude: 9.748917, longitude: -83.753428 },
  },
  CUW: { code: COUNTRY_ISO3166_ALPHA3.enum.CUW, name: 'Curaçao', location: { latitude: 12.1696, longitude: -68.99 } },
  CXR: {
    code: COUNTRY_ISO3166_ALPHA3.enum.CXR,
    name: 'Christmas Island',
    location: { latitude: -10.447525, longitude: 105.690449 },
  },
  CYM: {
    code: COUNTRY_ISO3166_ALPHA3.enum.CYM,
    name: 'Cayman Islands',
    location: { latitude: 19.513469, longitude: -80.566956 },
  },
  CYP: {
    code: COUNTRY_ISO3166_ALPHA3.enum.CYP,
    name: 'Cyprus',
    location: { latitude: 35.126413, longitude: 33.429859 },
  },
  CZE: {
    code: COUNTRY_ISO3166_ALPHA3.enum.CZE,
    name: 'Czech Republic',
    location: { latitude: 49.817492, longitude: 15.472962 },
  },
  DEU: {
    code: COUNTRY_ISO3166_ALPHA3.enum.DEU,
    name: 'Germany',
    location: { latitude: 51.165691, longitude: 10.451526 },
  },
  DJI: {
    code: COUNTRY_ISO3166_ALPHA3.enum.DJI,
    name: 'Djibouti',
    location: { latitude: 11.825138, longitude: 42.590275 },
  },
  DMA: {
    code: COUNTRY_ISO3166_ALPHA3.enum.DMA,
    name: 'Dominica',
    location: { latitude: 15.414999, longitude: -61.370976 },
  },
  DNK: {
    code: COUNTRY_ISO3166_ALPHA3.enum.DNK,
    name: 'Denmark',
    location: { latitude: 56.26392, longitude: 9.501785 },
  },
  DOM: {
    code: COUNTRY_ISO3166_ALPHA3.enum.DOM,
    name: 'Dominican Republic',
    location: { latitude: 18.735693, longitude: -70.162651 },
  },
  DZA: {
    code: COUNTRY_ISO3166_ALPHA3.enum.DZA,
    name: 'Algeria',
    location: { latitude: 28.033886, longitude: 1.659626 },
  },
  ECU: {
    code: COUNTRY_ISO3166_ALPHA3.enum.ECU,
    name: 'Ecuador',
    location: { latitude: -1.831239, longitude: -78.183406 },
  },
  EGY: {
    code: COUNTRY_ISO3166_ALPHA3.enum.EGY,
    name: 'Egypt',
    location: { latitude: 26.820553, longitude: 30.802498 },
  },
  ERI: {
    code: COUNTRY_ISO3166_ALPHA3.enum.ERI,
    name: 'Eritrea',
    location: { latitude: 15.179384, longitude: 39.782334 },
  },
  ESH: {
    code: COUNTRY_ISO3166_ALPHA3.enum.ESH,
    name: 'Western Sahara',
    location: { latitude: 24.215527, longitude: -12.885834 },
  },
  ESP: { code: COUNTRY_ISO3166_ALPHA3.enum.ESP, name: 'Spain', location: { latitude: 40.463667, longitude: -3.74922 } },
  EST: {
    code: COUNTRY_ISO3166_ALPHA3.enum.EST,
    name: 'Estonia',
    location: { latitude: 58.595272, longitude: 25.013607 },
  },
  ETH: { code: COUNTRY_ISO3166_ALPHA3.enum.ETH, name: 'Ethiopia', location: { latitude: 9.145, longitude: 40.489673 } },
  FIN: {
    code: COUNTRY_ISO3166_ALPHA3.enum.FIN,
    name: 'Finland',
    location: { latitude: 61.92411, longitude: 25.748151 },
  },
  FJI: {
    code: COUNTRY_ISO3166_ALPHA3.enum.FJI,
    name: 'Fiji',
    location: { latitude: -16.578193, longitude: 179.414413 },
  },
  FLK: {
    code: COUNTRY_ISO3166_ALPHA3.enum.FLK,
    name: 'Falkland Islands (Malvinas)',
    location: { latitude: -51.796253, longitude: -59.523613 },
  },
  FRA: {
    code: COUNTRY_ISO3166_ALPHA3.enum.FRA,
    name: 'France',
    location: { latitude: 46.227638, longitude: 2.213749 },
  },
  FRO: {
    code: COUNTRY_ISO3166_ALPHA3.enum.FRO,
    name: 'Faroe Islands',
    location: { latitude: 61.892635, longitude: -6.911806 },
  },
  FSM: {
    code: COUNTRY_ISO3166_ALPHA3.enum.FSM,
    name: 'Micronesia (Federated States of)',
    location: { latitude: 7.425554, longitude: 150.550812 },
  },
  GAB: {
    code: COUNTRY_ISO3166_ALPHA3.enum.GAB,
    name: 'Gabon',
    location: { latitude: -0.803689, longitude: 11.609444 },
  },
  GBR: {
    code: COUNTRY_ISO3166_ALPHA3.enum.GBR,
    name: 'United Kingdom',
    location: { latitude: 55.378051, longitude: -3.435973 },
  },
  GEO: {
    code: COUNTRY_ISO3166_ALPHA3.enum.GEO,
    name: 'Georgia',
    location: { latitude: 42.315407, longitude: 43.356892 },
  },
  GGY: {
    code: COUNTRY_ISO3166_ALPHA3.enum.GGY,
    name: 'Guernsey',
    location: { latitude: 49.465691, longitude: -2.585278 },
  },
  GHA: { code: COUNTRY_ISO3166_ALPHA3.enum.GHA, name: 'Ghana', location: { latitude: 7.946527, longitude: -1.023194 } },
  GIB: {
    code: COUNTRY_ISO3166_ALPHA3.enum.GIB,
    name: 'Gibraltar',
    location: { latitude: 36.137741, longitude: -5.345374 },
  },
  GIN: {
    code: COUNTRY_ISO3166_ALPHA3.enum.GIN,
    name: 'Guinea',
    location: { latitude: 9.945587, longitude: -9.696645 },
  },
  GLP: {
    code: COUNTRY_ISO3166_ALPHA3.enum.GLP,
    name: 'Guadeloupe',
    location: { latitude: 16.995971, longitude: -62.067641 },
  },
  GMB: {
    code: COUNTRY_ISO3166_ALPHA3.enum.GMB,
    name: 'Gambia, The',
    location: { latitude: 13.443182, longitude: -15.310139 },
  },
  GNB: {
    code: COUNTRY_ISO3166_ALPHA3.enum.GNB,
    name: 'Guinea-Bissau',
    location: { latitude: 11.803749, longitude: -15.180413 },
  },
  GNQ: {
    code: COUNTRY_ISO3166_ALPHA3.enum.GNQ,
    name: 'Equatorial Guinea',
    location: { latitude: 1.650801, longitude: 10.267895 },
  },
  GRC: {
    code: COUNTRY_ISO3166_ALPHA3.enum.GRC,
    name: 'Greece',
    location: { latitude: 39.074208, longitude: 21.824312 },
  },
  GRD: {
    code: COUNTRY_ISO3166_ALPHA3.enum.GRD,
    name: 'Grenada',
    location: { latitude: 12.262776, longitude: -61.604171 },
  },
  GRL: {
    code: COUNTRY_ISO3166_ALPHA3.enum.GRL,
    name: 'Greenland',
    location: { latitude: 71.706936, longitude: -42.604303 },
  },
  GTM: {
    code: COUNTRY_ISO3166_ALPHA3.enum.GTM,
    name: 'Guatemala',
    location: { latitude: 15.783471, longitude: -90.230759 },
  },
  GUF: {
    code: COUNTRY_ISO3166_ALPHA3.enum.GUF,
    name: 'French Guiana',
    location: { latitude: 3.933889, longitude: -53.125782 },
  },
  GUM: {
    code: COUNTRY_ISO3166_ALPHA3.enum.GUM,
    name: 'Guam',
    location: { latitude: 13.444304, longitude: 144.793731 },
  },
  GUY: {
    code: COUNTRY_ISO3166_ALPHA3.enum.GUY,
    name: 'Guyana',
    location: { latitude: 4.860416, longitude: -58.93018 },
  },
  HKG: {
    code: COUNTRY_ISO3166_ALPHA3.enum.HKG,
    name: 'Hong Kong SAR',
    location: { latitude: 22.396428, longitude: 114.109497 },
  },
  HMD: {
    code: COUNTRY_ISO3166_ALPHA3.enum.HMD,
    name: 'Heard Island and McDonald Islands',
    location: { latitude: -53.08181, longitude: 73.504158 },
  },
  HND: {
    code: COUNTRY_ISO3166_ALPHA3.enum.HND,
    name: 'Honduras',
    location: { latitude: 15.199999, longitude: -86.241905 },
  },
  HRV: { code: COUNTRY_ISO3166_ALPHA3.enum.HRV, name: 'Croatia', location: { latitude: 45.1, longitude: 15.2 } },
  HTI: {
    code: COUNTRY_ISO3166_ALPHA3.enum.HTI,
    name: 'Haiti',
    location: { latitude: 18.971187, longitude: -72.285215 },
  },
  HUN: {
    code: COUNTRY_ISO3166_ALPHA3.enum.HUN,
    name: 'Hungary',
    location: { latitude: 47.162494, longitude: 19.503304 },
  },
  IDN: {
    code: COUNTRY_ISO3166_ALPHA3.enum.IDN,
    name: 'Indonesia',
    location: { latitude: -0.789275, longitude: 113.921327 },
  },
  IMN: {
    code: COUNTRY_ISO3166_ALPHA3.enum.IMN,
    name: 'Isle of Man',
    location: { latitude: 54.236107, longitude: -4.548056 },
  },
  IND: { code: COUNTRY_ISO3166_ALPHA3.enum.IND, name: 'India', location: { latitude: 20.593684, longitude: 78.96288 } },
  IOT: {
    code: COUNTRY_ISO3166_ALPHA3.enum.IOT,
    name: 'British Indian Ocean Territory',
    location: { latitude: -6.343194, longitude: 71.876519 },
  },
  IRL: {
    code: COUNTRY_ISO3166_ALPHA3.enum.IRL,
    name: 'Ireland',
    location: { latitude: 53.41291, longitude: -8.24389 },
  },
  IRQ: { code: COUNTRY_ISO3166_ALPHA3.enum.IRQ, name: 'Iraq', location: { latitude: 33.223191, longitude: 43.679291 } },
  ISL: {
    code: COUNTRY_ISO3166_ALPHA3.enum.ISL,
    name: 'Iceland',
    location: { latitude: 64.963051, longitude: -19.020835 },
  },
  ISR: {
    code: COUNTRY_ISO3166_ALPHA3.enum.ISR,
    name: 'Israel',
    location: { latitude: 31.046051, longitude: 34.851612 },
  },
  ITA: { code: COUNTRY_ISO3166_ALPHA3.enum.ITA, name: 'Italy', location: { latitude: 41.87194, longitude: 12.56738 } },
  JAM: {
    code: COUNTRY_ISO3166_ALPHA3.enum.JAM,
    name: 'Jamaica',
    location: { latitude: 18.109581, longitude: -77.297508 },
  },
  JEY: {
    code: COUNTRY_ISO3166_ALPHA3.enum.JEY,
    name: 'Jersey',
    location: { latitude: 49.214439, longitude: -2.13125 },
  },
  JOR: {
    code: COUNTRY_ISO3166_ALPHA3.enum.JOR,
    name: 'Jordan',
    location: { latitude: 30.585164, longitude: 36.238414 },
  },
  JPN: {
    code: COUNTRY_ISO3166_ALPHA3.enum.JPN,
    name: 'Japan',
    location: { latitude: 36.204824, longitude: 138.252924 },
  },
  KAZ: {
    code: COUNTRY_ISO3166_ALPHA3.enum.KAZ,
    name: 'Kazakhstan',
    location: { latitude: 48.019573, longitude: 66.923684 },
  },
  KEN: {
    code: COUNTRY_ISO3166_ALPHA3.enum.KEN,
    name: 'Kenya',
    location: { latitude: -0.023559, longitude: 37.906193 },
  },
  KGZ: {
    code: COUNTRY_ISO3166_ALPHA3.enum.KGZ,
    name: 'Kyrgyzstan',
    location: { latitude: 41.20438, longitude: 74.766098 },
  },
  KHM: {
    code: COUNTRY_ISO3166_ALPHA3.enum.KHM,
    name: 'Cambodia',
    location: { latitude: 12.565679, longitude: 104.990963 },
  },
  KIR: {
    code: COUNTRY_ISO3166_ALPHA3.enum.KIR,
    name: 'Kiribati',
    location: { latitude: -3.370417, longitude: -168.734039 },
  },
  KNA: {
    code: COUNTRY_ISO3166_ALPHA3.enum.KNA,
    name: 'Saint Kitts and Nevis',
    location: { latitude: 17.357822, longitude: -62.782998 },
  },
  KOR: {
    code: COUNTRY_ISO3166_ALPHA3.enum.KOR,
    name: 'South Korea',
    location: { latitude: 35.907757, longitude: 127.766922 },
  },
  KWT: {
    code: COUNTRY_ISO3166_ALPHA3.enum.KWT,
    name: 'Kuwait',
    location: { latitude: 29.31166, longitude: 47.481766 },
  },
  LAO: {
    code: COUNTRY_ISO3166_ALPHA3.enum.LAO,
    name: "Lao People's Democratic Republic",
    location: { latitude: 19.85627, longitude: 102.495496 },
  },
  LBN: {
    code: COUNTRY_ISO3166_ALPHA3.enum.LBN,
    name: 'Lebanon',
    location: { latitude: 33.854721, longitude: 35.862285 },
  },
  LBR: {
    code: COUNTRY_ISO3166_ALPHA3.enum.LBR,
    name: 'Liberia',
    location: { latitude: 6.428055, longitude: -9.429499 },
  },
  LBY: { code: COUNTRY_ISO3166_ALPHA3.enum.LBY, name: 'Libya', location: { latitude: 26.3351, longitude: 17.228331 } },
  LCA: {
    code: COUNTRY_ISO3166_ALPHA3.enum.LCA,
    name: 'Saint Lucia',
    location: { latitude: 13.909444, longitude: -60.978893 },
  },
  LIE: {
    code: COUNTRY_ISO3166_ALPHA3.enum.LIE,
    name: 'Liechtenstein',
    location: { latitude: 47.166, longitude: 9.555373 },
  },
  LKA: {
    code: COUNTRY_ISO3166_ALPHA3.enum.LKA,
    name: 'Sri Lanka',
    location: { latitude: 7.873054, longitude: 80.771797 },
  },
  LSO: {
    code: COUNTRY_ISO3166_ALPHA3.enum.LSO,
    name: 'Lesotho',
    location: { latitude: -29.609988, longitude: 28.233608 },
  },
  LTU: {
    code: COUNTRY_ISO3166_ALPHA3.enum.LTU,
    name: 'Lithuania',
    location: { latitude: 55.169438, longitude: 23.881275 },
  },
  LUX: {
    code: COUNTRY_ISO3166_ALPHA3.enum.LUX,
    name: 'Luxembourg',
    location: { latitude: 49.815273, longitude: 6.129583 },
  },
  LVA: {
    code: COUNTRY_ISO3166_ALPHA3.enum.LVA,
    name: 'Latvia',
    location: { latitude: 56.879635, longitude: 24.603189 },
  },
  MAC: {
    code: COUNTRY_ISO3166_ALPHA3.enum.MAC,
    name: 'Macao',
    location: { latitude: 22.198745, longitude: 113.543873 },
  },
  MAF: {
    code: COUNTRY_ISO3166_ALPHA3.enum.MAF,
    name: 'Saint Martin (French part)',
    location: { latitude: 18.0708, longitude: -63.0501 },
  },
  MAR: {
    code: COUNTRY_ISO3166_ALPHA3.enum.MAR,
    name: 'Morocco',
    location: { latitude: 31.791702, longitude: -7.09262 },
  },
  MCO: {
    code: COUNTRY_ISO3166_ALPHA3.enum.MCO,
    name: 'Monaco',
    location: { latitude: 43.750298, longitude: 7.412841 },
  },
  MDA: {
    code: COUNTRY_ISO3166_ALPHA3.enum.MDA,
    name: 'Moldova, Republic of',
    location: { latitude: 47.411631, longitude: 28.369885 },
  },
  MDG: {
    code: COUNTRY_ISO3166_ALPHA3.enum.MDG,
    name: 'Madagascar',
    location: { latitude: -18.766947, longitude: 46.869107 },
  },
  MDV: {
    code: COUNTRY_ISO3166_ALPHA3.enum.MDV,
    name: 'Maldives',
    location: { latitude: 3.202778, longitude: 73.22068 },
  },
  MEX: {
    code: COUNTRY_ISO3166_ALPHA3.enum.MEX,
    name: 'Mexico',
    location: { latitude: 23.634501, longitude: -102.552784 },
  },
  MHL: {
    code: COUNTRY_ISO3166_ALPHA3.enum.MHL,
    name: 'Marshall Islands',
    location: { latitude: 7.131474, longitude: 171.184478 },
  },
  MKD: {
    code: COUNTRY_ISO3166_ALPHA3.enum.MKD,
    name: 'North Macedonia',
    location: { latitude: 41.608635, longitude: 21.745275 },
  },
  MLI: { code: COUNTRY_ISO3166_ALPHA3.enum.MLI, name: 'Mali', location: { latitude: 17.570692, longitude: -3.996166 } },
  MLT: {
    code: COUNTRY_ISO3166_ALPHA3.enum.MLT,
    name: 'Malta',
    location: { latitude: 35.937496, longitude: 14.375416 },
  },
  MMR: {
    code: COUNTRY_ISO3166_ALPHA3.enum.MMR,
    name: 'Myanmar',
    location: { latitude: 21.913965, longitude: 95.956223 },
  },
  MNE: {
    code: COUNTRY_ISO3166_ALPHA3.enum.MNE,
    name: 'Montenegro',
    location: { latitude: 42.708678, longitude: 19.37439 },
  },
  MNG: {
    code: COUNTRY_ISO3166_ALPHA3.enum.MNG,
    name: 'Mongolia',
    location: { latitude: 46.862496, longitude: 103.846656 },
  },
  MNP: {
    code: COUNTRY_ISO3166_ALPHA3.enum.MNP,
    name: 'Northern Mariana Islands',
    location: { latitude: 17.33083, longitude: 145.38469 },
  },
  MOZ: {
    code: COUNTRY_ISO3166_ALPHA3.enum.MOZ,
    name: 'Mozambique',
    location: { latitude: -18.665695, longitude: 35.529562 },
  },
  MRT: {
    code: COUNTRY_ISO3166_ALPHA3.enum.MRT,
    name: 'Mauritania',
    location: { latitude: 21.00789, longitude: -10.940835 },
  },
  MSR: {
    code: COUNTRY_ISO3166_ALPHA3.enum.MSR,
    name: 'Montserrat',
    location: { latitude: 16.742498, longitude: -62.187366 },
  },
  MTQ: {
    code: COUNTRY_ISO3166_ALPHA3.enum.MTQ,
    name: 'Martinique',
    location: { latitude: 14.641528, longitude: -61.024174 },
  },
  MUS: {
    code: COUNTRY_ISO3166_ALPHA3.enum.MUS,
    name: 'Mauritius',
    location: { latitude: -20.348404, longitude: 57.552152 },
  },
  MWI: {
    code: COUNTRY_ISO3166_ALPHA3.enum.MWI,
    name: 'Malawi',
    location: { latitude: -13.254308, longitude: 34.301525 },
  },
  MYS: {
    code: COUNTRY_ISO3166_ALPHA3.enum.MYS,
    name: 'Malaysia',
    location: { latitude: 4.210484, longitude: 101.975766 },
  },
  MYT: {
    code: COUNTRY_ISO3166_ALPHA3.enum.MYT,
    name: 'Mayotte',
    location: { latitude: -12.8275, longitude: 45.166244 },
  },
  NAM: {
    code: COUNTRY_ISO3166_ALPHA3.enum.NAM,
    name: 'Namibia',
    location: { latitude: -22.95764, longitude: 18.49041 },
  },
  NCL: {
    code: COUNTRY_ISO3166_ALPHA3.enum.NCL,
    name: 'New Caledonia',
    location: { latitude: -20.904305, longitude: 165.618042 },
  },
  NER: { code: COUNTRY_ISO3166_ALPHA3.enum.NER, name: 'Niger', location: { latitude: 17.607789, longitude: 8.081666 } },
  NFK: {
    code: COUNTRY_ISO3166_ALPHA3.enum.NFK,
    name: 'Norfolk Island',
    location: { latitude: -29.040835, longitude: 167.954712 },
  },
  NGA: {
    code: COUNTRY_ISO3166_ALPHA3.enum.NGA,
    name: 'Nigeria',
    location: { latitude: 9.081999, longitude: 8.675277 },
  },
  NIC: {
    code: COUNTRY_ISO3166_ALPHA3.enum.NIC,
    name: 'Nicaragua',
    location: { latitude: 12.865416, longitude: -85.207229 },
  },
  NIU: {
    code: COUNTRY_ISO3166_ALPHA3.enum.NIU,
    name: 'Niue',
    location: { latitude: -19.054445, longitude: -169.867233 },
  },
  NLD: {
    code: COUNTRY_ISO3166_ALPHA3.enum.NLD,
    name: 'Netherlands',
    location: { latitude: 52.132633, longitude: 5.291266 },
  },
  NOR: {
    code: COUNTRY_ISO3166_ALPHA3.enum.NOR,
    name: 'Norway',
    location: { latitude: 60.472024, longitude: 8.468946 },
  },
  NPL: {
    code: COUNTRY_ISO3166_ALPHA3.enum.NPL,
    name: 'Nepal',
    location: { latitude: 28.394857, longitude: 84.124008 },
  },
  NRU: {
    code: COUNTRY_ISO3166_ALPHA3.enum.NRU,
    name: 'Nauru',
    location: { latitude: -0.522778, longitude: 166.931503 },
  },
  NZL: {
    code: COUNTRY_ISO3166_ALPHA3.enum.NZL,
    name: 'New Zealand',
    location: { latitude: -40.900557, longitude: 174.885971 },
  },
  OMN: { code: COUNTRY_ISO3166_ALPHA3.enum.OMN, name: 'Oman', location: { latitude: 21.512583, longitude: 55.923255 } },
  PAK: {
    code: COUNTRY_ISO3166_ALPHA3.enum.PAK,
    name: 'Pakistan',
    location: { latitude: 30.375321, longitude: 69.345116 },
  },
  PAN: {
    code: COUNTRY_ISO3166_ALPHA3.enum.PAN,
    name: 'Panama',
    location: { latitude: 8.537981, longitude: -80.782127 },
  },
  PCN: {
    code: COUNTRY_ISO3166_ALPHA3.enum.PCN,
    name: 'Pitcairn',
    location: { latitude: -24.703615, longitude: -127.439308 },
  },
  PER: {
    code: COUNTRY_ISO3166_ALPHA3.enum.PER,
    name: 'Peru',
    location: { latitude: -9.189967, longitude: -75.015152 },
  },
  PHL: {
    code: COUNTRY_ISO3166_ALPHA3.enum.PHL,
    name: 'Philippines',
    location: { latitude: 12.879721, longitude: 121.774017 },
  },
  PLW: { code: COUNTRY_ISO3166_ALPHA3.enum.PLW, name: 'Palau', location: { latitude: 7.51498, longitude: 134.58252 } },
  PNG: {
    code: COUNTRY_ISO3166_ALPHA3.enum.PNG,
    name: 'Papua New Guinea',
    location: { latitude: -6.314993, longitude: 143.95555 },
  },
  POL: {
    code: COUNTRY_ISO3166_ALPHA3.enum.POL,
    name: 'Poland',
    location: { latitude: 51.919438, longitude: 19.145136 },
  },
  PRI: {
    code: COUNTRY_ISO3166_ALPHA3.enum.PRI,
    name: 'Puerto Rico',
    location: { latitude: 18.220833, longitude: -66.590149 },
  },
  PRT: {
    code: COUNTRY_ISO3166_ALPHA3.enum.PRT,
    name: 'Portugal',
    location: { latitude: 39.399872, longitude: -8.224454 },
  },
  PRY: {
    code: COUNTRY_ISO3166_ALPHA3.enum.PRY,
    name: 'Paraguay',
    location: { latitude: -23.442503, longitude: -58.443832 },
  },
  PSE: {
    code: COUNTRY_ISO3166_ALPHA3.enum.PSE,
    name: 'Palestine',
    location: { latitude: 31.952162, longitude: 35.233154 },
  },
  PYF: {
    code: COUNTRY_ISO3166_ALPHA3.enum.PYF,
    name: 'French Polynesia',
    location: { latitude: -17.679742, longitude: -149.406843 },
  },
  QAT: {
    code: COUNTRY_ISO3166_ALPHA3.enum.QAT,
    name: 'Qatar',
    location: { latitude: 25.354826, longitude: 51.183884 },
  },
  REU: {
    code: COUNTRY_ISO3166_ALPHA3.enum.REU,
    name: 'Réunion',
    location: { latitude: -21.115141, longitude: 55.536384 },
  },
  ROU: {
    code: COUNTRY_ISO3166_ALPHA3.enum.ROU,
    name: 'Romania',
    location: { latitude: 45.943161, longitude: 24.96676 },
  },
  RWA: {
    code: COUNTRY_ISO3166_ALPHA3.enum.RWA,
    name: 'Rwanda',
    location: { latitude: -1.940278, longitude: 29.873888 },
  },
  SAU: {
    code: COUNTRY_ISO3166_ALPHA3.enum.SAU,
    name: 'Saudi Arabia',
    location: { latitude: 23.885942, longitude: 45.079162 },
  },
  SDN: {
    code: COUNTRY_ISO3166_ALPHA3.enum.SDN,
    name: 'Sudan',
    location: { latitude: 12.862807, longitude: 30.217636 },
  },
  SEN: {
    code: COUNTRY_ISO3166_ALPHA3.enum.SEN,
    name: 'Senegal',
    location: { latitude: 14.497401, longitude: -14.452362 },
  },
  SGP: {
    code: COUNTRY_ISO3166_ALPHA3.enum.SGP,
    name: 'Singapore',
    location: { latitude: 1.352083, longitude: 103.819836 },
  },
  SGS: {
    code: COUNTRY_ISO3166_ALPHA3.enum.SGS,
    name: 'South Georgia and the South Sandwich Islands',
    location: { latitude: -54.429579, longitude: -36.587909 },
  },
  SHN: {
    code: COUNTRY_ISO3166_ALPHA3.enum.SHN,
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    location: { latitude: -24.143474, longitude: -10.030696 },
  },
  SJM: {
    code: COUNTRY_ISO3166_ALPHA3.enum.SJM,
    name: 'Svalbard and Jan Mayen',
    location: { latitude: 77.553604, longitude: 23.670272 },
  },
  SLB: {
    code: COUNTRY_ISO3166_ALPHA3.enum.SLB,
    name: 'Solomon Islands',
    location: { latitude: -9.64571, longitude: 160.156194 },
  },
  SLE: {
    code: COUNTRY_ISO3166_ALPHA3.enum.SLE,
    name: 'Sierra Leone',
    location: { latitude: 8.460555, longitude: -11.779889 },
  },
  SLV: {
    code: COUNTRY_ISO3166_ALPHA3.enum.SLV,
    name: 'El Salvador',
    location: { latitude: 13.794185, longitude: -88.89653 },
  },
  SMR: {
    code: COUNTRY_ISO3166_ALPHA3.enum.SMR,
    name: 'San Marino',
    location: { latitude: 43.94236, longitude: 12.457777 },
  },
  SOM: {
    code: COUNTRY_ISO3166_ALPHA3.enum.SOM,
    name: 'Somalia',
    location: { latitude: 5.152149, longitude: 46.199616 },
  },
  SPM: {
    code: COUNTRY_ISO3166_ALPHA3.enum.SPM,
    name: 'Saint Pierre and Miquelon',
    location: { latitude: 46.941936, longitude: -56.27111 },
  },
  SRB: {
    code: COUNTRY_ISO3166_ALPHA3.enum.SRB,
    name: 'Serbia',
    location: { latitude: 44.016521, longitude: 21.005859 },
  },
  SSD: {
    code: COUNTRY_ISO3166_ALPHA3.enum.SSD,
    name: 'South Sudan',
    location: { latitude: 6.876991, longitude: 31.306978 },
  },
  STP: {
    code: COUNTRY_ISO3166_ALPHA3.enum.STP,
    name: 'Sao Tome and Principe',
    location: { latitude: 0.18636, longitude: 6.613081 },
  },
  SUR: {
    code: COUNTRY_ISO3166_ALPHA3.enum.SUR,
    name: 'Suriname',
    location: { latitude: 3.919305, longitude: -56.027783 },
  },
  SVK: {
    code: COUNTRY_ISO3166_ALPHA3.enum.SVK,
    name: 'Slovakia',
    location: { latitude: 48.669026, longitude: 19.699024 },
  },
  SVN: {
    code: COUNTRY_ISO3166_ALPHA3.enum.SVN,
    name: 'Slovenia',
    location: { latitude: 46.151241, longitude: 14.995463 },
  },
  SWE: {
    code: COUNTRY_ISO3166_ALPHA3.enum.SWE,
    name: 'Sweden',
    location: { latitude: 60.128161, longitude: 18.643501 },
  },
  SWZ: {
    code: COUNTRY_ISO3166_ALPHA3.enum.SWZ,
    name: 'Eswatini',
    location: { latitude: -26.522503, longitude: 31.465866 },
  },
  SXM: {
    code: COUNTRY_ISO3166_ALPHA3.enum.SXM,
    name: 'Sint Maarten (Dutch part)',
    location: { latitude: 18.04248, longitude: -63.05483 },
  },
  SYC: {
    code: COUNTRY_ISO3166_ALPHA3.enum.SYC,
    name: 'Seychelles',
    location: { latitude: -4.679574, longitude: 55.491977 },
  },
  TCA: {
    code: COUNTRY_ISO3166_ALPHA3.enum.TCA,
    name: 'Turks and Caicos Islands',
    location: { latitude: 21.694025, longitude: -71.797928 },
  },
  TCD: { code: COUNTRY_ISO3166_ALPHA3.enum.TCD, name: 'Chad', location: { latitude: 15.454166, longitude: 18.732207 } },
  TGO: { code: COUNTRY_ISO3166_ALPHA3.enum.TGO, name: 'Togo', location: { latitude: 8.619543, longitude: 0.824782 } },
  THA: {
    code: COUNTRY_ISO3166_ALPHA3.enum.THA,
    name: 'Thailand',
    location: { latitude: 15.870032, longitude: 100.992541 },
  },
  TJK: {
    code: COUNTRY_ISO3166_ALPHA3.enum.TJK,
    name: 'Tajikistan',
    location: { latitude: 38.861034, longitude: 71.276093 },
  },
  TKL: {
    code: COUNTRY_ISO3166_ALPHA3.enum.TKL,
    name: 'Tokelau',
    location: { latitude: -8.967363, longitude: -171.855881 },
  },
  TKM: {
    code: COUNTRY_ISO3166_ALPHA3.enum.TKM,
    name: 'Turkmenistan',
    location: { latitude: 38.969719, longitude: 59.556278 },
  },
  TLS: {
    code: COUNTRY_ISO3166_ALPHA3.enum.TLS,
    name: 'East Timor',
    location: { latitude: -8.874217, longitude: 125.727539 },
  },
  TON: {
    code: COUNTRY_ISO3166_ALPHA3.enum.TON,
    name: 'Tonga',
    location: { latitude: -21.178986, longitude: -175.198242 },
  },
  TTO: {
    code: COUNTRY_ISO3166_ALPHA3.enum.TTO,
    name: 'Trinidad and Tobago',
    location: { latitude: 10.691803, longitude: -61.222503 },
  },
  TUN: {
    code: COUNTRY_ISO3166_ALPHA3.enum.TUN,
    name: 'Tunisia',
    location: { latitude: 33.886917, longitude: 9.537499 },
  },
  TUR: {
    code: COUNTRY_ISO3166_ALPHA3.enum.TUR,
    name: 'Turkey',
    location: { latitude: 38.963745, longitude: 35.243322 },
  },
  TUV: {
    code: COUNTRY_ISO3166_ALPHA3.enum.TUV,
    name: 'Tuvalu',
    location: { latitude: -7.109535, longitude: 177.64933 },
  },
  TWN: {
    code: COUNTRY_ISO3166_ALPHA3.enum.TWN,
    name: 'Taiwan',
    location: { latitude: 23.69781, longitude: 120.960515 },
  },
  TZA: {
    code: COUNTRY_ISO3166_ALPHA3.enum.TZA,
    name: 'Tanzania, United Republic of',
    location: { latitude: -6.369028, longitude: 34.888822 },
  },
  UGA: {
    code: COUNTRY_ISO3166_ALPHA3.enum.UGA,
    name: 'Uganda',
    location: { latitude: 1.373333, longitude: 32.290275 },
  },
  UKR: {
    code: COUNTRY_ISO3166_ALPHA3.enum.UKR,
    name: 'Ukraine',
    location: { latitude: 48.379433, longitude: 31.16558 },
  },
  UMI: {
    code: COUNTRY_ISO3166_ALPHA3.enum.UMI,
    name: 'United States Minor Outlying Islands',
    location: { latitude: 19.282319, longitude: 166.647047 },
  },
  URY: {
    code: COUNTRY_ISO3166_ALPHA3.enum.URY,
    name: 'Uruguay',
    location: { latitude: -32.522779, longitude: -55.765835 },
  },
  USA: {
    code: COUNTRY_ISO3166_ALPHA3.enum.USA,
    name: 'United States of America',
    location: { latitude: 37.09024, longitude: -95.712891 },
  },
  UZB: {
    code: COUNTRY_ISO3166_ALPHA3.enum.UZB,
    name: 'Uzbekistan',
    location: { latitude: 41.377491, longitude: 64.585262 },
  },
  VAT: {
    code: COUNTRY_ISO3166_ALPHA3.enum.VAT,
    name: 'Holy See',
    location: { latitude: 41.902916, longitude: 12.453389 },
  },
  VCT: {
    code: COUNTRY_ISO3166_ALPHA3.enum.VCT,
    name: 'Saint Vincent and the Grenadines',
    location: { latitude: 12.984305, longitude: -61.287228 },
  },
  VEN: {
    code: COUNTRY_ISO3166_ALPHA3.enum.VEN,
    name: 'Venezuela',
    location: { latitude: 6.42375, longitude: -66.58973 },
  },
  VGB: {
    code: COUNTRY_ISO3166_ALPHA3.enum.VGB,
    name: 'British Virgin Islands',
    location: { latitude: 18.420695, longitude: -64.639968 },
  },
  VIR: {
    code: COUNTRY_ISO3166_ALPHA3.enum.VIR,
    name: 'Virgin Islands',
    location: { latitude: 18.335765, longitude: -64.896335 },
  },
  VNM: {
    code: COUNTRY_ISO3166_ALPHA3.enum.VNM,
    name: 'Vietnam',
    location: { latitude: 14.058324, longitude: 108.277199 },
  },
  VUT: {
    code: COUNTRY_ISO3166_ALPHA3.enum.VUT,
    name: 'Vanuatu',
    location: { latitude: -15.376706, longitude: 166.959158 },
  },
  WLF: {
    code: COUNTRY_ISO3166_ALPHA3.enum.WLF,
    name: 'Wallis and Futuna',
    location: { latitude: -13.768752, longitude: -177.156097 },
  },
  WSM: {
    code: COUNTRY_ISO3166_ALPHA3.enum.WSM,
    name: 'Samoa',
    location: { latitude: -13.759029, longitude: -172.104629 },
  },
  XKX: {
    code: COUNTRY_ISO3166_ALPHA3.enum.XKX,
    name: 'Kosovo',
    location: { latitude: 42.602636, longitude: 20.902977 },
  },
  YEM: {
    code: COUNTRY_ISO3166_ALPHA3.enum.YEM,
    name: 'Yemen',
    location: { latitude: 15.552727, longitude: 48.516388 },
  },
  ZAF: {
    code: COUNTRY_ISO3166_ALPHA3.enum.ZAF,
    name: 'South Africa',
    location: { latitude: -30.559482, longitude: 22.937506 },
  },
  ZMB: {
    code: COUNTRY_ISO3166_ALPHA3.enum.ZMB,
    name: 'Zambia',
    location: { latitude: -13.133897, longitude: 27.849332 },
  },
  ZWE: {
    code: COUNTRY_ISO3166_ALPHA3.enum.ZWE,
    name: 'Zimbabwe',
    location: { latitude: -19.015438, longitude: 29.154857 },
  },
} as const;
