import type { IconProps } from './Icon.types';

export const RoutingIcon = ({ width = 36, height, ...props }: Omit<IconProps, 'color'>) => (
  <svg
    width={width}
    height={height ?? width}
    viewBox='0 0 36 36'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}>
    <path
      d='M9 8H23.5C26.2614 8 28.5 10.2386 28.5 13V13C28.5 15.7614 26.2614 18 23.5 18H11.75C9.12665 18 7 20.1266 7 22.75V22.75C7 25.3734 9.12665 27.5 11.75 27.5H28.5'
      stroke='#53ACDE'
      strokeWidth='3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M25 24L28.5 27.5L25 31' stroke='#53ACDE' strokeWidth='3' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);
