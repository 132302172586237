import parse from 'html-react-parser';
import dompurify from 'isomorphic-dompurify';

type ParseHtmlOptions = {
  forbidLinks?: boolean;
};

// Hook to remove any iframes that are not YouTube embeds
dompurify.addHook('uponSanitizeElement', (node, data) => {
  if (data.tagName !== 'iframe') return;

  const src = node.getAttribute('src') || '';
  // Allow YouTube embeds
  if (src.startsWith('https://www.youtube.com/embed/')) return;

  // Remove any other iframes
  if (!node.parentNode) throw new Error('Embedded iframe node has no parent');
  return node.parentNode.removeChild(node);
});

// This needs to be in its own file because it breaks all UI tests
// There's a bug with TextEncoder / TextDecoder in jsdom
export const parseHtml = (dirtyHtml?: string, options?: ParseHtmlOptions) => {
  const forbiddenTags: Array<string> = [];
  if (options?.forbidLinks) {
    forbiddenTags.push('a');
  }

  return parse(
    dompurify.sanitize(dirtyHtml || '', {
      ADD_TAGS: [
        // While iframes are allowed, we only allow YouTube embeds
        'iframe',
        'img',
      ],
      ADD_ATTR: ['src', 'alt', 'style'],
      FORBID_TAGS: forbiddenTags,
    }),
  );
};
