import { useId } from 'react';

export const CoinbaseIcon = () => {
  const gradientId = useId();
  return (
    <svg width='40' height='39' viewBox='0 0 40 39' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='0.638672' width='38.4578' height='38.4578' rx='8.24096' fill={`url(#${gradientId})`} />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.34717 19.2293C6.34717 26.6964 12.4004 32.7496 19.8675 32.7496C27.3346 32.7496 33.3878 26.6964 33.3878 19.2293C33.3878 11.7622 27.3346 5.70898 19.8675 5.70898C12.4004 5.70898 6.34717 11.7622 6.34717 19.2293ZM17.259 14.8729C16.2936 14.8729 15.5109 15.6555 15.5109 16.6209V21.8379C15.5109 22.8033 16.2936 23.586 17.259 23.586H22.4759C23.4414 23.586 24.224 22.8033 24.224 21.8379V16.6209C24.224 15.6555 23.4414 14.8729 22.4759 14.8729H17.259Z'
        fill='white'
      />
      <defs>
        <linearGradient id={gradientId} x1='19.8676' y1='0' x2='19.8676' y2='38.4578' gradientUnits='userSpaceOnUse'>
          <stop stopColor='#2E66F8' />
          <stop offset='1' stopColor='#124ADB' />
        </linearGradient>
      </defs>
    </svg>
  );
};
