import type { IconProps } from './Icon.types';

export const GlobeIcon = ({
  width = 27,
  height,
  strokeColor = 'currentColor',
  strokeWidth = 2,
  color,
  ...props
}: IconProps) => (
  <svg
    width={width}
    height={height ?? width}
    viewBox='0 0 27 27'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}>
    <circle cx='13.1814' cy='13.1814' r='12.1814' stroke={strokeColor} strokeWidth={strokeWidth} />
    <path
      d='M17.4539 13.1814C17.4539 16.7267 16.8773 19.8903 15.9812 22.1307C15.532 23.2536 15.0221 24.0953 14.5071 24.6398C13.9941 25.1823 13.5458 25.3628 13.1814 25.3628C12.8169 25.3628 12.3686 25.1823 11.8556 24.6398C11.3406 24.0953 10.8308 23.2536 10.3816 22.1307C9.48544 19.8903 8.90881 16.7267 8.90881 13.1814C8.90881 9.6361 9.48544 6.47249 10.3816 4.23213C10.8308 3.10918 11.3406 2.26752 11.8556 1.72297C12.3686 1.18052 12.8169 1 13.1814 1C13.5458 1 13.9941 1.18052 14.5071 1.72297C15.0221 2.26752 15.532 3.10918 15.9812 4.23213C16.8773 6.47249 17.4539 9.6361 17.4539 13.1814Z'
      stroke={color ?? strokeColor}
      strokeWidth={strokeWidth}
    />
    <line y1='13.4995' x2='26.3628' y2='13.4995' stroke={strokeColor} strokeWidth={strokeWidth} />
  </svg>
);
