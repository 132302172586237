import { useId } from 'react';

import type { IconProps } from './Icon.types';

export const InstagramIcon = ({ width = 36, height, color = 'currentColor', ...props }: IconProps) => {
  const clipId = useId();
  return (
    <svg
      width={width}
      height={height ?? width}
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <g clipPath={`url(#${clipId})`}>
        <rect x='1.5' y='1.5' width='33' height='33' rx='9.5' stroke={color} strokeWidth='3' />
        <circle cx='27.5' cy='8.5' r='2.5' fill={color} />
        <circle cx='18' cy='18' r='7.5' stroke={color} strokeWidth='3' />
      </g>
      <defs>
        <clipPath id={clipId}>
          <rect width='36' height='36' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
