export const BaseTokenIcon = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M10 20C11.9778 20 13.9112 19.4135 15.5557 18.3147C17.2002 17.2159 18.4819 15.6541 19.2388 13.8268C19.9957 11.9996 20.1937 9.98891 19.8078 8.0491C19.422 6.10929 18.4696 4.32746 17.0711 2.92894C15.6725 1.53041 13.8907 0.578004 11.9509 0.192152C10.0111 -0.1937 8.00042 0.00433286 6.17316 0.761209C4.3459 1.51809 2.78412 2.79981 1.6853 4.4443C0.58649 6.08879 0 8.02219 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.80429 18.9464 7.34783 20 10 20Z'
      fill='#2775CA'
    />
    <path
      d='M12.7393 11.5894C12.7393 10.1311 11.8518 9.63942 10.1351 9.404C8.86633 9.22067 8.62258 8.92275 8.62258 8.33109C8.62258 7.73942 9.05175 7.37067 9.87258 7.37067C10.6205 7.37067 11.0518 7.63109 11.2288 8.23317C11.2476 8.29907 11.2872 8.35715 11.3416 8.3988C11.396 8.44046 11.4624 8.46347 11.5309 8.46442H12.1872C12.23 8.46531 12.2726 8.45674 12.3117 8.43932C12.3509 8.4219 12.3858 8.39606 12.4138 8.36363C12.4419 8.33121 12.4624 8.293 12.474 8.25172C12.4856 8.21045 12.488 8.16712 12.4809 8.12484C12.2726 7.17275 11.6309 6.59984 10.6247 6.42067V5.4165C10.6247 5.33362 10.5917 5.25414 10.5331 5.19553C10.4745 5.13693 10.395 5.104 10.3122 5.104H9.68717C9.60429 5.104 9.5248 5.13693 9.4662 5.19553C9.40759 5.25414 9.37467 5.33362 9.37467 5.4165V6.38525C8.12467 6.56025 7.33508 7.38525 7.33508 8.44359C7.33508 9.81234 8.16842 10.3436 9.91008 10.579C11.0955 10.7728 11.433 11.029 11.433 11.7019C11.433 12.3748 10.8601 12.8269 10.0518 12.8269C8.96217 12.8269 8.59342 12.3498 8.4705 11.7311C8.45621 11.6601 8.41802 11.5961 8.36229 11.5499C8.30656 11.5036 8.23666 11.4779 8.16425 11.4769H7.45175C7.40924 11.4766 7.36717 11.4857 7.32851 11.5034C7.28985 11.521 7.25553 11.547 7.22794 11.5793C7.20035 11.6117 7.18017 11.6497 7.16881 11.6906C7.15745 11.7316 7.15518 11.7746 7.16217 11.8165C7.34342 12.8582 8.01217 13.6207 9.37467 13.804V14.7915C9.37467 14.8744 9.40759 14.9539 9.4662 15.0125C9.5248 15.0711 9.60429 15.104 9.68717 15.104H10.3122C10.395 15.104 10.4745 15.0711 10.5331 15.0125C10.5917 14.9539 10.6247 14.8744 10.6247 14.7915V13.804C11.9163 13.5998 12.7393 12.7082 12.7393 11.5894Z'
      fill='white'
    />
    <path
      d='M7.85575 15.9747C6.65199 15.5352 5.61245 14.7362 4.87796 13.6861C4.14347 12.6359 3.74954 11.3854 3.74954 10.1039C3.74954 8.82239 4.14347 7.57186 4.87796 6.52173C5.61245 5.4716 6.65199 4.67263 7.85575 4.23306C7.93293 4.19918 7.99904 4.14431 8.04655 4.0747C8.09407 4.00509 8.12108 3.92353 8.1245 3.83931V3.25598C8.1295 3.20432 8.12083 3.15226 8.09934 3.10502C8.07786 3.05777 8.04433 3.01701 8.00211 2.98683C7.95989 2.95664 7.91048 2.93809 7.85882 2.93304C7.80717 2.928 7.7551 2.93662 7.70784 2.95806C6.19192 3.44121 4.86894 4.39393 3.93013 5.67848C2.99133 6.96303 2.48535 8.51285 2.48535 10.1039C2.48535 11.6949 2.99133 13.2448 3.93013 14.5293C4.86894 15.8139 6.19192 16.7666 7.70784 17.2497C7.7551 17.2712 7.80717 17.2798 7.85882 17.2747C7.91048 17.2697 7.95989 17.2511 8.00211 17.221C8.04433 17.1908 8.07786 17.15 8.09934 17.1028C8.12083 17.0555 8.1295 17.0035 8.1245 16.9518V16.3685C8.12108 16.2843 8.09407 16.2027 8.04655 16.1331C7.99904 16.0635 7.93293 16.0086 7.85575 15.9747Z'
      fill='white'
    />
    <path
      d='M12.2911 2.95806C12.2438 2.93662 12.1918 2.928 12.1401 2.93304C12.0884 2.93809 12.039 2.95664 11.9968 2.98683C11.9546 3.01701 11.9211 3.05777 11.8996 3.10502C11.8781 3.15226 11.8694 3.20432 11.8744 3.25598V3.83931C11.8779 3.92353 11.9049 4.00509 11.9524 4.0747C11.9999 4.14431 12.066 4.19918 12.1432 4.23306C13.3469 4.67263 14.3865 5.4716 15.121 6.52173C15.8555 7.57186 16.2494 8.82239 16.2494 10.1039C16.2494 11.3854 15.8555 12.6359 15.121 13.6861C14.3865 14.7362 13.3469 15.5352 12.1432 15.9747C12.0635 16.005 11.995 16.059 11.9469 16.1294C11.8989 16.1998 11.8736 16.2832 11.8744 16.3685V16.9518C11.8694 17.0035 11.8781 17.0555 11.8996 17.1028C11.9211 17.15 11.9546 17.1908 11.9968 17.221C12.039 17.2511 12.0884 17.2697 12.1401 17.2747C12.1918 17.2798 12.2438 17.2712 12.2911 17.2497C13.807 16.7666 15.13 15.8139 16.0688 14.5293C17.0076 13.2448 17.5136 11.6949 17.5136 10.1039C17.5136 8.51285 17.0076 6.96303 16.0688 5.67848C15.13 4.39393 13.807 3.44121 12.2911 2.95806Z'
      fill='white'
    />
  </svg>
);
