import NTEE_A from './images/ntee/A.png';
import NTEE_B from './images/ntee/B.png';
import NTEE_C from './images/ntee/C.png';
import NTEE_D from './images/ntee/D.png';
import NTEE_E from './images/ntee/E.png';
import NTEE_F from './images/ntee/F.png';
import NTEE_G from './images/ntee/G.png';
import NTEE_H from './images/ntee/H.png';
import NTEE_I from './images/ntee/I.png';
import NTEE_J from './images/ntee/J.png';
import NTEE_K from './images/ntee/K.png';
import NTEE_L from './images/ntee/L.png';
import NTEE_M from './images/ntee/M.png';
import NTEE_N from './images/ntee/N.png';
import NTEE_O from './images/ntee/O.png';
import NTEE_P from './images/ntee/P.png';
import NTEE_Q from './images/ntee/Q.png';
import NTEE_R from './images/ntee/R.png';
import NTEE_S from './images/ntee/S.png';
import NTEE_T from './images/ntee/T.png';
import NTEE_U from './images/ntee/U.png';
import NTEE_V from './images/ntee/V.png';
import NTEE_W from './images/ntee/W.png';
import NTEE_X from './images/ntee/X.png';
import NTEE_Y from './images/ntee/Y.png';
import NTEE_Z from './images/ntee/Z.png';

export const NTEECodes = {
  A: NTEE_A,
  B: NTEE_B,
  C: NTEE_C,
  D: NTEE_D,
  E: NTEE_E,
  F: NTEE_F,
  G: NTEE_G,
  H: NTEE_H,
  I: NTEE_I,
  J: NTEE_J,
  K: NTEE_K,
  L: NTEE_L,
  M: NTEE_M,
  N: NTEE_N,
  O: NTEE_O,
  P: NTEE_P,
  Q: NTEE_Q,
  R: NTEE_R,
  S: NTEE_S,
  T: NTEE_T,
  U: NTEE_U,
  V: NTEE_V,
  W: NTEE_W,
  X: NTEE_X,
  Y: NTEE_Y,
  Z: NTEE_Z,
};
