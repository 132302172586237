import { COUNTRY_GEODATA } from '@endaoment-frontend/constants';
import type { CountryCode } from '@endaoment-frontend/types';
import { FlagIcon } from '@endaoment-frontend/ui/icons';

import { Pill } from './Pill';

const shortenCountry = (str: string) => {
  if (str.length > 28) {
    return `${str.slice(0, 28)}...`;
  }
  return str;
};

export const CountryPill = ({ countryCode, className }: { countryCode: CountryCode; className?: string }) => {
  return (
    <Pill className={className} size='tiny' variation='blue'>
      <FlagIcon countryCode={countryCode} />
      {shortenCountry(COUNTRY_GEODATA[countryCode].name)}
    </Pill>
  );
};
