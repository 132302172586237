import type { IconProps } from '../Icon.types';

export const MigrateIcon = ({ width = 28, height, color, ...props }: IconProps) => (
  <svg
    width={width}
    height={height ?? width}
    viewBox='0 0 28 28'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}>
    <circle
      cx='14'
      cy='14'
      r='13.25'
      fill={color ?? '#8478E0'}
      fillOpacity='0.125'
      stroke={color ?? '#8478E0'}
      strokeWidth='1.5'
    />
    <path
      d='M7 14H21M21 14L15.75 9M21 14L15.75 19'
      stroke={color ?? '#8478E0'}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
