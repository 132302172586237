import { useId } from 'react';

import type { IconProps } from '../Icon.types';

export const LearnIcon = ({ width = 24, height, color, ...props }: IconProps) => {
  const clipPathId = useId();
  const pathId = useId();
  return (
    <svg
      width={width}
      height={height ?? width}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <g clipPath={`url(#${clipPathId})`}>
        <circle
          cx='6.41107'
          cy='18.1447'
          r='4.34448'
          fill={color ?? '#F01414'}
          fillOpacity='0.25'
          stroke={color ?? '#F01414'}
          strokeOpacity='0.75'
          strokeWidth='1.5'
        />
        <circle
          cx='18.678'
          cy='6.38934'
          r='4.34448'
          fill={color ?? '#F01414'}
          fillOpacity='0.25'
          stroke={color ?? '#F01414'}
          strokeOpacity='0.75'
          strokeWidth='1.5'
        />
        <path
          d='M5.28216 1.5669C5.66353 0.88277 6.64771 0.882771 7.02908 1.5669L11.5237 9.62986C11.8953 10.2964 11.4134 11.1168 10.6502 11.1168H1.66099C0.897868 11.1168 0.415967 10.2964 0.787532 9.62986L5.28216 1.5669Z'
          fill={color ?? '#F01414'}
          fillOpacity='0.25'
        />
        <path
          d='M6.37398 1.93208L10.8686 9.99504C10.9615 10.1617 10.841 10.3668 10.6502 10.3668H1.66099C1.47021 10.3668 1.34973 10.1617 1.44262 9.99504L5.93725 1.93208C6.03259 1.76105 6.27864 1.76104 6.37398 1.93208Z'
          stroke={color ?? '#F01414'}
          strokeOpacity='0.75'
          strokeWidth='1.5'
        />
        <mask id={pathId} fill='white'>
          <rect x='13.8219' y='13.2888' width='9.7112' height='9.7112' rx='1' />
        </mask>
        <rect
          x='13.8219'
          y='13.2888'
          width='9.7112'
          height='9.7112'
          rx='1'
          fill={color ?? '#F01414'}
          fillOpacity='0.25'
          stroke={color ?? '#F01414'}
          strokeOpacity='0.75'
          strokeWidth='3'
          mask={`url(#${pathId})`}
        />
      </g>
      <defs>
        <clipPath id={clipPathId}>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
