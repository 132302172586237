import type { IconProps } from './Icon.types';

export const FilterIcon = ({ width, height, className, color }: IconProps) => (
  <svg
    width={width ?? '25'}
    height={height ?? '13'}
    className={className}
    viewBox='0 0 20 13'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M13.2929 7.20711L18.7929 1.70711C19.4229 1.07714 18.9767 0 18.0858 0H1.41421C0.52331 0 0.0771427 1.07714 0.707108 1.70711L6.20711 7.20711C6.39464 7.39464 6.5 7.649 6.5 7.91421V12C6.5 12.5523 6.94771 13 7.5 13H12C12.5523 13 13 12.5523 13 12V7.91421C13 7.649 13.1054 7.39464 13.2929 7.20711Z'
      fill={color ?? '#C4C4C4'}
    />
  </svg>
);
