import type { IconProps } from './Icon.types';

export const ConfirmIdentityIcon = ({
  width = 73,
  height = 86,
  color = '#627EEA',
  strokeWidth = 5,
  ...props
}: IconProps) => (
  <svg width={width} height={height} viewBox='0 0 73 86' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g opacity='0.25'>
      <path
        d='M45 54L52 61L69.5 43.5'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M61 82V58C61 48.8876 55.9216 40.9614 48.4406 36.8971M13 82V58C13 48.8876 18.0784 40.9614 25.5593 36.8971M25.5593 36.8971C28.6695 39.4605 32.655 41 37 41C41.345 41 45.3305 39.4605 48.4406 36.8971M25.5593 36.8971C21.5537 33.5957 19 28.5961 19 23C19 13.0589 27.0589 5 37 5C46.9411 5 55 13.0589 55 23C55 28.5961 52.4463 33.5957 48.4406 36.8971'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
      />
    </g>
  </svg>
);
