import { useId } from 'react';

import type { IconGradientFillProps, IconProps } from './Icon.types';

export const StarIcon = ({
  startColor,
  endColor,
  width = 32,
  height,
  color = 'currentColor',
  ...props
}: IconGradientFillProps & IconProps) => {
  const gradientId = useId();

  return (
    <svg
      width={width}
      height={height ?? width}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <linearGradient id={gradientId} x1='0%' y1='100%' x2='100%' y2='100%'>
        <stop offset='0%' style={{ stopColor: startColor ?? color, stopOpacity: 1 }} />
        <stop offset='100%' style={{ stopColor: endColor ?? color, stopOpacity: 1 }} />
      </linearGradient>
      <path
        d='M16 0L17.3773 5.65779C18.4962 10.2545 22.1317 13.8149 26.7508 14.8377L32 16L26.4191 17.398C21.9781 18.5105 18.5105 21.9781 17.398 26.4191L16 32L14.602 26.4191C13.4895 21.9781 10.0219 18.5105 5.58089 17.398L0 16L5.58089 14.602C10.0219 13.4895 13.4895 10.0219 14.602 5.58089L16 0Z'
        fill={`url(#${gradientId})`}
      />
    </svg>
  );
};
